
import * as services from './services';

export function getUserData(user, cpf) {
  return new Promise(async (resolve, reject) => {
    services
      .getUserData(user, cpf)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
};

