import * as business from './business';
import * as layoutActions from '../../App/actions/layout_actions';
import * as messageActions from '../../App/actions/message_actions';
import { primaryColor, successColor, dangerColor, warningColor } from '../../App/assets/jss/material-dashboard-pro-react';

import moment from 'moment';

export function setError (error) {
    return{
      type: 'ERROR',
      payload: error
    };
};

export function setSuccess (message) {
    return{
      type: 'SUCCESS',
      payload: message
    };
};

export const block = () => {
  return {
      type: 'BLOCK'
    }
};

export const unblock = () => {
  return {
      type: 'UNBLOCK'
    }
};


//-----------------------------------------------------------------------


export const getStart = (operation, order) => { 
  let result;
  if(operation && operation.operationStart && order[operation.operationStart]){
    result = order[operation.operationStart];
  }else if(operation && operation.operationStart && !order[operation.operationStart]){
    result =  null;
  }else{
    result = order.orderPlaced;
  }

  return result;
}

export const getSLA = (minutes) => {
  function getDays(minutes) {
    let result = Math.floor(minutes / 1440)
    if (isNaN(result) || result < 0) {
      result = 0
    }

    return result
  }
  function getHours(minutes) {
    let result = Math.floor((minutes - getDays(minutes) * 1440) / 60)

    if (isNaN(result) || result < 0) {
      result = 0
    }
    return result
  }
  function getMinutes(minutes) {
    let result = minutes - (getDays(minutes) * 1440) - (getHours(minutes) * 60)

    if (isNaN(result) || result < 0) {
      result = 0
    }
    return result
  }
  if(minutes === 0){
    return ''
  }
  //let slaResult = `${getDays(minutes) > 0 ? (getDays(minutes) + 'd') : ''} ${getHours(minutes) > 0 ? (getHours(minutes) + 'h') : ''} ${getMinutes(minutes) > 0 ? (getMinutes(minutes) + 'm') : ''}`
  let slaResult = `${getDays(minutes) > 0 ? (getDays(minutes) + 'd') : ''} ${getHours(minutes) > 0 ? (getHours(minutes) + 'h') : ''}`
  return slaResult
}

export const getOperationCheckpoints = (operations, order) => {
  let operation = operations && operations.filter(op => op.Operation === order.operation)[0];
  if(operation && operation.Phases.filter(phase => phase.IsTimelessPhase).length > 0){
    return getOperationCheckpointsWithBacklogPhase(operations, order)
  }else{
    return getOperationCheckpointsStandard(operations, order)
  }
}

const getOperationCheckpointsStandard = (operations, order) => {
  let checkpoints = [];
  let status;
  let color;
  let label;
  let isAnchorFeatureOn = false;
  function isAnchor(phase, order, type){
    let anchor = {};
    if(phase.Anchors){
      let anchors = phase.Anchors[type];
      anchors.parameters.forEach(p => {
        if(order[p.parameter] && (!anchor.priority || anchor.priority > p.priority)){
          anchor = p;
        }
      })
    }
    let result;
    if(anchor){
      result = order[anchor.parameter]
    }
    return result;
  }
  let operation = operations && operations.filter(op => op.Operation === order.operation)[0];
  let currentPhaseIndex;

  {operation && operation.Phases && operation.Phases.map((currentPhase, i) => {

    let phaseInitialLeadTimePlanned = order.leadTimePlanned && order.leadTimePlanned.leadTimes[`leadTime_${currentPhase.Phase}`]
    let phaseInitialLeadTimePredicted = (order.leadTimePredicted && order.leadTimePredicted.leadTimes[`leadTimePredicted_${currentPhase.Phase}`])? order.leadTimePredicted.leadTimes[`leadTimePredicted_${currentPhase.Phase}`] : order.leadTimePlanned.leadTimes[`leadTime_${currentPhase.Phase}`]

    const phase = currentPhase;
    let phaseResult = {
      phase: currentPhase,
      start: {
        planned: null,
        predicted: null,
        realized: null
      },
      end: {
        planned: null,
        predicted: null,
        realized: null
      },
      leadTime: {
        planned: phaseInitialLeadTimePlanned || 0,
        predicted: phaseInitialLeadTimePredicted || 0,
        realized: null
      }
    };
    

    let previousPhase = checkpoints[i - 1];
    
    let nextPhase = i < (operation.Phases.length - 1) ? operation.Phases[i+1] : null;

    if(i === 0){
      phaseResult.start.realized = getStart(operation, order);
      
    }else{
      phaseResult.start.realized = order[`phaseIn_${currentPhase.Phase}`]

    }
    

    if(order.currentPhase && order.currentPhase.Phase === currentPhase.Phase){
      currentPhaseIndex = i;
    }


    if(currentPhaseIndex !== 0 && (!currentPhaseIndex || currentPhaseIndex > i)){
      phaseResult.phaseStatus = 'before';
      phaseResult.end.realized = order[`phaseIn_${nextPhase && nextPhase.Phase}`];
    }else if(currentPhaseIndex === i){
      phaseResult.phaseStatus = 'current';
      let startToNow = moment().diff(moment(phaseResult.start.realized),'minutes');
      
      if(startToNow && startToNow <= phaseResult.leadTime.planned){
        let anchorEnd = isAnchor(phase, order, 'end');
        
        if(anchorEnd){
          phaseResult.end.predicted = anchorEnd;
        }else{
          phaseResult.end.predicted = moment(phaseResult.start.realized).add((phaseResult.leadTime.predicted || phaseResult.leadTime.planned),'minutes').format();
        }
      }else if(startToNow && startToNow > phaseResult.leadTime.planned){
        let anchorEnd = isAnchor(phase, order, 'end');
        if(anchorEnd){
          phaseResult.end.predicted = anchorEnd;
        }else{
          //TODO
          if(phaseResult.leadTime.predicted === phaseResult.leadTime.planned){
            phaseResult.leadTime.predicted = startToNow;
          }
          phaseResult.end.predicted = moment(phaseResult.start.realized).add((phaseResult.leadTime.predicted || startToNow ),'minutes').format();
        }

      }

      
      
      
    }else{
      phaseResult.phaseStatus = 'after';
      let anchorStart = isAnchor(phase, order, 'start');
      if(anchorStart){
        phaseResult.start.predicted = anchorStart;
      }else{

        phaseResult.start.predicted = previousPhase.end.realized || previousPhase.end.predicted;
      }
      
      let anchorEnd = isAnchor(phase, order, 'end');
      if(anchorEnd){
        phaseResult.end.predicted = anchorEnd;
      }else{
        phaseResult.end.predicted = phaseResult.start.predicted && moment(phaseResult.start.predicted).add((phaseResult.leadTime.predicted || phaseResult.leadTime.planned),'minutes').format();
      }
    }
    if(phaseResult.start.realized && phaseResult.end.realized){
      phaseResult.leadTime.realized = moment(phaseResult.end.realized).diff(moment(phaseResult.start.realized), 'minutes')
      if(phaseResult.leadTime.realized && !isNaN(phaseResult.leadTime.realized) && phaseResult.leadTime.realized < 0){
        phaseResult.leadTime.realized = 0
      }
    }
    
    checkpoints.push(phaseResult)
  })}
  
  //TODO
  
  let totalLeadTimePlanned = 0;
  checkpoints && checkpoints.forEach((checkpoint) => totalLeadTimePlanned += checkpoint.leadTime.planned)
  

  if(checkpoints[checkpoints.length - 1]){
    checkpoints[checkpoints.length - 1].end.planned = moment(checkpoints[0].start.realized).add(totalLeadTimePlanned,'minutes').format()
  }

  
  let diffPredictedPlanned = checkpoints[checkpoints.length - 1] && moment(checkpoints[checkpoints.length - 1].end.predicted).diff(moment(checkpoints[checkpoints.length - 1].end.planned), 'minutes');
  
  let delay = getSLA(Math.abs(diffPredictedPlanned));

  if((diffPredictedPlanned/totalLeadTimePlanned) < 0){
    status = 'ontime';
    color = successColor;
    label = {
      'pt-br': 'ONTIME',
      'en-gb': 'ONTIME'
    }
  }else if((diffPredictedPlanned/totalLeadTimePlanned) < 0.1){
    status = 'attention';
    color = warningColor;
    label = {
      'pt-br': 'ONTIME',
      'en-gb': 'ONTIME'
    }
  }else{
    status = 'delayed';
    color = dangerColor;
    label = {
      'pt-br': 'ATRASADO',
      'en-gb': 'DELAYED'
    }
  }
  

  let processStatus = {
    checkpoints: checkpoints,
    status: status,
    color: color,
    label: label,
    delay: delay
  }
  
  return processStatus
}


export const getAutomationAlerts = (item) => {
  let yellowLight;
  let redLight;
  let headerColor = primaryColor;
  let yellowAlerts = [];
  let redAlerts = [];
  if(item.automations){
    item.automations.forEach(automation => {
      let ignoreParameter = `automationIgnore_${automation.automation._id}`
      let ignore = item[ignoreParameter]
      if(automation.automation && automation.automation.automationRules && automation.automation.automationRules.action){
        if(automation.automation.automationRules.action === 'activateYellowLight' && !automation.ignore && automation.triggered && !ignore){
          yellowLight = true;
          headerColor = warningColor;
          yellowAlerts.push(automation.automation.automationRules.reference)
        }else if(automation.automation.automationRules.action === 'activateRedLight' && !automation.ignore && automation.triggered && !ignore){
          redLight = true;
          headerColor = dangerColor;
          redAlerts.push(automation.automation.automationRules.reference)
        }
      }
    })
  }

  return {
    yellowLight,
    redLight,
    headerColor,
    yellowAlerts,
    redAlerts
  }

}


const getOperationCheckpointsWithBacklogPhase = (operations, order) => {
  let checkpoints = [];
  let status;
  let color;
  let label;
  let startDatesOffset;
  let statusMessage;
  let statusMessageColor;
  let statusMessageLeadTime;
  let isAnchorFeatureOn = true;

  let operation = operations && operations.filter(op => op.Operation === order.operation)[0];
  let currentPhaseIndex;
  let plannedDeliveryDate = order.necFactory;

  
  let operationPhases = operation.Phases;

  for(let i = (operationPhases.length - 1) ; i >= 0 ; i--){
    let currentPhase = operationPhases[i];
    if(order.currentPhase.Phase === currentPhase.Phase){
      currentPhaseIndex = i;
    }
    let previousPhase = operationPhases[i - 1];
    let nextPhase = i < (operationPhases.length - 1) ? operationPhases[i + 1] : null;
    let nextPhaseCheckpoint = (i < (operationPhases.length - 1)) ? checkpoints[checkpoints.findIndex(c => c.phase.Phase === nextPhase.Phase)] : null;
    
    
    let phaseResult = {
      phase: currentPhase,
      start: {
        planned: null,
        predicted: null,
        realized: null
      },
      end: {
        planned: null,
        predicted: null,
        realized: null
      },
      leadTime: {
        planned: order.leadTimePlanned && order.leadTimePlanned.leadTimes[`leadTime_${currentPhase.Phase}`],
        predicted: (order.leadTimePredicted && order.leadTimePredicted.leadTimes[`leadTimePredicted_${currentPhase.Phase}`])? order.leadTimePredicted.leadTimes[`leadTimePredicted_${currentPhase.Phase}`] : (order.leadTimePlanned && order.leadTimePlanned.leadTimes[`leadTime_${currentPhase.Phase}`]),
        realized: null
      }
    };

    phaseResult.end.planned = (i === (operationPhases.length - 1)) ? plannedDeliveryDate : moment(nextPhaseCheckpoint.end.planned).subtract(nextPhaseCheckpoint.leadTime.planned, 'minutes').format();
    phaseResult.start.planned = moment(phaseResult.end.planned).subtract(phaseResult.leadTime.planned, 'minutes').format();
    
    phaseResult.end.realized = nextPhaseCheckpoint ? nextPhaseCheckpoint.start.realized : order.processEndedAt;
    
    let anchorStart = currentPhase && currentPhase.TriggersIn.AnchorDateRealized && order[currentPhase.TriggersIn.AnchorDateRealized];
    const phaseInLabel = `phaseIn_${currentPhase.Phase}`;
    phaseResult.start.realized = order[phaseInLabel] ? order[phaseInLabel] : (i === 0 ? getStart(operation, order) : null);
    if(anchorStart){
      phaseResult.start.realized = moment(anchorStart).format();
    }
    
    if(currentPhase.IsTimelessPhase){
      phaseResult.leadTime.planned = moment(phaseResult.end.planned).diff(phaseResult.start.planned, 'minutes');
    }

    checkpoints.push(phaseResult)
  }

  let totalLeadTimePlanned = 0;
  checkpoints && checkpoints.forEach((checkpoint) => totalLeadTimePlanned += (checkpoint.leadTime.planned || 0))
  let totalLeadTimePredicted = 0;
  checkpoints && checkpoints.forEach((checkpoint) => totalLeadTimePredicted += (checkpoint.leadTime.realized || checkpoint.leadTime.predicted || 0))
  
  let limitRequestDate = moment(order.necFactory).subtract(totalLeadTimePlanned,'minutes').format();

  operation && operationPhases && operationPhases.map((currentPhase, i) => {
    let nextPhase = operationPhases[i+1]
    let previousPhase = i > 0 && checkpoints[checkpoints.findIndex(ch => ch.phase.Phase === operationPhases[i - 1].Phase)];
    let anchorStart = currentPhase && currentPhase.TriggersIn.AnchorDate && order[currentPhase.TriggersIn.AnchorDate];
    let anchorEnd = nextPhase && nextPhase.TriggersIn.AnchorDate && order[nextPhase.TriggersIn.AnchorDate];
    let anchorEndRealized = nextPhase && nextPhase.TriggersIn.AnchorDateRealized && order[nextPhase.TriggersIn.AnchorDateRealized];
    let checkpoint = checkpoints[checkpoints.findIndex(ch => ch.phase.Phase === currentPhase.Phase)];
    if(currentPhaseIndex !== 0 && (!currentPhaseIndex || currentPhaseIndex > i)){
      checkpoint.phaseStatus = 'before';
      if(anchorEndRealized && isAnchorFeatureOn){
        checkpoint.end.realized = anchorEndRealized;
        checkpoint.leadTime.realized = moment(anchorEndRealized).diff(moment(checkpoint.start.realized),'minutes')
        if(checkpoint.leadTime.realized && !isNaN(checkpoint.leadTime.realized) && checkpoint.leadTime.realized < 0){
          checkpoint.leadTime.realized = 0
        }
      }
    }else if(currentPhaseIndex === i){
      checkpoint.phaseStatus = 'current';
      let startToNow = moment().diff(moment(checkpoint.start.realized),'seconds');
      if(currentPhase.IsTimelessPhase){
        checkpoint.end.predicted = moment(limitRequestDate).isBefore(moment()) ? moment() : limitRequestDate;
      }else if(startToNow && startToNow <= (checkpoint.leadTime.planned * 60) && checkpoint.leadTime.planned !== 0){
        if(anchorEnd && isAnchorFeatureOn){
            checkpoint.end.predicted = anchorEnd;
            checkpoint.leadTime.predicted = moment(anchorEnd).diff(moment(checkpoint.start.realized),'minutes')
          }else{
          checkpoint.end.predicted = moment(checkpoint.start.realized).add((checkpoint.leadTime.predicted || checkpoint.leadTime.planned),'minutes').format();
        }
      }else if(startToNow && startToNow > (checkpoint.leadTime.planned * 60)){
        if(anchorEnd && isAnchorFeatureOn){
          checkpoint.end.predicted = anchorEnd;
          checkpoint.leadTime.predicted = moment(anchorEnd).diff(moment(checkpoint.start.realized),'minutes')

        }else{
          //TODO
          if(checkpoint.leadTime.predicted === checkpoint.leadTime.planned){
            checkpoint.leadTime.predicted = startToNow / 60;
          }
          checkpoint.end.predicted = moment(checkpoint.start.realized).add(((checkpoint.leadTime.predicted * 60) || startToNow ),'seconds').format();
        }
      }
      
    }else{
      checkpoint.phaseStatus = 'after';
      if(anchorStart && isAnchorFeatureOn){
        checkpoint.start.predicted = anchorStart;
      }else{
        checkpoint.start.predicted = previousPhase.end.realized || previousPhase.end.predicted;
      }
      if(anchorEnd && isAnchorFeatureOn){
        checkpoint.end.predicted = anchorEnd;
        checkpoint.leadTime.predicted = moment(anchorEnd).diff(moment(checkpoint.start.predicted),'minutes')
      }else{
        checkpoint.end.predicted = checkpoint.start.predicted && moment(checkpoint.start.predicted).add((checkpoint.leadTime.predicted || checkpoint.leadTime.planned),'minutes').format();
      }

    }

    if(checkpoint.start.realized && checkpoint.end.realized){
      checkpoint.leadTime.realized = moment(checkpoint.end.realized).diff(moment(checkpoint.start.realized), 'minutes')
      if(checkpoint.leadTime.realized && !isNaN(checkpoint.leadTime.realized) && checkpoint.leadTime.realized < 0){
        checkpoint.leadTime.realized = 0
      }
    }
  })

  let startDatePlanned = checkpoints[checkpoints.findIndex(ch => ch.phase.Phase === operationPhases[0].Phase)].end.planned;
  let startDatePredicted = checkpoints[checkpoints.findIndex(ch => ch.phase.Phase === operationPhases[0].Phase)].end.predicted;
  let startDateRealized= checkpoints[checkpoints.findIndex(ch => ch.phase.Phase === operationPhases[0].Phase)].end.realized;
  let deliveryDatePlanned = checkpoints[checkpoints.findIndex(ch => ch.phase.Phase === operationPhases[operationPhases.length - 1].Phase)].end.planned;
  let deliveryDatePredicted = checkpoints[checkpoints.findIndex(ch => ch.phase.Phase === operationPhases[operationPhases.length - 1].Phase)].end.predicted;
  let deliveryDateRealized = checkpoints[checkpoints.findIndex(ch => ch.phase.Phase === operationPhases[operationPhases.length - 1].Phase)].end.realized;

  const lastInlandLeg = operation.Phases.find( phase => phase.PhaseType === "last_inland_leg");

  let deliveryReferencePhase = {
    phase: lastInlandLeg && lastInlandLeg.Phase,
    startOrEnd: "end"
  };

  let deliveryReferenceCheckpoint = checkpoints.find(ch => ch.phase.Phase === deliveryReferencePhase.phase);

  let warehouseInboundDatePlanned = deliveryReferenceCheckpoint && deliveryReferenceCheckpoint[deliveryReferencePhase.startOrEnd] && deliveryReferenceCheckpoint[deliveryReferencePhase.startOrEnd].planned;
  let warehouseInboundDateRealized = deliveryReferenceCheckpoint && deliveryReferenceCheckpoint[deliveryReferencePhase.startOrEnd] && deliveryReferenceCheckpoint[deliveryReferencePhase.startOrEnd].realized;
  let warehouseInboundDatePredicted = deliveryReferenceCheckpoint && deliveryReferenceCheckpoint[deliveryReferencePhase.startOrEnd] && deliveryReferenceCheckpoint[deliveryReferencePhase.startOrEnd].predicted;

  let warehouseDeliveryDatePlanned = checkpoints[checkpoints.findIndex(ch => ch.phase.Phase === operationPhases[operationPhases.length - 1].Phase)].start.planned;
  let warehouseDeliveryDateRealized = checkpoints[checkpoints.findIndex(ch => ch.phase.Phase === operationPhases[operationPhases.length - 1].Phase)].start.realized;

  if(moment(deliveryDatePredicted).diff(moment(deliveryDatePlanned),'minutes') < 1440){
    status = 'ontime';
    statusMessage = {
      'pt-br': `ONTIME`,
      'en-gb': `ONTIME`
    }
    statusMessageLeadTime = {
      'pt-br': ``,
      'en-gb': ``
    }
    statusMessageColor = successColor;
  }else if(moment(deliveryDatePredicted).valueOf() < moment(deliveryDatePlanned).valueOf()){
    statusMessage = {
      'pt-br': `ADIANTADO`,
      'en-gb': `AHEAD`
    };
    status = 'ontime';
    statusMessageLeadTime = {
      'pt-br': `${getSLA(moment(deliveryDatePlanned).diff(moment(deliveryDatePredicted),'minutes'))}`,
      'en-gb': `${getSLA(moment(deliveryDatePlanned).diff(moment(deliveryDatePredicted),'minutes'))}`
    }
    statusMessageColor = successColor;
  }else if(moment(deliveryDatePredicted).valueOf() > moment(deliveryDatePlanned).valueOf()){
    statusMessage = {
      'pt-br': `ATRASADO`,
      'en-gb': `DELAYED`
    }
    status = 'delayed';
    statusMessageLeadTime = {
      'pt-br': `${getSLA(moment(deliveryDatePredicted).diff(moment(deliveryDatePlanned),'minutes'))}`,
      'en-gb': `${getSLA(moment(deliveryDatePredicted).diff(moment(deliveryDatePlanned),'minutes'))}`
    }
    statusMessageColor = dangerColor;
  }else if(order.currentPhase.IsTimelessPhase){
    if(moment().isBefore(startDatePlanned)){
      status = 'ontime';
      statusMessage = {
        'pt-br': `PRAZO PEDIDO`,
        'en-gb': `ORDER LT`
      }
      statusMessageLeadTime = {
        'pt-br': `${getSLA(moment(startDatePlanned).diff(moment(),'minutes'))}`,
        'en-gb': `${getSLA(moment(startDatePlanned).diff(moment(),'minutes'))}`
      }
      statusMessageColor = successColor;
    }
  }else{
    status = 'ontime';
    statusMessage = {
      'pt-br': ` `,
      'en-gb': ` `
    }
    statusMessageLeadTime = {
      'pt-br': ` `,
      'en-gb': ` `
    }
    statusMessageColor = 'gray';
  }
  
  let firstCheckpoint = checkpoints[checkpoints.findIndex(ck => ck.phase.Phase === operationPhases.filter(ph => !ph.IsTimelessPhase)[0].Phase)]
  
  startDatesOffset = moment(firstCheckpoint.start.realized || firstCheckpoint.start.predicted).diff( moment(firstCheckpoint.start.planned), 'minutes')


  let processStatus = {
    checkpoints: checkpoints,
    status: status,
    totalLeadTimePlanned: totalLeadTimePlanned,
    totalLeadTimePredicted: totalLeadTimePredicted,
    deliveryDatePlanned: deliveryDatePlanned,
    deliveryDatePredicted: deliveryDatePredicted,
    deliveryDateRealized: deliveryDateRealized,
    startDatePlanned: startDatePlanned,
    startDatePredicted: startDatePredicted,
    startDateRealized: startDateRealized,
    limitRequestDate: limitRequestDate,
    startDatesOffset: startDatesOffset,
    statusMessage: statusMessage,
    statusMessageColor: statusMessageColor,
    statusMessageLeadTime: statusMessageLeadTime,
    warehouseDeliveryDatePlanned: warehouseDeliveryDatePlanned,
    warehouseDeliveryDateRealized: warehouseDeliveryDateRealized,
    warehouseInboundDatePlanned,
    warehouseInboundDateRealized,
    warehouseInboundDatePredicted
  }

  if(order.automations){
    let alerts = getAutomationAlerts(order)
    processStatus.alerts = {
      ...alerts
    }
  }
  
  return processStatus
}
