import * as reducers from '../actions/types/index';

const INITIAL_STATE = {
  error: '',
  info: '',
  success: '',
  blockMessage: ''
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case reducers.ERROR:
      return {
        ...state,
        error: action.payload
      };
    case reducers.INFO:
      return {
        ...state,
        info: action.payload
      };
    case reducers.SUCCESS:
      return {
        ...state,
        success: action.payload
      };
    case reducers.BLOCK_MESSAGE:
      return {
        ...state,
        blockMessage: action.payload
      };
    default:
      return state;
  }
}
