const INITIAL_STATE = {
};


export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
   
    default:
      return state;
  }
}
