const INITIAL_STATE = {
  firstAccessForm: {
    cpf: null,
    IAgree: true,
    birthday: null,
    country: null,
    name: null,
    newPassword: null,
    newPasswordCheck: null,
  }
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@USER/FIRST_ACCESS_FORM':
      return {
        ...state,
        firstAccessForm: {
          ...state.firstAccessForm,
          ...action.payload
        }
      };
    default:
      return state;
  }
}
