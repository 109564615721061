// ##############################
// // // @material-ui/core/Avatar styles
// #############################

const avatarStyle = theme => ({
  avatar: {
    margin: '0px',
    border: 0,
    objectFit: 'cover',
    height: '55px',
    width: '55px'
  },

  avatarImg: {
    maxHeight: '60px'
  },

  avatarSidebarImg: {
    maxHeight: '40px'
  },

  smallAvatarImg: {
    maxHeight: '50px'
  },

  bigAvatar: {
    margin: '10px 25px 10px 10px',
    width: 70,
    height: 70
  },

  smallAvatar: {
    width: 50,
    height: 50
  },

  miniAvatar: {
    width: 30,
    height: 30
  },

  miniAvatarImg: {
    maxHeight: '40px'
  },

  bigAvatarImg: {
    maxHeight: '70px'
  },
  vehicleAvatar: {
    maxWidth: 65
  },
  trailerAvatar: {
    maxWidth: 65
  }
});

export default avatarStyle;
