// ##############################
// // // Header styles
// #############################

import { containerFluid, dangerColor, defaultBoxShadow, defaultFont, infoColor, primaryColor, successColor, warningColor } from '../../assets/default-css';

const Styles = theme => ({
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderBottom: '0',
    marginBottom: '0',
    position: 'absolute',
    width: '100%',
    paddingTop: '10px',
    zIndex: '1029',
    color: '#555555',
    border: '0',
    borderRadius: '3px',
    padding: '10px 0',
    transition: 'all 150ms ease 0s',
    minHeight: '50px',
    display: 'block'
  },
  input: {
    padding: "12px !important"
  },
  container: {
    ...containerFluid,
    minHeight: window.innerWidth >= 3700 ? '200px' : '40px'
  },
  commonContainer: {
    ...containerFluid,
    minHeight: window.innerWidth >= 3700 ? '200px' : '40px',
    marginTop:'-10px'
  },
  toolbar: {
    flex: 1, 
    display:'flex', 
    flexDirection:'row', 
    alignItems:'center',
    '& div': {
      '& svg': {
        top: 'initial',
      }
    }
  },
  title: {
    ...defaultFont,
    lineHeight: '30px',
    borderRadius: '3px',
    textTransform: 'none',
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    marginLeft: '15px', 
    fontSize: 22, 
    fontWeight: 'bolder', 
    color: '#404040 !important',
    paddingLeft: 0,
    paddingRight: '15px'
  },
  primary: {
    backgroundColor: primaryColor,
    color: '#FFFFFF',
    ...defaultBoxShadow
  },
  info: {
    backgroundColor: infoColor,
    color: '#FFFFFF',
    ...defaultBoxShadow
  },
  success: {
    backgroundColor: successColor,
    color: '#FFFFFF',
    ...defaultBoxShadow
  },
  warning: {
    backgroundColor: warningColor,
    color: '#FFFFFF',
    ...defaultBoxShadow
  },
  danger: {
    backgroundColor: dangerColor,
    color: '#FFFFFF',
    ...defaultBoxShadow
  },
  sidebarMinimize: {
    float: 'left',
    padding: '0 0 0 15px',
    display: 'block',
    color: '#555555'
  },
  sidebarMiniIcon: {
    width: '20px',
    height: '17px'
  },
  dateFilter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '6px',
    backgroundColor: 'white',
    height: '45px',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)'
  },
  dateFilterSortArrowUpward: {
    fontSize: 18
  },

  dateFilterSortArrowDownward: {
    fontSize: 18
  },

  textFieldSelect: {
    margin: '6px',
    marginRight: '20px',
    minWidth: '200px',
    maxWidth: '250px',
    height: '58px',
    width: '100%'
  }
});

export default Styles;
