/**
 * Clears the input value from mask placeholder characters.
 * @param value The input value.
 */
export const clearInputPlaceholder = (value) => (
  value
    ? String(value)
      .split("_")
      .join("")
    : undefined
);
