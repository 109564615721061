import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import DiagramSankey from '../../assets/icons/DiagramSankey.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// @material-ui/core
import Avatar from '@material-ui/core/Avatar';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons
import ArrowBack from '@material-ui/icons/ArrowBack';
import PermIdentity from '@material-ui/icons/PermIdentity';

// components
import HeaderLinks from '../Header/HeaderLinks';
import SidebarWrapper from './SidebarWrapper';

// styles
import avatarStyle from '../../assets/jss/avatar-styles';
import Styles from './Styles';

// enum
import * as modulesEnum from '../../domainmodel/enum/module';
import * as viewsEnum from '../../domainmodel/enum/view';
import * as actions from '../../actions/user_actions';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openAvatar: false,
      drawerBigMode: false,
    };

    this.mainPanel = null;

    this.setMainPanelRef = element => {
      this.mainPanel = element;
    };
  }

  svgIcon() {
    return <img style={{ color: 'white', fontSize: 24 }} width="34px" src={DiagramSankey} />;
  }

  isActiveRoute(routeName) {
    const { location } = this.props;
    return !!location.pathname.endsWith(routeName);
  }

  openCollapse(collapse) {
    const st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  openUserData = () => {
    const { userData, setUserData } = this.props;
    userData.dialog = true;
    setUserData(userData);
    this.forceUpdate();
  };

  checkModulePermission(moduleType) {
    const { userLogged } = this.props;
    let permited = false;

    userLogged.Authorization.Profiles.forEach(profile => {
      profile.Roles.forEach(role => {
        role.Views.forEach(view => {
          if (moduleType === modulesEnum.Admin && view.ViewType === viewsEnum.Maintenance) {
            permited = true;
          } else if (moduleType === modulesEnum.Maintenance && view.ViewType === viewsEnum.Maintenance) {
            permited = true;
          } else if (
            moduleType === modulesEnum.RoadCarrier &&
            [viewsEnum.RoadCarrier, viewsEnum.Vehicles].includes(view.ViewType)
          ) {
            permited = true;
          } else if (moduleType === modulesEnum.Registers && view.ViewType === viewsEnum.ProductRegisters) {
            permited = true;
          } else if (moduleType === modulesEnum.Dashboard && view.ViewType === viewsEnum.Dashboard) {
            permited = true;
          } else if (moduleType === modulesEnum.Finished && view.ViewType === viewsEnum.Finished) {
            permited = true;
          } else if (moduleType === modulesEnum.Operations && view.ViewType === viewsEnum.Operations) {
            permited = true;
          } else if (moduleType === modulesEnum.Planning && view.ViewType === viewsEnum.Planning) {
            permited = true;
          } else if (moduleType === modulesEnum.Reports && view.ViewType === viewsEnum.Reports) {
            permited = true;
          } else if (moduleType === modulesEnum.Tasks && view.ViewType === viewsEnum.Tasks) {
            permited = true;
          } else if (moduleType === modulesEnum.Tracking && view.ViewType === viewsEnum.Tracking) {
            permited = true;
          } else if (moduleType === modulesEnum.Automations && view.ViewType === viewsEnum.Automations) {
            permited = true;
          } else if (moduleType === modulesEnum.Integrations && view.ViewType === viewsEnum.Integrations) {
            permited = true;
          } else if (moduleType === modulesEnum.TrackingMonitor && view.ViewType === viewsEnum.TrackingMonitor) {
            permited = true;
          } else if (
            moduleType === modulesEnum.IntegrationsMonitor &&
            view.ViewType === viewsEnum.IntegrationsMonitor
          ) {
            permited = true;
          } else if (moduleType === modulesEnum.Actors && view.ViewType === viewsEnum.Actors) {
            permited = true;
          } else if (moduleType === modulesEnum.Config && view.ViewType === viewsEnum.Config) {
            permited = true;
          } else if (moduleType === modulesEnum.AdminFlowls && view.ViewType === viewsEnum.AdminFlowls) {
            permited = true;
          } else if (moduleType === modulesEnum.N8N && view.ViewType === viewsEnum.N8N) {
            permited = true;
          } else if (moduleType === modulesEnum.ValueChain && view.ViewType === viewsEnum.ValueChain) {
            permited = true;
          } else if (moduleType === modulesEnum.GlobalTracking && view.ViewType === viewsEnum.GlobalTracking) {
            permited = true;
          } else if (moduleType === modulesEnum.Apps && view.ViewType === viewsEnum.Apps) {
            permited = true;
          } else if (moduleType === modulesEnum.Users && view.ViewType === viewsEnum.Users) {
            permited = true;
          } else if (moduleType === modulesEnum.Billing && view.ViewType === viewsEnum.Billing) {
            permited = true;
          } else if (moduleType === modulesEnum.Credentials && view.ViewType === viewsEnum.Credentials) {
            permited = true;
          }
        });
      });
    });

    return permited;
  }

  checkViewPermission(viewType) {
    const { userLogged } = this.props;
    let permited = false;
    userLogged.Authorization.Profiles.forEach(profile => {
      profile.Roles.forEach(role => {
        role.Views.forEach(view => {
          if (view.ViewType === viewType) {
            permited = true;
          }
        });
      });
    });
    return permited;
  }

  render() {
    const {
      classes,
      color,
      logo,
      logoMini,
      image,
      routes,
      bgColor,
      userLogged,
      language,
      signOutUser,
      toggleSidebarDrawerOpen,
      open,
      currentUser,
    } = this.props;

    const { drawerBigMode, openAvatar } = this.state;

    const itemText = `${classes.itemText} ${cx({
      [classes.itemTextMini]: !drawerBigMode,
    })}`;
    const itemTextOnlyBig = `${classes.itemText}`;

    const collapseItemText = `${classes.collapseItemText} ${cx({
      [classes.collapseItemTextMini]: !drawerBigMode,
    })}`;
    const collapseItemTextOnlyBig = `${classes.collapseItemText}`;

    const userWrapperClass = `${classes.user} ${cx({
      [classes.whiteAfter]: bgColor === 'white',
    })}`;
    const caret = `${classes.caret}`;
    const itemIcon = `${classes.itemIcon}`;
    const collapseItemMini = `${classes.collapseItemMini}`;
    const photo = `${classes.photo}`;

    const signoutName = { ['en-gb']: 'Sign out', 'pt-br': 'Sair' };
    const user = (itemTextClass, collapseItemTextClass) => (
      <div className={userWrapperClass}>
        <div className={photo}>
          {
            currentUser?.picture && (
              <Avatar alt="Driver" className={classes.avatar}>
                <img src={currentUser?.picture} alt="" className={classes.avatarImg} />
              </Avatar>
            )
          }
        </div>

        <List className={classes.listImg}>
          <ListItem className={`${classes.item} ${classes.userItem}`}>
            <NavLink
              to="#"
              className={`${classes.itemLink} ${classes.userCollapseButton}`}
              onClick={() => this.openCollapse('openAvatar')}
            >
              <ListItemText
                primary={userLogged?.Name}
                disableTypography
                className={`${itemTextClass} ${classes.userItemText}`}
              />
              <ListItemText
                primary={currentUser?.email ?? ''}
                secondary={<b className={`${caret} ${classes.userCaret} ${openAvatar ? classes.caretActive : ''}`} />}
                disableTypography
                className={`${itemTextClass} ${classes.userEmailText}`}
              />
            </NavLink>
            <Collapse in={openAvatar} unmountOnExit classes={{ wrapper: classes.colapseWraper }}>
              <List className={`${classes.listImg} ${classes.collapseList}`}>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="#"
                    className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                    onClick={() => signOutUser()}
                  >
                    <span className={collapseItemMini}>
                      <ArrowBack />
                    </span>
                    <ListItemText primary={signoutName[language]} disableTypography className={collapseItemTextClass} />
                  </NavLink>
                </ListItem>
                {/* <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="#"
                    className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                    onClick={() => this.openUserData()}
                  >
                    <span className={collapseItemMini}>
                      <PermIdentity />
                    </span>
                    <ListItemText primary={configName[language]} disableTypography className={collapseItemTextClass} />
                  </NavLink>
                </ListItem> */}
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
    );

    const links = (itemTextClass, collapseItemTextClass) => (
      <List className={classes.list}>
        {routes
          .filter(m => m.permission !== modulesEnum.Admin)
          .map(moduleType => {
            if (
              moduleType.redirect === true ||
              moduleType.sidebar === false ||
              userLogged == null ||
              userLogged.Authorization == null
            ) {
              return null;
            }

            const permited = this.checkModulePermission(moduleType.permission);
            if (!permited) {
              return null;
            }

            if (moduleType.collapse === true) {
              const navLinkClasses = `${classes.itemLink} ${cx({
                [` ${classes.collapseActive}`]: this.isActiveRoute(moduleType.path),
              })}`;

              return (
                <ListItem className={classes.item} key={moduleType.key}>
                  <NavLink to="#" className={navLinkClasses} onClick={() => this.openCollapse(moduleType.state)}>
                    <ListItemIcon className={itemIcon}>
                      {moduleType.svg ? (
                        this.svgIcon()
                      ) : moduleType.fa ? (
                        <FontAwesomeIcon icon={moduleType.icon} style={{ color: 'white', fontSize: 24 }} />
                      ) : (
                        <moduleType.icon />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={moduleType.name[language]}
                      secondary={
                        <b className={`${caret} ${this.state[moduleType.state] ? classes.caretActive : ''}`} />
                      }
                      disableTypography
                      className={itemTextClass}
                    />
                  </NavLink>

                  <Collapse in={this.state[moduleType.state]} unmountOnExit>
                    <List className={`${classes.list} ${classes.collapseList}`}>
                      {moduleType.views.map(viewType => {
                        if (userLogged == null || userLogged.Authorization == null) {
                          return null;
                        }
                        const permited = this.checkViewPermission(viewType.permission);

                        if (!permited) {
                          return null;
                        }

                        const collapsedNavLinkClasses = `${classes.collapseItemLink} ${cx({
                          [` ${classes[color]}`]: this.isActiveRoute(viewType.path),
                        })}`;
                        return (
                          <ListItem className={classes.collapseItem} key={viewType.key}>
                            <NavLink to={viewType.path} className={collapsedNavLinkClasses}>
                              <span className={collapseItemMini}>
                                {viewType.icon ? (
                                  viewType.svg ? (
                                    this.svgIcon()
                                  ) : viewType.fa ? (
                                    <FontAwesomeIcon icon={viewType.icon} style={{ color: 'white', fontSize: 18 }} />
                                  ) : (
                                    <viewType.icon />
                                  )
                                ) : (
                                  viewType.mini
                                )}
                              </span>
                              <ListItemText
                                primary={viewType.name[language]}
                                disableTypography
                                className={collapseItemTextClass}
                              />
                            </NavLink>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </ListItem>
              );
            }

            const navLinkClasses = `${classes.itemLink} ${cx({
              [` ${classes[color]}`]: this.isActiveRoute(moduleType.path),
            })}`;

            return (
              <ListItem className={classes.item} key={moduleType.key}>
                <NavLink to={moduleType.path} className={navLinkClasses}>
                  <ListItemIcon className={itemIcon}>
                    {moduleType.svg ? (
                      this.svgIcon()
                    ) : moduleType.fa ? (
                      <FontAwesomeIcon icon={moduleType.icon} style={{ color: 'white', fontSize: 24 }} />
                    ) : (
                      <moduleType.icon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={moduleType.name[language]} disableTypography className={itemTextClass} />
                </NavLink>
              </ListItem>
            );
          })}
      </List>
    );

    const tools = (itemTextClass, collapseItemTextClass) => (
      <List className={classes.list}>
        {routes
          .filter(m => m.permission === modulesEnum.Admin)
          .map(moduleType => {
            if (
              moduleType.redirect === true ||
              moduleType.sidebar === false ||
              userLogged == null ||
              userLogged.Authorization == null
            ) {
              return null;
            }

            const permited = this.checkModulePermission(moduleType.permission);
            if (!permited) {
              return null;
            }

            if (moduleType.collapse === true) {
              const navLinkClasses = `${classes.itemLink} ${cx({
                [` ${classes.collapseActive}`]: this.isActiveRoute(moduleType.path),
              })}`;

              return (
                <ListItem className={classes.item} key={moduleType.key}>
                  <NavLink to="#" className={navLinkClasses} onClick={() => this.openCollapse(moduleType.state)}>
                    <ListItemIcon className={itemIcon}>
                      {moduleType.svg ? (
                        this.svgIcon()
                      ) : moduleType.fa ? (
                        <FontAwesomeIcon icon={moduleType.icon} style={{ color: 'white', fontSize: 24 }} />
                      ) : (
                        <moduleType.icon />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={moduleType.name[language]}
                      secondary={
                        <b className={`${caret} ${this.state[moduleType.state] ? classes.caretActive : ''}`} />
                      }
                      disableTypography
                      className={itemTextClass}
                    />
                  </NavLink>

                  <Collapse in={this.state[moduleType.state]} unmountOnExit>
                    <List className={`${classes.list} ${classes.collapseList}`}>
                      {moduleType.views.map(viewType => {
                        if (userLogged == null || userLogged.Authorization == null) {
                          return null;
                        }
                        const permited = this.checkViewPermission(viewType.permission);
                        if (!permited) {
                          return null;
                        }

                        const collapsedNavLinkClasses = `${classes.collapseItemLink} ${cx({
                          [` ${classes[color]}`]: this.isActiveRoute(viewType.path),
                        })}`;
                        return (
                          <ListItem className={classes.collapseItem} key={viewType.key}>
                            <NavLink to={viewType.path} className={collapsedNavLinkClasses}>
                              <span className={collapseItemMini}>
                                {viewType.icon ? (
                                  viewType.svg ? (
                                    this.svgIcon()
                                  ) : viewType.fa ? (
                                    <FontAwesomeIcon icon={viewType.icon} style={{ color: 'white', fontSize: 18 }} />
                                  ) : (
                                    <viewType.icon />
                                  )
                                ) : (
                                  viewType.mini
                                )}
                              </span>
                              <ListItemText
                                primary={viewType.name[language]}
                                disableTypography
                                className={collapseItemTextClass}
                              />
                            </NavLink>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </ListItem>
              );
            }

            const navLinkClasses = `${classes.itemLink} ${cx({
              [` ${classes[color]}`]: this.isActiveRoute(moduleType.path),
            })}`;
            return (
              <ListItem className={classes.item} key={moduleType.key}>
                <NavLink to={moduleType.path} className={navLinkClasses}>
                  <ListItemIcon className={itemIcon}>
                    {moduleType.svg ? (
                      this.svgIcon()
                    ) : moduleType.fa ? (
                      <FontAwesomeIcon icon={moduleType.icon} style={{ color: 'white', fontSize: 24 }} />
                    ) : (
                      <moduleType.icon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={moduleType.name[language]} disableTypography className={itemTextClass} />
                </NavLink>
              </ListItem>
            );
          })}
      </List>
    );

    const logoMiniClasses = `${classes.logoMini}`;
    const logoClasses = `${classes.logo} ${cx({
      [classes.whiteAfter]: bgColor === 'white',
      [classes.logoNoText]: drawerBigMode,
    })}`;

    const logoClassesOnlyBig = `${classes.logo} ${cx({
      [classes.whiteAfter]: bgColor === 'white',
      [classes.logoNoText]: true,
    })}`;

    const imgClasses = `${cx({
      [classes.imgNoText]: drawerBigMode,
      [classes.img]: !drawerBigMode,
    })}`;
    const imgClassesOnlyBig = `${cx({
      [classes.imgNoText]: true,
    })}`;

    const brand = (
      <div className={logoClasses}>
        <a className={logoMiniClasses} href="/">
          <img className={imgClasses} src={drawerBigMode ? logo : logoMini} alt="logo" />
        </a>
      </div>
    );

    const brandOnlyBig = (
      <div className={logoClassesOnlyBig}>
        <a className={logoMiniClasses} href="/">
          <img src={logo} alt="logo" className={imgClassesOnlyBig} />
        </a>
      </div>
    );

    const drawerPaper = `${classes.drawerPaper} ${cx({
      [classes.drawerPaperMini]: !drawerBigMode,
    })}`;

    const drawerPaperOnlyBig = `${classes.drawerPaper}`;

    const sidebarWrapper = `${classes.sidebarWrapper} ${cx({
      [classes.drawerPaperMini]: !drawerBigMode,
      [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
    })}`;

    const sidebarWrapperOnlyBig = `${classes.sidebarWrapper} ${cx({
      [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
    })}`;

    return (
      <div ref={this.setMainPanelRef}>
        <Hidden mdUp>
          <Drawer
            onClose={() => {
              this.setState({ drawerBigMode: false });
              toggleSidebarDrawerOpen();
            }}
            anchor={'left'}
            variant="temporary"
            open={open}
            classes={{
              paper: `${drawerPaperOnlyBig} ${classes[`${bgColor}Background`]}`,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brandOnlyBig}
            <SidebarWrapper
              className={sidebarWrapperOnlyBig}
              user={user(itemTextOnlyBig, collapseItemTextOnlyBig)}
              headerLinks={<HeaderLinks language={language} />}
              links={links(itemTextOnlyBig, collapseItemTextOnlyBig)}
              tools={tools(itemTextOnlyBig, collapseItemTextOnlyBig)}
            />
            {
              image && (
                <div className={classes.background} style={{ backgroundImage: `url(${image})` }} />
              )
            }
          </Drawer>
        </Hidden>

        <Hidden smDown>
          <Drawer
            onMouseOver={() => this.setState({ drawerBigMode: true })}
            onMouseOut={() => this.setState({ drawerBigMode: false })}
            anchor={'left'}
            variant="permanent"
            classes={{
              paper: `${drawerPaper} ${classes[`${bgColor}Background`]}`,
            }}
          >
            {brand}

            <SidebarWrapper
              className={sidebarWrapper}
              user={user(itemText, collapseItemText)}
              links={links(itemText, collapseItemText)}
              tools={tools(itemText, collapseItemText)}
              headerLinks={<div />}
            />

            {
              image && (
                <div className={classes.background} style={{ backgroundImage: `url(${image})` }} />
              )
            }
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: 'blue',
};

Sidebar.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  userLogged: PropTypes.instanceOf(Object).isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
  language: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['white', 'red', 'orange', 'green', 'blue', 'purple', 'rose']).isRequired,
  logo: PropTypes.string.isRequired,
  logoMini: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  routes: PropTypes.instanceOf(Array).isRequired,

  signOutUser: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  toggleSidebarDrawerOpen: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    userLogged: state.auth.userLogged,
    currentUser: state.auth.currentUser,
  };
};

export default connect(mapStateToProps, {
  setUserData: actions.setUserData,
})(
  withStyles(
    theme => ({
      ...Styles(theme),
      ...avatarStyle(theme),
    }),
    { withTheme: true },
  )(Sidebar),
);
