export const AdminUserInsert = 1;
export const AdminPartnerInsert = 2;
export const Dashboard = 3;
export const Maintenance = 4;
export const Operations = 5; //
export const Tasks = 6;
export const Planning = 7; //
export const Data = 8;
export const CompanyRegisters = 9;
export const UsersRegisters = 10;
export const ProductRegisters = 11;
export const Registers = 12;
export const Finished = 13; //
export const Tracking = 14;
export const RoadCarrier = 15;
export const Vehicles = 16;
export const Reports = 17; //
export const LeadtimeRegisters = 18;
export const AlertRegisters = 19;
export const RouteRegisters = 20;
export const Integrations = 21;
export const Automations = 22;
export const TrackingMonitor = 23;
export const Actors = 24;
export const IntegrationsMonitor = 25;
export const Config = 26;
export const AdminFlowls = 27;
export const Drivers = 28;
export const N8N = 29;
export const ValueChain = 30;
export const GlobalTracking = 31;
export const Apps = 32;
export const Users = 33;
export const Billing = 34;
export const Credentials = 35;


