import React from "react";
import { Box } from "@material-ui/core";

import fullDark from "../../assets/logo/full_dark.png";
import fullWhite from "../../assets/logo/full_white.png";
import hFullWhite from "../../assets/logo/h_full_white.png";
import miniWhite from "../../assets/logo/mini_white.png";
import shortDark from "../../assets/logo/short_dark.png";
import shortWhite from "../../assets/logo/short_white.png";

const icons = {
  fullDark,
  fullWhite,
  hFullWhite,
  miniWhite,
  shortDark,
  shortWhite,
};

const Logo = ({
  sx,
  variant,
  imgHeight,
  imgWidth,
  objectFit,
  objectPosition,
}) => (
  <Box sx={sx}>
    <img
      src={icons[variant]}
      alt="Flows"
      style={{
        width: imgWidth || 40,
        height: imgHeight || 40,
        objectFit,
        objectPosition,
      }}
    />
  </Box>
);

Logo.defaultProps = {
  variant: "fullDark",
};

export default Logo;
