
import * as services from './services';
import uuid from 'react-uuid'

export function getProducts(user, isComplete) {
  return new Promise(async (resolve, reject) => {
    services
      .getProducts(user, isComplete)
      .then(result => {
        
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function saveProduct(user, product) {
  return new Promise(async (resolve, reject) => {
    if(!product.skuNumber){
      product.skuNumber = uuid();
    }
    services
      .saveProduct(user, product)
      .then(result => {
        
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function updateProduct(user, product) {
  return new Promise(async (resolve, reject) => {
    services
      .updateProduct(user, product)
      .then(result => {
        
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function deleteProduct(user, id) {
  return new Promise(async (resolve, reject) => {
    services
      .deleteProduct(user.Token, id)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getByID(user, id) {
  return new Promise(async (resolve, reject) => {
    services
      .getByID(user, id)
      .then(result => {
        
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getMailer(user) {
  return new Promise(async (resolve, reject) => {
    services
      .getMailer(user)
      .then(result => {
        
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}