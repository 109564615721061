import moment from 'moment';

import * as services from '../services/search_service';

export const getCPFfromAPI = (token, cpf) => {
  return new Promise(async (resolve, reject) => {
    services
      .getCPFfromAPI(token, cpf)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getCNHfromAPI = (token, cpf, cnh, cnhSecurityCode) => {
  return new Promise(async (resolve, reject) => {
    services
      .getCNHfromAPI(token, cpf, cnh, cnhSecurityCode)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getCNPJfromAPI = (token, cnpj) => {
  return new Promise(async (resolve, reject) => {
    services
      .getCNPJ(token, cnpj)
      .then(result => {
        if (result.TransactionResultType === 'TransactionUnavailable') {
          reject(new Error('API Direct Intelligence Indisponível'));
        }

        if (result.TransactionResultType === '"InvalidInputData"') {
          reject(new Error('CNPJ Inválido'));
        }

        if (result.Result.SituacaoCadastral === 'BAIXADA') {
          reject(new Error(`CNPJ Baixado na Receita Federal em ${moment(result.Result.DataSituacaoCadastral).format('DD/MM/YYYY')}`));
        }
        resolve(result.Result);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getLocationFromAPI = address => {
  return new Promise(async (resolve, reject) => {
    services
      .getLocationFromAPI(address)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};
