import Api from '../api';

const getWorkspaceProfile = profiles => {
  return new Promise((resolve, reject) => {
    Api.get(`/flowlsapi/getworkspaceprofile/${profiles}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

const getActors = actors => {
  return new Promise((resolve, reject) => {
    Api.get(`/flowlsapi/actors/${actors}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const mapClientWorkspace = async user => {
  const workspaces = user['https://flowls.app/workspaces'];
  const workspaceProfiles = await getWorkspaceProfile(workspaces.map(workspace => workspace.legacy_profile));
  const workspaceOwners = await getActors(workspaces.map(workspace => workspace.legacy_client_id));
  return workspaceProfiles.map(workspaceProfile => {
    const workspaceData = workspaces.find(workspaceData => workspaceData.legacy_profile === workspaceProfile.Profile);
    const actor = workspaceOwners.find(workspace => workspace.OficialDocument === workspaceData.legacy_client_id);
    let profile = workspaceProfiles.find(workspaceProfile => workspaceProfile.Profile === workspaceData.legacy_profile);
    profile.Roles = profile.ClientRoleTypes.map(roleType => ({
      ClientRoleType: roleType,
      ...profile,
    }));
    return {
      Clients: [actor],
      Authorization: {
        ActorId: actor._id,
        ClientType: actor.ClientType,
        Country: actor.Country,
        SocialName: actor.SocialName,
        BusinessName: actor.BusinessName,
        OficialDocument: workspaceData?.legacy_client_id,
        Profile: workspaceData?.legacy_profile,
        Role: workspaceData?.role,
        Addresses: null,
        Profiles: [profile],
        CurrentRole: profile.Roles[0],
      },
    };
  });
};

export const mapUserData = user => {
  const userMetaData = user['https://flowls.app/user_metadata'];
  return {
    Email: user?.email,
    Name: userMetaData?.name,
    Gender: null,
    Country: userMetaData?.country_code,
    OficialDocument: userMetaData?.document_number,
  };
};

export const mapAuthorization = async user => {
  const clientWorkspaces = await mapClientWorkspace(user);
  let userUpdated = clientWorkspaces[0];
  userUpdated = insertActorsID(userUpdated);
  return userUpdated;
};

export const insertActorsID = user => {
  const result = user;
  const roles = [];
  result.Authorization.ClientID = `{"ClientType":${user.Authorization.ClientType}, "Country":"${user.Authorization.Country}", "OficialDocument":"${user.Authorization.OficialDocument}"}`;
  result.Authorization.Profiles = result.Authorization.Profiles.map(profile => {
    profile.Roles = profile.Roles.map(role => {
      role.Partners = role.Partners.map(partner => {
        return {
          ...partner,
          PartnerID: `{"ClientType":${partner.ClientType}, "Country":"${partner.Country}", "OficialDocument":"${partner.OficialDocument}"}`,
        };
      });
      if(role.Subsidiaries?.length){
        role.Subsidiaries = role.Subsidiaries.map(subsidiary => {
          return {
            ...subsidiary,
            PartnerID: `{"ClientType":${subsidiary.ClientType}, "Country":"${subsidiary.Country}", "OficialDocument":"${subsidiary.OficialDocument}"}`
          }
        });
      }
      roles.push(role.ClientRoleType);
      return role;
    });
    return profile;
  });
  result.Authorization.CurrentRole = result.Authorization.Profiles[0].Roles[0];
  return result;
};
