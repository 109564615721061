

import Axios from 'axios';
import Api from '../../App/api';

export const getReports = ((user) => {
  return new Promise((resolve, reject) => {
    let operations = [];
    user.Authorization.Profiles.forEach(profile => {
      profile.Roles.forEach(role => {
        role.Operations.forEach( op => {
          operations.push(op.OperationTypeCode)
        })
      })
    })
    Api.get(`/operations/reports/${operations}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error)); 
  });
});

export const getCompletedProcesses = ((user, cargoOwners) => {
  return new Promise((resolve, reject) => {
    let data = cargoOwners;
    //TODO
    let roles = user.Authorization.Profiles[0].Roles.map(rle => {
      return rle.ClientRoleType
    })
    
    Api.post(`/processes/listFinished/${JSON.stringify(roles)}`, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      }); 
  });
});

export const getProfileReports = user => {
  return new Promise((resolve, reject) => {
    let operations = [];
    let reports = [];
    user &&
      user.Authorization &&
      user.Authorization.Profiles.forEach(profile => {
        profile.Roles.forEach(role => {
          role.Operations.forEach(op => {
            operations.push(op.OperationTypeCode);
          });
          reports = [...reports, ...role.Reports];
        });
      });
      reports = reports.filter((report, i) => i === reports.findIndex(rep => rep === report))
    if (operations && operations.length && reports && reports.length) {
      Api.get(`/operations/reports/${operations}/${reports}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    }
  });
};

export const saveReport = ((userLogged, reportId, reportData) => {
  return new Promise((resolve, reject) => {
    Api.post(`/operations/updateReport/${reportId}`, reportData)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      }); 
  });
});

