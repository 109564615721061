import * as business from '../business/user_business';
import * as genders from '../domainmodel/enum/genderType';
import * as searchBusiness from '../business/search_business';

import * as layoutActions from './layout_actions';
import * as messageAction from './message_actions';
import * as reducers from './types';

const parseGender = genderStr => {
  if (genderStr === 'M') return genders.GenderTypeEnumMale;
  if (genderStr === 'F') return genders.GenderTypeEnumFemale;
  return null;
};

const cpfUnmasc = cpf => {
  return cpf.replace(/(\.|\/|-)/g, '');
};

export const list = (userLogged, start) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      if (start === true) {
        dispatch(layoutActions.block());
      }

      await business
        .list(userLogged.Token)
        .then(userList => {
          dispatch({ type: reducers.USER_LIST, payload: userList });
          if (start) {
            dispatch(layoutActions.unblock());
          }
        })
        .catch(error => {
          dispatch(messageAction.setError(error));
          if (start) {
            dispatch(layoutActions.unblock());
          }
        });
    }
  };
};

export const getByCPF = (userLogged, userSave) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      dispatch(layoutActions.block());

      await business
        .getByCPF(userLogged.Token, userSave.CPF.Code)
        .then(async user => {
          if (user == null) {
            await dispatch(getCPFfromAPI(userLogged, userSave));
            dispatch(layoutActions.unblock());
          } else if (user.Birthday == null) {
            await dispatch(getCPFfromAPI(userLogged, user));
            dispatch(layoutActions.unblock());
          } else {
            dispatch(setUserSave(user));
            dispatch(layoutActions.unblock());
          }
        })
        .catch(error => {
          dispatch(messageAction.setError(error));
          dispatch(layoutActions.unblock());
        });
    }
  };
};

export const getByID = (userLogged, userSave) => {
  return dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      dispatch(layoutActions.block());

      business
        .getByID(userLogged.Token, userSave.ID)
        .then(user => {
          dispatch(setUserSave(user));
          dispatch(layoutActions.unblock());
        })
        .catch(error => {
          dispatch(messageAction.setError(error));
          dispatch(layoutActions.unblock());
        });
    }
  };
};

export const save = (userLogged, user) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      dispatch(layoutActions.block());
      await business
        .save(userLogged.Token, user)
        .then(async message => {
          await dispatch(list(userLogged, false));

          user = {
            ID: null,
            Name: null,
            Email: null,
            Gender: null,
            Birthday: null,
            MotherName: null,
            Image: null,
            HomePhone: null,
            CellPhone: null,
            CPF: {
              ID: null,
              Code: null
            },
            CompanyProfiles: []
          };

          dispatch(setUserSave(user));
          dispatch(messageAction.setSuccess(message));
          dispatch(layoutActions.unblock());
        })
        .catch(error => {
          dispatch(messageAction.setError(error));
          dispatch(layoutActions.unblock());
        });
    }
  };
};

export const deleteUser = (userLogged, user) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      dispatch(layoutActions.block());
      await business
        .deleteUser(userLogged.Token, user)
        .then(async message => {
          await dispatch(list(userLogged, false));

          const userDelete = {
            ID: null
          };

          dispatch(setUserDelete(userDelete));
          dispatch(messageAction.setSuccess(message));
          dispatch(layoutActions.unblock());
        })
        .catch(error => {
          const userDelete = {
            ID: null
          };

          dispatch(setUserDelete(userDelete));
          dispatch(messageAction.setError(error));
          dispatch(layoutActions.unblock());
        });
    }
  };
};

export const setNewPassword = (userLogged, currentPassword, newPassword, newPasswordConfirmation) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      dispatch(layoutActions.block());
      await business
        .setNewPassword(userLogged, currentPassword, newPassword, newPasswordConfirmation)
        .then(async message => {
          let blankUserData = {
            userLogged: null,
            dialog: false,
            currentPassword: '',
            newPassword: '',
            newPasswordCheck: '',
            email: '',
            fone: '',
            passwordValidate: {
              message: '',
              color: ''
            }
          }
          dispatch(setUserData(blankUserData));
          dispatch(messageAction.setSuccess(message));
          dispatch(layoutActions.unblock());
        })
        .catch(error => {
          dispatch(messageAction.setError(error));
          dispatch(layoutActions.unblock());
        });
    }
  };
};

export const setUserSave = userSave => {
  return dispatch => {
    dispatch({ type: reducers.USER_SAVE, payload: userSave });
  };
};

export const setUserDelete = userDelete => {
  return dispatch => {
    dispatch({ type: reducers.USER_DELETE, payload: userDelete });
  };
};

export const getCPFfromAPI = (userLogged, user) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      dispatch(layoutActions.block());
      const cpfStr = cpfUnmasc(user.CPF.Code);

      await searchBusiness
        .getCPFfromAPI(userLogged.Token, cpfStr)
        .then(cpf => {
          let isTheUser = true;
          if (user.Name != null && user.Name !== '') {
            const allNames = user.Name.split(' ');

            allNames.forEach(name => {
              if (!cpf.Nome.includes(name)) {
                isTheUser = false;
              }
            });
          }

          if (isTheUser === true) {
            user.Name = cpf.Nome;
            user.Gender = parseGender(cpf.Sexo);
            user.MotherName = cpf.NomeMae;

            const dtStr = `${cpf.DataNascimento.split('.')[0]}Z`;
            const dt = new Date(dtStr);
            dt.setUTCHours(23, 59, 59);
            user.Birthday = `${dt.toISOString().split('.')[0]}Z`;

            dispatch(setUserSave(user));
            dispatch(layoutActions.unblock());
          } else {
            user.CPF = {
              ID: null,
              Code: null
            };

            dispatch(setUserSave(user));
            dispatch(messageAction.setError('Usuário não corresponde ao CPF digitado'));
            dispatch(layoutActions.unblock());
          }
        })
        .catch(error => {
          // TODO: show error na modal?
          dispatch(messageAction.setError(error));
          dispatch(layoutActions.unblock());
        });
    }
  };
};

export const setUserFilter = filter => {
  return dispatch => {
    if (filter.searchValue === '') {
      filter.FilteredList = null;
    } else {
      filter.FilteredList = filter.list.filter(
        user => user.Name.toUpperCase().includes(filter.searchValue.toUpperCase()) || user.CNH.Code.toUpperCase().includes(filter.searchValue.toUpperCase()) || user.CPF.Code.toUpperCase().includes(filter.searchValue.toUpperCase())
      );
    }
    dispatch({
      type: reducers.USER_FILTER,
      payload: filter
    });
  };
};

export const setUserData = data => {
  return dispatch => {
    dispatch({ type: reducers.USER_DATA, payload: data });
  };
};

