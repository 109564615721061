import { useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { mapUserData, mapAuthorization } from '../utils/handleUserAccess';

import useAuth0Configs from '../hooks/useAuth0Configs'

const useSession = () => {
  const dispatch = useDispatch();
  const { auth0Config, scope } = useAuth0Configs();
  const { user, isAuthenticated, getIdTokenClaims, isLoading, getAccessTokenSilently, logout } = useAuth0();
  const userLogged = useSelector(state => state.auth.userLogged);

  const onGetAccessTokenSilently = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: auth0Config.audience,
        scope,
        ignoreCache: true,
      });
      dispatch({ type: 'SET_CURRENT_USER', payload: { accessToken } });
    } catch (error) {
      console.error(error);
    }
  }, [
    getAccessTokenSilently,
    auth0Config.audience,
    scope,
  ]);

  const onGetIdTokenClaims = useCallback(async () => {
    try {
      const token = await getIdTokenClaims();
      const idToken = token?.__raw;

      dispatch({ type: 'SET_TOKEN', payload: idToken });
    } catch (error) {
      console.error(error);
    }
  }, [getIdTokenClaims]);

  useEffect(() => {
    if (!isAuthenticated && isLoading) return;

    onGetIdTokenClaims();
  }, [isAuthenticated, onGetIdTokenClaims, isLoading]);

  useEffect(() => {
    if (isAuthenticated && user) {
      if (user["https://flowls.app/workspaces"] && user["https://flowls.app/workspaces"][0] && user["https://flowls.app/workspaces"][0].legacy_client_id) {
        dispatch({ type: 'SET_CURRENT_USER', payload: user });
        // dispatch({ type: 'SET_CLIENTS', payload: mapClientWorkspace(user) });
        dispatch({ type: 'USER_LOGGED', payload: { ...mapUserData(user) } });
      } else {
        window.location.replace('https://flowls.app');
      }
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (userLogged && userLogged.Token && !userLogged.Authorization) {
      const saveUserProfile = async () => {
        const userProfile = await mapAuthorization(user);

        dispatch({ type: 'USER_LOGGED', payload: { ...userLogged, ...userProfile } });
      };
      saveUserProfile();
    }
  }, [userLogged]);

  useEffect(() => {
    if (isAuthenticated && user && !isLoading) {
      onGetAccessTokenSilently();
    }
  }, [isAuthenticated, user, isLoading]);
};

export default useSession;
