import React from "react";
import ReactInputMask from "react-input-mask";
import { TextField } from '@material-ui/core';

const InputMask = ({
  label,
  mask,
  childrenProps,
  disabled = false,
  onChange,
  ...rest
}) => (
  <ReactInputMask
    mask={mask}
    disabled={disabled}
    onChange={e => onChange && onChange(e.target.value)}
    onBlur={e => onChange && onBlur(e.target.value)}
    {...rest}
  >
    {(inputProps) => (
      <TextField
        {...inputProps}
        {...childrenProps}
        label={label}
        fullWidth
        disabled={disabled}
      />
    )}
  </ReactInputMask>
);

export default InputMask;
