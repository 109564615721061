const INITIAL_STATE = {
  cargosnapFiles: [],
};


export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@integrations/CARGOSNAP_FILES':
      return {
        ...state,
        cargosnapFiles: action.payload
      };
    default:
      return state;
  }
}
