import { clearInputPlaceholder } from "./clearInputPlaceholder";

/**
 * Validates if a given value is a valid CPF.
 * @param {*} value The value.
 * Note: It didn't handle char repetition
 */
export const isValidCpf = (value) => (
  // eslint-disable-next-line no-useless-escape
  (/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/).test(clearInputPlaceholder(value) ?? "")
);
