import { lazy } from 'react';

import * as modulesEnum from '../domainmodel/enum/module';
import * as viewsEnum from '../domainmodel/enum/view';
import withRequirePermission from '../components/HOCs/withRequirePermission';

const PermissionDeniedPage = lazy(() => import('../../Login/components/PermissionDeniedPage'));
const DashboardPage = lazy(() => import('../../Dashboard/Dashboard'));
const OperationsPage = lazy(() => import('../../Operations/Operations'));
const AutomationsPage = lazy(() => import('../../Automations/Automations'));
const IntegrationsPage = lazy(() => import('../../Automations/Integrations'));
const ProductRegistersPage = lazy(() => import('../../Registers/ProductRegister'));
const LeadtimeRegisterPage = lazy(() => import('../../Registers/LeadTimeRegister'));
const ProcessTracking = lazy(() => import('../../TrackingPage/TrackingPage'));
const TrackingFleetPage = lazy(() => import('../../TrackingFleet/TrackingFleet'));
const VehiclesPage = lazy(() => import('../../Fleet/Vehicles'));
const DriversPage = lazy(() => import('../../Fleet/Drivers'));
const ReportsPage = lazy(() => import('../../Reports/Reports'));
const TrackingMonitorPage = lazy(() => import('../../TrackingMonitor/TrackingMonitor'));
const GlobalTrackingPage = lazy(() => import('../../GlobalTracking/GlobalTracking'));
const IntegrationsMonitorPage = lazy(() => import('../../IntegrationsMonitor/IntegrationsMonitor'));
const ActorsPage = lazy(() => import('../../Actors/Actors'));
const UsersPage = lazy(() => import('../../Users/Users'));
// const BillingPage = lazy(() => import('../../Billing/Billing'));
const CredentialsPage = lazy(() => import('../../Credentials/Credentials'));
const ConfigPage = lazy(() => import('../../Config/Config'));
const Workflows = lazy(() => import('../../Workflows'));
const AppsPage = lazy(() => import('../../AppsPage'));
const SupplyChainMap = lazy(() => import('../../SupplyChainMap'));

const registers = [
  {
    path: '/registers/leadtimes',
    name: {['en-gb']: 'Lead Times', 'pt-br': 'Lead Times' },
    icon: ['far','clock'],
    fa: true,
    component: withRequirePermission(LeadtimeRegisterPage, viewsEnum.ProductRegisters),
    permission: viewsEnum.ProductRegisters,
    key: viewsEnum.LeadtimeRegisters
  },
  {
    path: '/registers/product',
    name: { ['en-gb']: 'Product Catalogue', 'pt-br': 'Catálogo de Produtos' },
    icon: ['far','box-open'],
    fa: true,
    component: withRequirePermission(ProductRegistersPage, viewsEnum.ProductRegisters),
    permission: viewsEnum.ProductRegisters,
    key: viewsEnum.ProductRegisters
  },
];

const settings = [
    {
      path: '/settings/integrations',
      name: { ['en-gb']: 'Integrations', 'pt-br': 'Integrações' },
      icon: ['far','clock'],
      fa: true,
      component: withRequirePermission(ConfigPage, viewsEnum.Integrations),
      permission: viewsEnum.Integrations,
      key: viewsEnum.Integrations
    },
  ];

const roadCarrier = [
  {
    path: '/roadcarrier/trackingfleet',
    name: { ['en-gb']: 'Fleet Tracking', 'pt-br': 'Rastreamento Frota' },
    icon: ['far','map-marked'],
    fa: true,
    component: withRequirePermission(TrackingFleetPage, viewsEnum.RoadCarrier),
    permission: viewsEnum.RoadCarrier,
    key: viewsEnum.RoadCarrier
  },
  {
    path: '/roadcarrier/vehicles',
    name: { ['en-gb']: 'Vehicles', 'pt-br': 'Veículos' },
    icon: ['far','truck-container'],
    fa: true,
    component: withRequirePermission(VehiclesPage, viewsEnum.Vehicles),
    permission: viewsEnum.Vehicles,
    key: viewsEnum.Vehicles
  },
  {
    path: '/roadcarrier/drivers',
    name: { ['en-gb']: 'Drivers', 'pt-br': 'Motoristas' },
    icon: ['far','steering-wheel'],
    fa: true,
    component: withRequirePermission(DriversPage, viewsEnum.Drivers),
    permission: viewsEnum.Drivers,
    key: viewsEnum.Drivers
  }
];

const modules = [
  // { 
  //   sidebar: false, 
  //   path: '/signin', 
  //   component: requireNotAuth(LoginPage), 
  //   key: 1 
  // },
  {
    sidebar: false,
    path: '/permissiondenied',
    component: PermissionDeniedPage,
    key: 1
  },
  {
    sidebar: true,
    collapse: false,
    path: '/dashboard',
    name: { ['en-gb']: 'Dashboard', 'pt-br': 'Dashboard' },
    icon: 'analytics',
    fa: true,
    component: withRequirePermission(DashboardPage, viewsEnum.Dashboard),
    permission: modulesEnum.Dashboard,
    key: modulesEnum.Dashboard
  },
  {
    sidebar: true,
    collapse: false,
    path: '/',
    name: { ['en-gb']: 'Operations', 'pt-br': 'Operações' },
    icon: 'route',
    fa: true,
    component: withRequirePermission(OperationsPage, viewsEnum.Operations),
    permission: modulesEnum.Operations,
    key: modulesEnum.Operations
  },
  {
    sidebar: true,
    collapse: false,
    path: '/reports',
    name: { ['en-gb']: 'Reports', 'pt-br': 'Relatórios' },
    icon: 'file-spreadsheet',
    fa: true,
    component: withRequirePermission(ReportsPage, viewsEnum.Reports),
    permission: modulesEnum.Reports,
    key: modulesEnum.Reports
  },
  {
    sidebar: true,
    collapse: false,
    path: '/automations',
    name: { ['en-gb']: 'Automations', 'pt-br': 'Automações' },
    icon: 'robot',
    fa: true,
    component: withRequirePermission(AutomationsPage, viewsEnum.Automations),
    permission: modulesEnum.Automations,
    key: modulesEnum.Automations
  },
  {
    sidebar: true,
    collapse: false,
    path: '/integrations',
    name: { ['en-gb']: 'Integrations', 'pt-br': 'Integrações' },
    icon: ['far', 'link'],
    fa: true,
    component: withRequirePermission(IntegrationsPage, viewsEnum.Integrations),
    permission: modulesEnum.Integrations,
    key: modulesEnum.Integrations
  },

  {
    sidebar: true,
    collapse: false,
    path: '/actors',
    name: { ['en-gb']: 'Actors', 'pt-br': 'Intervenientes' },
    icon: ['far','building'],
    fa: true,
    component: withRequirePermission(ActorsPage, viewsEnum.Actors),
    permission: modulesEnum.Actors,
    key: modulesEnum.Actors
  },
  {
    sidebar: true,
    collapse: false,
    path: '/trackingmonitor',
    name: { ['en-gb']: 'Tracking', 'pt-br': 'Tracking' },
    icon: 'ship',
    fa: true,
    component: withRequirePermission(TrackingMonitorPage, viewsEnum.TrackingMonitor),
    permission: modulesEnum.TrackingMonitor,
    key: modulesEnum.TrackingMonitor
  },
  {
    sidebar: true,
    collapse: false,
    path: '/integrationmonitor',
    name: { ['en-gb']: 'Integrations', 'pt-br': 'Integrações' },
    icon: 'cogs',
    fa: true,
    component: withRequirePermission(IntegrationsMonitorPage, viewsEnum.IntegrationsMonitor),
    permission: modulesEnum.IntegrationsMonitor,
    key: modulesEnum.IntegrationsMonitor
  },
  {
    sidebar: true,
    collapse: false,
    path: '/workflows',
    name: { ['en-gb']: 'Integrations', 'pt-br': 'Integrações' },
    icon: ['far','project-diagram'],
    fa: true,
    component: withRequirePermission(Workflows, viewsEnum.N8N),
    permission: modulesEnum.N8N,
    key: modulesEnum.N8N
  },
  {
    sidebar: true,
    collapse: false,
    path: '/apps',
    name: { ['en-gb']: 'Apps', 'pt-br': 'Apps' },
    icon: 'code',
    fa: true,
    component: withRequirePermission(AppsPage, viewsEnum.Apps),
    permission: modulesEnum.Apps,
    key: modulesEnum.Apps
  },
  {
    sidebar: true,
    collapse: false,
    path: '/supplychain',
    name: { ['en-gb']: 'Supply Chain', 'pt-br': 'Supply Chain' },
    icon: ['far','diagram-sankey'],
    fa: false,
    svg: true,
    component: withRequirePermission(SupplyChainMap, viewsEnum.ValueChain),
    permission: modulesEnum.ValueChain,
    key: modulesEnum.ValueChain
  },
  {
    sidebar: true,
    collapse: false,
    path: '/globaltracking',
    name: { ['en-gb']: 'Tracking', 'pt-br': 'Tracking' },
    icon: 'globe',
    fa: true,
    component: withRequirePermission(GlobalTrackingPage, viewsEnum.GlobalTracking),
    permission: modulesEnum.GlobalTracking,
    key: modulesEnum.GlobalTracking
  },
  {
    sidebar: true,
    collapse: true,
    path: '-roadcarrier',
    name: { ['en-gb']: 'Road Carrier', 'pt-br': 'Transportadora' },
    state: 'openRoadCarrier',
    icon: 'truck',
    fa: true,
    views: roadCarrier,
    permission: modulesEnum.RoadCarrier,
    key: modulesEnum.RoadCarrier
  }, 
  {
    sidebar: true,
    collapse: true,
    path: '-registers',
    name: { ['en-gb']: 'Registers', 'pt-br': 'Cadastros' },
    state: 'openRegisters',
    icon: 'cabinet-filing',
    fa: true,
    views: registers,
    permission: modulesEnum.Registers,
    key: modulesEnum.Registers
  },
  {
    sidebar: false,
    collapse: false,
    path: '/processtracking/:token/:id/:language?',
    name: { ['en-gb']: 'Tracking Process', 'pt-br': 'Tracking Processo' },
    icon: ['far', 'link'],
    fa: true,
    component: ProcessTracking,
    permission: true,
    key: modulesEnum.ProcessTracking
  },
  {
    sidebar: true,
    collapse: false,
    path: '/users',
    name: { ['en-gb']: 'Users', 'pt-br': 'Usuários' },
    icon: ['far','users'],
    fa: true,
    component: withRequirePermission(UsersPage, viewsEnum.Users),
    permission: modulesEnum.Users,
    key: modulesEnum.Users
  },
  // {
  //   sidebar: true,
  //   collapse: false,
  //   path: '/billing',
  //   name: { ['en-gb']: 'Billing', 'pt-br': 'Billing' },
  //   icon: ['far','users'],
  //   fa: true,
  //   component: withRequirePermission(BillingPage, viewsEnum.Billing),
  //   permission: modulesEnum.Billing,
  //   key: modulesEnum.Billing
  // },
  {
    sidebar: true,
    collapse: false,
    path: '/credentials',
    name: { ['en-gb']: 'Credentials', 'pt-br': 'Credenciais' },
    icon: ['far', 'key'],
    fa: true,
    component: withRequirePermission(CredentialsPage, viewsEnum.Credentials),
    permission: modulesEnum.Credentials,
    key: modulesEnum.Credentials
  },
  {
    sidebar: true,
    collapse: true,
    path: '-settings',
    name: { ['en-gb']: 'Settings', 'pt-br': 'Configurações' },
    state: 'openSettings',
    icon: 'cogs',
    fa: true,
    views: settings,
    permission: modulesEnum.Settings,
    key: modulesEnum.Settings
  },
  {
    sidebar: true,
    collapse: false,
    path: '/config',
    name: { ['en-gb']: 'Settings', 'pt-br': 'Configurações' },
    icon:['far', 'cog'],
    fa: true,
    component: withRequirePermission(ConfigPage, viewsEnum.Config),
    permission: modulesEnum.Config,
    key: modulesEnum.Config
  },
];

export default modules;
