import Axios from 'axios';
import { getCargoOwners } from '../../Utils/functions';
import Api from '../../App/api';

var _ = require('lodash/core');

export const checkIfIsUnique = (
  userLogged,
  owner,
  ownerParameter,
  uniqueParameter,
  uniqueParameterValue,
  operation,
) => {
  return new Promise((resolve, reject) => {
    let data = {
      owner: owner,
      ownerParameter: ownerParameter,
      uniqueParameter: uniqueParameter,
      uniqueParameterValue: uniqueParameterValue,
      operation: operation
    }

    Api.post(`/processes/checkIfUnique`, data)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};



export const saveNewProcess = (user, process) => {
  return new Promise((resolve, reject) => {
    Api.post(`/processes/create`, process)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getProcesses = user => {
  return new Promise((resolve, reject) => {
    let cargoOwners = [];

    if (user) {
      cargoOwners = getCargoOwners(user);
    }

    let data = cargoOwners;

    let roles =
      user &&
      user.Authorization.Profiles[0].Roles.map(rle => {
        return rle.ClientRoleType;
      });

    let profile = user && user.Authorization?.Profile || user.Authorization?.Profiles[0]?.Name;

    Api.post(`/processes/list/${JSON.stringify(roles)}?USER_PROFILE=${profile}`, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getProcessesCount = (user, operations) => {
  let roles =
    user &&
    user.Authorization.Profiles[0].Roles.map(rle => {
      return rle.ClientRoleType;
    });
  let cargoOwners = user && getCargoOwners(user);
  return new Promise((resolve, reject) => {
    Api.post(`/operations/count/${operations}/${JSON.stringify(roles)}`, cargoOwners)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getProcessByID = (user, id, owner) => {
  return new Promise((resolve, reject) => {
    let data = {
      id: id,
      owner: owner,
    };
    Api.post(`/processes/getById`, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const updateProcess = (user, id, params) => {
  return new Promise((resolve, reject) => {
    let data = {
      id: id,
      data: params,
    };

    Api.post(`/processes/update`, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const updateProcesses = (user, processes) => {
  return new Promise((resolve, reject) => {
    let data = processes;
    Api.post(`/processes/batchupdate`, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getProcessTracking = (id, token) => {
  return new Promise((resolve, reject) => {
    Axios.get(`${window.appConfig.endpoints.bff_node}/processes/getProcessTracking/${token}/${id}`, {
      headers: { Authorization: 'dG9rZW5mbG93bHNiYWNrZW5k' },
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const deleteProcess = (user, process) => {
  return new Promise((resolve, reject) => {
    let data = {
      id: process._id
    }
    Api.post(`/processes/delete`, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const forceSendOrderEmail = (user, order_id) => {
  return new Promise((resolve, reject) => {

    Api.post(`/processes/forceSendOrderEmail/${order_id}`, null)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const saveNewIntegration = (user, integration) => {
  return new Promise((resolve, reject) => {
    Api.post(`/sheetintegrations/create`, integration)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getSpreadsheetIntegrations = user => {
  return new Promise((resolve, reject) => {
    let data = {
      companyID: user.Authorization.ClientID,
    };
    Api.post(`/sheetintegrations/list`, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getLisDis = user => {
  return new Promise((resolve, reject) => {
    //TODO
    resolve([]);
    /*   Axios.post(`${window.appConfig.endpoints.bff_node}/comex/list`, process, {
      headers: {Authorization: user.Token}
    })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });  */
  });
};

export const getAwbByNumber = (user, awb) => {
  return new Promise((resolve, reject) => {
    let data = {
      awb: awb
    }

    Api.post(`/tracking/awb`, data)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getBlByNumber = (user, bl) => {
  return new Promise((resolve, reject) => {
    let data = {
      bl: bl
    }

    Api.post(`/tracking/bl`, data)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const ignoreAutomation = (user, process, automation) => {
  return new Promise((resolve, reject) => {
    //TODO
    let data = {
      processId: process,
      automationId: automation
    }

    Api.post(`/processes/ignoreAutomation`, data)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getLeadTimes = (user, ops, range) => {
  return new Promise((resolve, reject) => {
    Api.get(`/flowlsapi/leadtimes/${ops}/${range.start}/${range.end}`)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};
