import React from 'react';
import { useSelector } from 'react-redux';

import FirstAccessPres from './components/FirstAccess';

export default function Component() {
  const language = useSelector(state => state.layout.language);

  return (
    <div>
      <FirstAccessPres language={language} />
    </div>
  );
}
