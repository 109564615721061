const INITIAL_STATE = {
  vehicleList: null,
  processTripTracing: null
};


export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@trackingfleet/VEHICLES':
      
      return {
        ...state,
        vehicleList: [...action.payload]
      };
    case '@trackingfleet/PROCESS_TRACING':
      return {
        ...state,
        processTripTracing: action.payload
      };

      
    default:
      return state;
  }
}
