import React from 'react';
import { Route, Switch } from 'react-router-dom';

import routes from './routes/routes';
import ProtectedRoute from './routes/ProtectedRoute';
import LoadingScreen from './components/LoadingScreen';
import PrivateLayout from './PrivateLayout';
import useSession from './hooks/useSession';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey('ee77341301b19bb325a9eef8956879efTz01NTQwMCxFPTE3MDE1MjE0NjYwOTIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const renderRoute = ({ path, component: Component, key }) => (
  <Route 
    key={key} 
    path={path}
    exact
    render={(props) => <ProtectedRoute {...props} component={Component} />}
  />
);

const AppRouter = () => {
  useSession();

  return (
    <Switch>
      <React.Suspense fallback={<LoadingScreen />}>
        <PrivateLayout>
          {
            routes.map((moduleRoute) => {
              if (moduleRoute?.collapse) return moduleRoute.views.map((viewRoute) => ( renderRoute({ ...viewRoute }) ));
              return renderRoute({ ...moduleRoute });
            })
          }
        </PrivateLayout>
      </React.Suspense>
    </Switch>
  )
}

export default AppRouter;
