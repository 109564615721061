import * as yup from 'yup';

import { buildDocumentTest } from '../../App/utils/buildDocumentTest';

export const firstAccessSchema = yup.object().shape({
  name: yup
    .string()
    .min(3)
    .max(50)
    .trim()
    .required(),
  country: yup
    .string()
    .min(2)
    .required(),
   officialDocument: yup.string()
    .when('country', {
      is: country => country !== 'BR',
      then: yup.string(),
      otherwise: yup
        .string()
        .test(buildDocumentTest())
        .required(),
    }),
});
