/**
 * A class to set config methods to get env variables
 */
 class Config {
    constructor() {
      this.baseUrl = window.appConfig.endpoints.bff_node;
    }
  
    getURL() {
      return this.baseUrl;
    }
  }
  
  export default new Config();