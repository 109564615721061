import React, { useEffect } from 'react'
import { useSelector, useDispatch  } from 'react-redux';
import * as actions from './containers/actions'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import 'bulma-helpers/css/bulma-helpers.min.css';

var convert = require('xml-js');

 export default function Data(props){

    const {
      formData,
    } = props;



    let dispatch = useDispatch();

    // selectors
    const language = useSelector(state => state.layout.language);
    const userLogged = useSelector(state => state.auth.userLogged);

    useEffect( () => {
      userLogged && userLogged.OficialDocument && dispatch(actions.getSetupData(userLogged))
      userLogged && dispatch(actions.getLabels(userLogged)) ;
      if(userLogged && userLogged.Authorization){
          window.zE('webWidget', 'show')
          window.zE('webWidget', 'prefill', {
            name: {
              value: userLogged.Name,
              readOnly: true // optional
            },
            email: {
              value: userLogged.Email,
              readOnly: true // optional
            }
          });
        }


      return () => {
        //socket.removeAllListeners();
      }
    }, [userLogged]);



    const createNotification = (data) => {
      NotificationManager[data.type](data.body, data.title[language], data.timer, () => {
        if(data.callback){
          alert('callback');
        }else{

        }
      })
    }
    return (
      <div>
{/*         {!isLatestVersion && (
        <p>
          <a
            href="#"
            onClick={e => {
              
              e.preventDefault();
              emptyCacheStorage();
            }}
          >
            Update version
          </a>
        </p>
      )} */}
        <NotificationContainer/>
      </div>)

}

 