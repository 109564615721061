import Axios from 'axios';

class Auth0Api {
  constructor() {
    this._instance = null;
  }

  instance() {
    if (this._instance === undefined || this._instance === null) {
      const { default: store } = require('../store');
      const token = store.getState()?.auth?.currentUser?.accessToken;

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }

      if (token) headers.Authorization = `Bearer ${token}`;
      console.log("url", window.appConfig.endpoints.auth0_users)
      this._instance = Axios.create({
        baseURL: 'https://flowls-prod.us.auth0.com/api/v2/users',
        headers,
      });
    }

    return this._instance;
  }

  axios() { return this.instance();}

  /**
   * @param {String} url
   * @returns {Promise}
   */
  async get(url) {
    return this.axios().get(url);
  }

  /**
   * @param {String} url
   * @param {Object} params
   * @returns {Promise}
   */
   async patch(url, params = {}) {
    return this.axios().patch(url, params);
  }
}

export default new Auth0Api();
