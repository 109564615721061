const useAuth0Configs = () => {
  const auth0Configs = [
    {
      // development - default
      hostname: 'localhost',
      domain: "flowls-prod.us.auth0.com",
      clientId: "FLXJ7njdJtf6Ky3OSnmuPmIwYLdcjrJC",
      audience: "https://flowls-prod.us.auth0.com/api/v2/",
    },
    {
      // beta
      hostname: 'legacy.flowls.dev',
      domain: "flowls-prod.us.auth0.com",
      clientId: "FLXJ7njdJtf6Ky3OSnmuPmIwYLdcjrJC",
      audience: "https://flowls-prod.us.auth0.com/api/v2/",
    },
    {
      // prod
      hostname: 'app.flowls.com',
      domain: "flowls-prod.us.auth0.com",
      clientId: "FLXJ7njdJtf6Ky3OSnmuPmIwYLdcjrJC",
      audience: "https://flowls-prod.us.auth0.com/api/v2/",
    },
  ];

  const scope = "read:current_user update:current_user_metadata";

  const auth0Config = auth0Configs.find(({ hostname }) => hostname === window.location.hostname) || auth0Configs[0];

  return {
    auth0Configs,
    auth0Config,
    scope,
  };
};

export default useAuth0Configs;
