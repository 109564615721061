

import Axios from 'axios';
import Api from '../../App/api';

export const saveNewLeadTime = ((user, newLeadTime, companyID) => {
  newLeadTime.companyID = companyID;
  return new Promise((resolve, reject) => {
    Api.post(`/leadtimes/create`, newLeadTime)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      }); 
  });
});

export const editLeadTime = ((user, newLeadTime, companyID) => {
  newLeadTime.companyID = companyID;
  return new Promise((resolve, reject) => {
    Api.post(`/leadtimes/edit`, newLeadTime)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      }); 
  });
});


export const deleteLeadTime = ((user, leadTime) => {
  return new Promise((resolve, reject) => {
    let data = {
      id: leadTime._id
    }
    
    Api.post(`/leadtimes/delete`, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      }); 
  });
});

export const getLeadTimes = ((user, operation, companyID) => {
  return new Promise((resolve, reject) => {
    let data = {
      companyID: companyID,
      operation: operation
    }

    Api.post(`/leadtimes/list`, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      }); 
  });
});


export const getAllLeadTimes = ((user, companyIDs) => {
  return new Promise((resolve, reject) => {
    let data = {
      companyID: companyIDs
    }
    Api.post(`/leadtimes/list`, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      }); 
  });
});

