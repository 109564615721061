export const Admin = 1;
export const Maintenance = 2;
export const CargoOwner = 3;
export const RoadCarrier = 4;
export const Registers = 5;


export const Dashboard = 6;
export const Finished = 7;
export const Operations = 8;
export const Planning = 9;
export const Reports = 10;
export const Tasks = 11;
export const Tracking = 12;
export const Automations = 13;
export const Integrations = 14;
export const TrackingMonitor = 15;
export const Actors = 16;
export const IntegrationsMonitor = 17;
export const Config = 18;
export const AdminFlowls = 19;
export const Settings = 20;
export const N8N = 21;
export const ValueChain = 22;
export const GlobalTracking = 23;
export const Apps = 24;
export const Users = 25;
export const Billing = 26;
export const Credentials = 27;

export const ProcessTracking = 99;