import Api from '../api';

export const getWorkspaceProfile = (token, profiles) => {
  return new Promise((resolve, reject) => {
    Api.get(`/flowlsapi/getworkspaceprofile/${profiles}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const get = (token, clientType, clientCountry, clientOficialDocument) => {
  return new Promise(async (resolve, reject) => {
    Api.get(`/auth/${clientType}/${clientCountry}/${clientOficialDocument}`)
    .then(async response => {
      let authData = response.data;
      let profiles = response.data.Profiles.map(profile => profile.Name);
      let profilesData = await getWorkspaceProfile(token, profiles)
      
      if(profilesData && profilesData.length){
        authData.Profiles = authData.Profiles.map( profile => {
          const workspaceProfile = profilesData[profilesData.findIndex(profileData => profileData.Profile === profile.Name)]
          if(workspaceProfile){
            profile.Roles = workspaceProfile.ClientRoleTypes.map( roleType => (
              {
                ClientRoleType: roleType,
                ...workspaceProfile
              }
            ))
          }
          return profile
        })
      }
      resolve(authData);
    })
    .catch(error => reject(error));
  });
};

export const saveUserLayout = (user, layout) => {
  return new Promise((resolve, reject) => {
    Api.post(`/HumanResources/User/SaveUserLayout`, layout)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const resetPassword = email => {
  return new Promise((resolve, reject) => {
    Api.put(`/auth/forgot`, { Email: email })
      .then(message => resolve('Senha enviada com sucesso'))
      .catch(error => reject(error));
  });
};

export function setNewPassword(user, oldPassword, newPassword) {
  return new Promise((resolve, reject) => {
    const data = {
      "NewPassword": newPassword,
      "OldPassword": oldPassword
    };

    Api.put('/auth', data)
      .then(response => resolve('Senha atualizada com sucesso'))
      .catch(error => reject(error));
  });
}