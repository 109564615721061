const INITIAL_STATE = {
  operationLeadTimes: [],
  allLeadTimes: null
};


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@leadTime/LEAD_TIMES':
      return {
        ...state,
        operationLeadTimes: action.payload
      };
    case '@leadTime/ALL_LEAD_TIMES':
      return {
        ...state,
        allLeadTimes: action.payload
      };
    default:
      return state;
  }
}
