import * as services from '../services/auth_service';

export const getAuthorization = (token, clientType, clientCountry, clientOficialDocument) => {
  return new Promise((resolve, reject) => {
    services
      .get(token, clientType, clientCountry, clientOficialDocument)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const saveUserLayout = (user, layout) => {
  return new Promise(async (resolve, reject) => {
    services
      .saveUserLayout(user, layout)
      .then(demands => {
        resolve(demands);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const resetPassword = (email) => {
  return new Promise(async (resolve, reject) => {
    services
      .resetPassword(email)
      .then(message => {
        resolve(message);
      })
      .catch(error => {
        reject(error);
      });
  });
};
