import React from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Auth0Provider } from "@auth0/auth0-react";
import moment from 'moment';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';

import useAuth0Configs from "./App/hooks/useAuth0Configs";
import store from './App/store';
import theme from './theme';
import AppRouter from './App/AppRouter';

const App = () => {
  const { auth0Config, scope } = useAuth0Configs();

  return (
    <BrowserRouter>
      <Auth0Provider
        domain={auth0Config.domain}
        clientId={auth0Config.clientId}
        audience={auth0Config.audience}
        scope={scope}
        redirectUri={window.location.origin}
        useRefreshTokens
      >
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
            <Provider store={store}>
              <AppRouter />
            </Provider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </Auth0Provider>
    </BrowserRouter>
  )
}

export default App;