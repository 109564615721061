import { HandleError } from '../utils/ErrorHandler';

import * as reducers from './types';

let errorTimeout;
let sucessTimeout;
let infoTimeout;

export const setError = error => {
  return dispatch => {


    if (error === '') {
      clearTimeout(errorTimeout);
      dispatch({ type: reducers.ERROR, payload: error });
    } else {
      let errorStr = error instanceof Error ? HandleError(error) : error;
      if(error?.response?.data?.error){
        errorStr = error.response.data.error
      }
      console.log(errorStr);
      if (!errorStr.includes('UQ_tb_layout')) {
        dispatch(setErrorWithTimeout(errorStr, 10));
      }
    }
  };
};

export const setSuccess = message => {
  return dispatch => {
    if (message === '') {
      clearTimeout(sucessTimeout);
      dispatch({ type: reducers.SUCCESS, payload: message });
    } else {
      dispatch(setSuccessWithTimeout(message, 5));
    }
  };
};

export const setInfo = (message, timeout) => {
  return dispatch => {
    if (message === '') {
      clearTimeout(infoTimeout);
      dispatch({ type: reducers.INFO, payload: message });
    } else {
      dispatch(setInfoWithTimeout(message, timeout || 10));
    }
  };
};

export const setErrorWithTimeout = (error, timeout) => {
  return dispatch => {
    dispatch({
      type: reducers.ERROR,
      payload: error
    });

    if (timeout !== undefined) {
      errorTimeout = setTimeout(() => {
        dispatch({
          type: reducers.ERROR,
          payload: ''
        });
      }, timeout * 1000);
    }
  };
};

export const setSuccessWithTimeout = (success, timeout) => {
  return dispatch => {
    dispatch({
      type: reducers.SUCCESS,
      payload: success
    });

    if (timeout !== undefined) {
      sucessTimeout = setTimeout(() => {
        dispatch({
          type: reducers.SUCCESS,
          payload: ''
        });
      }, timeout * 1000);
    }
  };
};

export const setInfoWithTimeout = (info, timeout) => {
  return dispatch => {
    dispatch({
      type: reducers.INFO,
      payload: info
    });

    if (timeout !== undefined) {
      infoTimeout = setTimeout(() => {
        dispatch({
          type: reducers.INFO,
          payload: ''
        });
      }, timeout * 1000);
    }
  };
};
