import { applyMiddleware, createStore, compose } from 'redux';
import reduxMiddleware from 'react-block-ui/lib/reduxMiddleware';
import reduxThunk from 'redux-thunk';
import { composeWithDevTools } from "redux-devtools-extension";
import reducers from './reducers';

const middleware = [reduxThunk, reduxMiddleware]

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
