import PerfectScrollbar from 'perfect-scrollbar';
import PropTypes from 'prop-types';
import React from 'react';

let ps;

class SidebarWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.sidebarWrapper = null;

    this.setSidebarWrapperRef = element => {
      this.sidebarWrapper = element;
    };
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }

  render() {
    const { className, user, headerLinks, links, tools } = this.props;
    return (
      <div className={className} ref={this.setSidebarWrapperRef}>
        {user}
        {headerLinks}
        {links}
        {tools}
      </div>
    );
  }
}

SidebarWrapper.propTypes = {
  className: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  headerLinks: PropTypes.instanceOf(Object).isRequired,
  links: PropTypes.instanceOf(Object).isRequired
};

export default SidebarWrapper;
