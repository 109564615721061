import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import Snack from '@material-ui/core/Snackbar';
import cx from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';

import snackbarContentStyle from '../../assets/jss/material-dashboard-pro-react/components/snackbarContentStyle';

function Snackbar({ ...props }) {
  const { classes, message, color, icon, verticalPlace, horizontalPlace, open, closeNotification } = props;
  const messageClasses = cx({
    [classes.iconMessage]: icon !== undefined
  });
  const iconClasses = cx({
    [classes.icon]: classes.icon,
    [classes.infoIcon]: color === 'info',
    [classes.successIcon]: color === 'success',
    [classes.warningIcon]: color === 'warning',
    [classes.dangerIcon]: color === 'danger',
    [classes.primaryIcon]: color === 'primary',
    [classes.roseIcon]: color === 'rose'
  });
  const anchorOrigin = {
    vertical: verticalPlace,
    horizontal: horizontalPlace
  };
  const action = (
    <IconButton aria-label='Close' color='inherit' onClick={() => closeNotification()}>
      <Close style={{ cursor: 'pointer', width: 12, height: 12 }} />
    </IconButton>
  );
  const messageSnack = (
    <div>
      {icon != null ? <props.icon className={iconClasses} /> : null}
      <span className={messageClasses}>{message}</span>
    </div>
  );
  return (
    <Snack
      classes={{
        anchorOriginTopCenter: classes.top20,
        anchorOriginTopRight: classes.top40,
        anchorOriginTopLeft: classes.top40
      }}
      anchorOrigin={anchorOrigin}
      open={open}
      message={messageSnack}
      action={action}
      ContentProps={{
        classes: {
          root: `${classes.root} ${classes[color]}`,
          message: classes.message
        }
      }}
    />
  );
}

Snackbar.defaultProps = {
  color: 'info',
  icon: null
};

Snackbar.propTypes = {
  // styles
  classes: PropTypes.instanceOf(Object).isRequired,
  message: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['info', 'success', 'warning', 'danger', 'primary', 'rose']),
  closeNotification: PropTypes.func.isRequired,
  verticalPlace: PropTypes.oneOf(['bottom', 'top']).isRequired,
  horizontalPlace: PropTypes.oneOf(['left', 'center', 'right']).isRequired,
  open: PropTypes.bool.isRequired,
};

export default withStyles(snackbarContentStyle)(Snackbar);
