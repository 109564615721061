import { createTheme } from '@material-ui/core/styles';
import { secondaryColor, thirdColor } from './App/assets/default-css';
import { dangerColor, infoColor, primaryColor, successColor, warningColor } from './App/assets/jss/material-dashboard-pro-react';

export default createTheme({
    typography: {
      useNextVariants: true
    },
    palette: {
      primary: { main: primaryColor, dark: secondaryColor },
      danger: { main: dangerColor },
      warning: { main: warningColor },
      info: { main: infoColor },
      success: { main: successColor },
      background: { default: "#fff" }
    },
    overrides: {
      palette: {
        primary: { main: primaryColor },
        danger: { main: dangerColor },
        warning: { main: warningColor },
        info: { main: infoColor },
        success: { main: successColor }
      },
      MuiFormHelperText: {
        contained: {
          margin: 0,
          height: 0,
          minHeight: 0
        }
      },
      MuiDialogTitle: {
        root: {
          flex: '0 0 auto',
          margin: '0',
          padding: '16px 24px 8px'
        }
      },
      MuiDialogContent: {
        root: {
          flex: '1 1 auto',
          padding: '0px 16px 0px',
          overflowY: 'auto',
          '-webkit-overflow-scrolling': 'touch'
        }
      },
      MuiDialogActions: {
        root: {
          flex: '0 0 auto',
          margin: '8px 16px 16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }
      }
    }
  });
  