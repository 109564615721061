import * as business from './business';
import * as authActions from '../../App/actions/auth_actions';
import * as layoutActions from '../../App/actions/layout_actions';
import * as messageActions from '../../App/actions/message_actions';
import * as operationBusiness from '../../Operations/containers/business';
import * as registersBusiness from '../../Registers/containers/business';
import * as leadTimeBusiness from '../../LeadTime/containers/business';

import * as processBusiness from '../../Process/containers/business';
import * as reportsBusiness from '../../Reports/containers/business';
import * as processActions from '../../Process/containers/actions';
import { insertActorsID } from '../../App/utils/handleUserAccess';

import moment from 'moment';

export function setError(error) {
  return {
    type: 'ERROR',
    payload: error,
  };
}

export function setSuccess(message) {
  return {
    type: 'SUCCESS',
    payload: message,
  };
}

export const block = () => {
  return {
    type: 'BLOCK',
  };
};

export const unblock = () => {
  return {
    type: 'UNBLOCK',
  };
};

//-----------------------------------------------------------------------

export const getLabels = (userLogged) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      dispatch(layoutActions.block());
      await business
        .getLabels(userLogged)
        .then(result => {
          dispatch({
            type: '@data/LABELS',
            payload: result
          });
          dispatch(layoutActions.unblock());
        })
        .catch(error => {
          dispatch(layoutActions.unblock());
          dispatch(messageActions.setError(error));
        });
    }
  };
};

export const getSetupData = userLogged => {
  let operations;
  let operationsPermissions = {};
  let now = moment();
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null && userLogged.Authorization) {
      dispatch(layoutActions.block());
      await operationBusiness
        .getOperations(userLogged)
        .then(ops => {
          operations = ops;
          operationBusiness.getOperationsPermissions(userLogged, operations).then(result => {
            const myCompanyId = userLogged?.Authorization?.OficialDocument;
            operations = operations.map(operation => {
              operation.OriginalPhases = operation.Phases;
              const operationPermissionsPhases = result[operation.Operation]?.PHASES;
              if (operationPermissionsPhases) {
                operation.Phases = operation.Phases.filter(
                  phase =>
                    !operationPermissionsPhases[phase.Phase] ||
                    !operationPermissionsPhases[phase.Phase][myCompanyId] ||
                    operationPermissionsPhases[phase.Phase][myCompanyId] !== 'BLOCKED',
                );
                const operationPermissionsFormGroup = result[operation.Operation]?.GROUPS;
                operation.Phases = operation.Phases.map(phase => {
                  phase.Forms.update = phase.Forms.update.map(row => {
                    return row.filter(
                      field =>
                        !operationPermissionsFormGroup ||
                        (operationPermissionsFormGroup &&
                          (!operationPermissionsFormGroup[field.Parameter] ||
                            !operationPermissionsFormGroup[field.Parameter][myCompanyId] ||
                            operationPermissionsFormGroup[field.Parameter][myCompanyId] !== 'BLOCKED')),
                    );
                  });
                  return phase;
                });
              }
              return operation;
            });
            dispatch({
              type: '@operations/OPERATIONS',
              payload: operations,
            });
            operationsPermissions = {
              ...operationsPermissions,
              ...result,
            };
            dispatch({
              type: '@operations/OPERATIONS_PERMISSIONS',
              payload: result,
            });
          });
        })
        .catch(error => {
          dispatch(messageActions.setError(error));
        });
      reportsBusiness
        .getProfileReports(userLogged)
        .then(reports => {
          dispatch({
            type: '@operations/PROFILE_REPORTS',
            payload: reports,
          });
        })
        .catch(error => {
          dispatch(messageActions.setError(error));
        });
      registersBusiness
        .getProducts(userLogged, false)
        .then(products => {
          dispatch({
            type: '@products/PRODUCT_LIST',
            payload: products,
          });
        })
        .catch(error => {
          dispatch(messageActions.setError(error));
        });
      leadTimeBusiness
        .getAllLeadTimes(userLogged, userLogged.Authorization.ClientID)
        .then(leadtimes => {
          dispatch({
            type: '@leadTime/ALL_LEAD_TIMES',
            payload: leadtimes,
          });
        })
        .catch(error => {
          dispatch(messageActions.setError(error));
        });
      operationBusiness
        .getParameters(userLogged)
        .then(parameters => {
          operations.forEach(operation => {
            if (operationsPermissions && operationsPermissions[operation.Operation]?.DOCUMENTS) {
              const myCompanyId = userLogged?.Authorization?.OficialDocument;
              Object.entries(parameters).forEach(param => {
                if (
                  operationsPermissions[operation.Operation]?.DOCUMENTS &&
                  operationsPermissions[operation.Operation]?.DOCUMENTS[param[0]] &&
                  operationsPermissions[operation.Operation]?.DOCUMENTS[param[0]][myCompanyId] === 'BLOCKED'
                ) {
                  delete parameters[param[0]];
                }
              });
            }
          });

          dispatch({
            type: '@operations/PARAMETERS',
            payload: parameters,
          });
          if (window.location.pathname === '/reports' && userLogged?.Authorization?.CurrentRole?.OnlyServerControl) {
            dispatch(layoutActions.unblock());
          }
          if (operations?.length) {
            processBusiness
              .getProcessesCount(
                userLogged,
                operations.map(op => op.Operation),
              )
              .then(result => {
                dispatch({
                  type: '@data/PROCESSES_COUNT',
                  payload: result,
                });
                dispatch(layoutActions.unblock());
              })
              .catch(error => {
                dispatch(messageActions.setError(error));
                dispatch(layoutActions.unblock());
              });

            processBusiness
              .getProcesses(userLogged, parameters)
              .then(result => {
                result = processActions.putStatusInProcesses(userLogged, result, operations);
                dispatch({
                  type: '@data/PROCESSES',
                  payload: result,
                });
                dispatch({
                  type: '@data/GOT_FIRST_PROCESSES_LIST',
                  payload: true,
                });
                // dispatch(layoutActions.unblock());
              })
              .catch(error => {
                dispatch(messageActions.setError(error));
                dispatch(layoutActions.unblock());
              });
          } else {
            dispatch(layoutActions.unblock());
          }
        })
        .catch(error => {
          dispatch(layoutActions.unblock());
          dispatch(messageActions.setError(error));
        });
    }
  };
};

export const refreshProfile = userLogged => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      await business
        .refreshProfile(userLogged)
        .then(profiles => {
          const newProfile = profiles[0];
          let newUserLogged = {
            ...userLogged,
            Authorization: {
              ...userLogged.Authorization,
              CurrentRole: {
                ...userLogged.Authorization.CurrentRole,
                ...newProfile
              },
              Profiles: userLogged.Authorization.Profiles.map(prof => {
                if(newProfile.Profile === prof.Name){
                  return {
                    ...prof,
                    Roles: prof.Roles.map(role => {
                      if(role.Profile === newProfile.Profile){
                        return {
                          ...role,
                          ...newProfile
                        }
                      }else{
                        return role
                      }
                    })
                  }
                }else{
                  return prof
                }
              })
            }
          }
          newUserLogged = insertActorsID(newUserLogged);
          dispatch(authActions.setUserLogged(newUserLogged));
        })
        .catch(error => {
          dispatch(messageActions.setError(error));
        });
    }
  };
};
