import { isValidCnpj } from "./isValidCnpj";
import { isValidCpf } from "./isValidCpf";

/**
 * Applies document (cpf or cnpj) validation in one schema field.
 */
export const buildDocumentTest = (isRequired = true) => ({
  name: "isValidDocumentTest",
  exclusive: false,
  test(value) {
    if (!value && !isRequired) {
      return true;
    }

    return isValidCnpj(value) || isValidCpf(value);
  },
  message: "Inválido",
});
