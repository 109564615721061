
import * as services from './services';
import {getCargoOwners} from '../../Utils/functions';


export function saveNewLeadTime(user, newLeadTime, companyID) {
  return new Promise(async (resolve, reject) => {
    services
      .saveNewLeadTime(user, newLeadTime, companyID)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function editLeadTime(user, newLeadTime, companyID) {
  return new Promise(async (resolve, reject) => {
    services
      .editLeadTime(user, newLeadTime, companyID)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}


export function getLeadTimes(user, operation, companyID) {
  return new Promise(async (resolve, reject) => {
    services
      .getLeadTimes(user, operation, companyID)
      .then(result => {
        
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}


export function getAllLeadTimes(userLogged) {
  let cargoOwners = [];
  if(userLogged){
    cargoOwners = getCargoOwners(userLogged)
  }

  return new Promise(async (resolve, reject) => {
    services
      .getAllLeadTimes(userLogged, cargoOwners)
      .then(result => {

        let leadTimes = {};

        result.forEach(lt => {
          if(!leadTimes[lt.operation]){
            leadTimes[lt.operation] = [];
          }
          leadTimes[lt.operation].push(lt)
        })
        
        resolve(leadTimes);
      })
      .catch(error => {

        reject(error);
      });
  });
}

export function deleteLeadTime(user, leadTime) {
  return new Promise(async (resolve, reject) => {
    services
      .deleteLeadTime(user, leadTime)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}

