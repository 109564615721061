export function HandleError(error) {
  if (error.response != null) {
    if (error.response.status == 401) {
      return 'Usuário não autorizado!';
    } else if (error.response.data != null) {
      if (error.response.data === 'Unauthorized') {
        return 'Usuário não autorizado!';
      } else if (error.response.data === 'jwt: Unauthorized') {
        return 'Usuário não autorizado!';
      } else if (error.response.data.message != null) {
        return error.response.data.message;
      } else if (typeof error.response.data === 'string') {
        return error.response.data;
      }
      return `${error.response.status}:${error.response.data}`;
    }
  } else if (error.request) {
    return 'Servidor com problemas. Tente novamente em instantes!';
  } else if (error.message) {
    if (error.message === 'Unauthorized') {
      return 'Usuário não autorizado!';
    }
    return error.message;
  }
  return 'Erro desconhecido!';
}
