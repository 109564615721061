const INITIAL_STATE = {
  vehicleList: []
};


export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@vehicles/VEHICLES_LIST':
      return {
        ...state,
        vehicleList: action.payload
      };   
    default:
      return state;
  }
}
