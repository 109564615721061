import * as business from '../business/auth_business';
import * as reducers from './types';
import * as layoutActions from './layout_actions';
import * as messageActions from './message_actions';

export const insertActorsID = (user) => {

  let result = user;
  let roles = [];
  result.Authorization.ClientID = `{"ClientType":${user.Authorization.ClientType}, "Country":"${user.Authorization.Country}", "OficialDocument":"${user.Authorization.OficialDocument}"}`
  result.Authorization.Profiles = result.Authorization.Profiles.map(profile => {
    profile.Roles = profile.Roles.map(role => {
      role.Partners = role.Partners.map(partner => {
        return {
          ...partner,
          PartnerID: `{"ClientType":${partner.ClientType}, "Country":"${partner.Country}", "OficialDocument":"${partner.OficialDocument}"}`
          //PartnerID: "{ ClientType: " + partner.ClientType + ", Country: '" + partner.Country + "', OficialDocument: '" + partner.OficialDocument + "' }"
        }
      });
      if(role.Subsidiaries?.length){
        role.Subsidiaries = role.Subsidiaries.map(subsidiary => {
          return {
            ...subsidiary,
            PartnerID: `{"ClientType":${subsidiary.ClientType}, "Country":"${subsidiary.Country}", "OficialDocument":"${subsidiary.OficialDocument}"}`
            //PartnerID: "{ ClientType: " + partner.ClientType + ", Country: '" + partner.Country + "', OficialDocument: '" + partner.OficialDocument + "' }"
          }
        });
      }

      roles.push(role.ClientRoleType)
      return role
    });
    return profile;
  });
  result.Authorization.CurrentRole = result.Authorization.Profiles[0].Roles[0];
  return result;
}

export const updateUserInfo = (userToken) => {
  var err;
  var userPerson;
  var userClientAuthorization;
  return async dispatch => {
    if (err != null) {
      dispatch(messageActions.setError(err));
      dispatch(layoutActions.unblock());
      return;
    }
    if (userClients != null && userClients.length > 0) {
      await business
        .getAuthorization(userToken, userClients[0].ClientType, userClients[0].Country, userClients[0].OficialDocument)
        .then(authorization => userClientAuthorization = authorization)
        .catch(error => err = error);
      if (err != null) {
        dispatch(messageActions.setError(err));
        dispatch(layoutActions.unblock());
        return;
      }
    }
    let user = {
      ...userPerson,
      Token: userToken,
      Clients: userClients,
      Authorization: userClientAuthorization,
    };

    user = insertActorsID(user);
    dispatch(setUserLogged(user));
    dispatch(layoutActions.unblock());
  }
}

export const signInUser = (email, password) => {
  return async dispatch => {
    dispatch(layoutActions.block());

    var err;
    var userClientAuthorization;

    if (err != null) {
      dispatch(messageActions.setError(err));
      dispatch(layoutActions.unblock());
      return;
    }

    if (userClients != null && userClients.length > 0) {
      await business
        .getAuthorization(userToken, userClients[0].ClientType, userClients[0].Country, userClients[0].OficialDocument)
        .then(authorization => userClientAuthorization = authorization)
        .catch(error => err = error);
      if (err != null) {
        dispatch(messageActions.setError(err));
        dispatch(layoutActions.unblock());
        return;
      }
    }

    let user = {
      ...userPerson,
      Clients: userClients,
      Authorization: userClientAuthorization,
    };

    user = insertActorsID(user);

    user.Email = email


    dispatch(setUserLogged(user));

    if(!user.Name || user.Name === 'A'){
      dispatch({
        type: '@USER/FIRST_ACCESS_FORM',
        payload: {
          loginEmail: email,
          loginPassword: password,
          cpf: null
        }
      });

    }

    dispatch(layoutActions.unblock());
  };
};

export const changeUserAuthorization = (loggedUser, clientType, country, oficialDocument) => {
  return async dispatch => {
    dispatch(layoutActions.block());

    await business
      .getAuthorization(loggedUser.Token, clientType, country, oficialDocument)
      .then(authorization => {
        let user = {
          ...loggedUser,
          Authorization: authorization,
        };

        dispatch(setUserLogged(user));
        dispatch(layoutActions.unblock());
      })
      .catch(error => {
        console.log(error)
        dispatch(messageActions.setError(error));
        dispatch(layoutActions.unblock());
      });
  };
};

export const CheckVersion = (user) => {
  let version = window.localStorage.getItem('version');

  if (!user || (version && user.WebVersion !== version)) {
    window.localStorage.clear();
    signOutUser();
  } else {
    window.localStorage.setItem('version', user.WebVersion);
  }
  return null
}

export const resetPassword = (email) => {
  return dispatch => {
    dispatch(layoutActions.block());
    business
      .resetPassword(email)
      .then(message => {
        dispatch(messageActions.setSuccess(message));
        dispatch(layoutActions.unblock());
      })
      .catch(error => {
        dispatch(messageActions.setError(error));
        dispatch(layoutActions.unblock());
      });
  };
};

export const setUserLogged = (user) => {
  return async dispatch => {
    window.localStorage.setItem('user', JSON.stringify(user));

    await dispatch({
      type: reducers.USER_LOGGED,
      payload: user
    });
  };
};

export const setResetEmail = (email) => {
  return dispatch => {
    dispatch({
      type: reducers.RESET_PASSWORD_EMAIL,
      payload: email
    });
  };
};
