import PropTypes from 'prop-types';
import React from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';
import { MenuItem, Select } from '@material-ui/core';

import Menu from '@material-ui/icons/Menu';

import HeaderLinks from './HeaderLinks';

import Styles from './Styles';

class Header extends React.Component {

  viewName = () => {
    const { routes, location, language, operationView } = this.props;
    let name;

    routes.forEach(route => {
      if (route.collapse) {
        route.views.map(view => {
          if (view.path === location.pathname && view.name != null) {
            name = view.name[language];
            return;
          }
        });
      } else if (route.path === location.pathname && route.name != null) {
        name = route.name[language];
        if (route.permission === 8) {
          const labelMap = {
            "kanban": {
              'pt-br': 'Kanban',
              'en-gb': 'Kanban',
            },
            "table": {
              'pt-br': 'Tabela',
              'en-gb': 'Table',
            },
            "leadTime": {
              'pt-br': 'Lead Times',
              'en-gb': 'Lead Times',
            },
            "calendar": {
              'pt-br': 'Calendário',
              'en-gb': 'Calendar',
            },
            "analytics": {
              'pt-br': 'Analytics',
              'en-gb': 'Analytics',
            },
            "dashboards": {
              'pt-br': 'Dashboard',
              'en-gb': 'Dashboard',
            },
          };
          if(labelMap[operationView]){
            name = labelMap[operationView][language]
          }
        }
        return;
      }
    });



    return name;
  };

  renderCompanyLogo = () => {
    const { userLogged } = this.props;

    if (userLogged == null || userLogged.Authorization == null || userLogged.Authorization.Logo == null) {
      return null;
    }

    return (
      <img
        src={`data:application/octet-stream;base64,${userLogged.Authorization.Logo}`}
        style={{ height: '30px', marginLeft: '15px' }}
      />
    );
  };

  renderCompanyDropdown = () => {
    const { userLogged, handleChangeClient } = this.props;

    if (userLogged == null || userLogged.Authorization == null || userLogged.Clients == null) {
      return null;
    }
    const actualClientType = userLogged.Authorization.ClientType;
    const actualCountry = userLogged.Authorization.Country;
    const actualOficialDocument = userLogged.Authorization.OficialDocument;
    const actualValue =
      '{ ClientType: ' +
      actualClientType +
      ", Country: '" +
      actualCountry +
      "', OficialDocument: '" +
      actualOficialDocument +
      "' }";

    return (
      <Select
        autoWidth
        value={actualValue}
        style={{ marginLeft: '15px' }}
        onChange={e => handleChangeClient(e.target.value)}
      >
        {userLogged.Clients.map((client, i) => {
          const clientType = client.ClientType;
          const country = client.Country;
          const oficialDocument = client.OficialDocument;
          const value =
            '{ ClientType: ' +
            clientType +
            ", Country: '" +
            country +
            "', OficialDocument: '" +
            oficialDocument +
            "' }";

          return (
            <MenuItem key={i} value={value}>
              {client.SocialName}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  renderViewName = () => {
    const { classes } = this.props;

    return (
      <Button disabled className={classes.title}>
        <div>{this.viewName()}</div>
      </Button>
    );
  };

  render() {
    const { classes, setLanguage, language, toggleSidebarDrawerOpen, headerExtra, headerTopExtra, location, operationView } = this.props;

    if ((['/tracking', '/roadcarrier/trackingfleet', '/globaltracking']).includes(location.pathname)) {
      return null;
    }

    const hideToolbar = [].includes(operationView);

    return (
      <div>
        <AppBar className={classes.appBar} >
          <Toolbar className={classes.commonContainer} >
            <Hidden mdUp>
              <Button
                className={classes.appResponsive}
                color="inherit"
                aria-label="open drawer"
                onClick={() => toggleSidebarDrawerOpen()}
              >
                <Menu />
              </Button>
            </Hidden>
            <div className={classes.toolbar}>
              {this.renderCompanyLogo()}
              {this.renderCompanyDropdown()}
              {headerTopExtra}
            </div>
            <Hidden smDown implementation="css">
              <HeaderLinks setLanguage={setLanguage} language={language} />
            </Hidden>
          </Toolbar>
          {!hideToolbar ? (
            <Toolbar className={classes.container}>
              {this.renderViewName()}
              {headerExtra}
            </Toolbar>
          ) : null}
        </AppBar>
      </div>
    );
  }
}

Header.propTypes = {
  // redux
  userLogged: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  routes: PropTypes.instanceOf(Array).isRequired,
  language: PropTypes.string.isRequired,

  // actions
  setLanguage: PropTypes.func.isRequired,
  toggleSidebarDrawerOpen: PropTypes.func.isRequired,
  handleChangeClient: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    headerExtra: state.header.headerExtra,
    headerTopExtra: state.header.headerTopExtra,
    operationView: state.operations.operationView
  };
}

export default connect(mapStateToProps, null)(withStyles(Styles)(Header));
