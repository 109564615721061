import * as reducers from '../actions/types';

const INITIAL_STATE = {
  currentUser: null,
  userLogged: {
    Token: null,
    Clients: null,
    Authorization: null,
  },
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case reducers.USER_LOGGED:
      return {
        ...state,
        userLogged: {
          ...state.userLogged,
          ...action.payload
        },
      };
    case 'SET_TOKEN':
      return {
        ...state,
        userLogged: {
          ...state.userLogged,
          Token: action.payload,
        },
      };
    case 'SET_CURRENT_USER':
      return {
        ...state,
        currentUser: { 
          ...state.currentUser, 
          ...action.payload 
        },
      };
    case 'SET_CLIENTS':
        return {
          ...state,
          userLogged: {
            ...state.userLogged,
            Clients: action.payload,
          },
        };
    default:
      return state;
  }
}
