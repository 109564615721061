// ##############################
// // // App styles
// #############################

import { containerFluid, drawerMiniWidth, drawerWidth, transition } from '../../material-dashboard-pro-react';

const appStyle = theme => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh',
    '&:after': {
      display: 'table',
      clear: 'both',
      content: '" "'
    }
  },
  mainPanel: {
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    // overflow: "auto",
    position: 'relative',
    float: 'right',
    ...transition,
    height: '100%',
    width: '100%',
    overflowScrolling: 'touch'
  },
  mainPanelOff: {
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    height: '100%',
    overflowScrolling: 'touch'
  },
  content: {
    marginTop: window.location.pathname !== '/trip/map' ? (window.innerWidth >= 3700 ? '140px' : '90px') : window.innerWidth >= 3700 ? '140px' : '90px',
    padding: '0px 15px',
    // position: 'relative',
    // top:'80px',
    // bottom: '0px',
    //height: '100vh',
    // maxHeight: 5 * window.innerHeight,
    overflowY: 'auto'
  },
  contentOff: {
    // padding: '30px 15px'
    // minHeight: window.innerHeight - 110,
  },
  container: { ...containerFluid },
  map: {
    marginTop: '70px'
  },
  mainPanelSidebarMini: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerMiniWidth}px)`
    }
  },
  mainPanelWithPerfectScrollbar: {
    overflow: 'hidden !important'
  }
});

export default appStyle;
