export const LANGUAGE = 'LANGUAGE';
export const MINE_ACTIVE = 'MINE_ACTIVE';
export const MOBILE_OPEN = 'MOBILE_OPEN';
export const WINDOWWIDTH = 'WINDOWWIDTH';
export const WINDOWHEIGHT = 'WINDOWHEIGHT';

export const ERROR = 'ERROR';
export const INFO = 'INFO';
export const SUCCESS = 'SUCCESS';

export const USER_LOGGED = 'USER_LOGGED';
export const USER_DATA = 'USER_DATA';
export const USER_DELETE = 'USER_DELETE';
export const USER_FILTER = 'USER_FILTER';
export const USER_LIST = 'USER_LIST';
export const USER_SAVE = 'USER_SAVE';
export const USER_FILTERED_LIST = 'USER_FILTERED_LIST';

export const BLOCK = 'BLOCK';
export const UNBLOCK = 'UNBLOCK';
export const BLOCKERS = 'BLOCKERS';
export const BLOCK_MESSAGE = 'BLOCK_MESSAGE';

export const RESET_PASSWORD_EMAIL = 'RESET_PASSWORD_EMAIL';
export const OPERATION_VIEW = 'OPERATION_VIEW';
