import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import authReducer from './auth_reducer';
import notificationReducer from './notification_reducer';
import layoutReducer from './layout_reducer';
import userReducer from './user_reducer';
import briefcaseReducer from '../../Briefcase/containers/reducers';
import operationsReducer from '../../Operations/containers/reducers';
import processReducer from '../../Process/containers/reducers';
// import initialDataReducer from '../../Maintenance/containers/reducers'
import registersReducer from '../../Registers/containers/reducers'
import integrationsReducer from '../../Integrations/containers/reducers'
import logsReducer from '../../ProcessLogs/containers/reducers'
import trackingFleetReducer from '../../TrackingFleet/containers/reducers'
import fleetReducer from '../../Fleet/containers/reducers'
import leadTimeReducer from '../../LeadTime/containers/reducers'
import costReducer from '../../Cost/containers/reducers'
import headerReducer from '../components/Header/reducers'
import firstAccessReducer from '../../FirstAccess/containers/reducers'

const rootReducer = combineReducers({
  form,
  auth: authReducer,
  notification: notificationReducer,
  layout: layoutReducer,
  user: userReducer,
  briefcase: briefcaseReducer,
  operations: operationsReducer,
  process: processReducer,
  integrations: integrationsReducer,
  logs: logsReducer,
  trackingFleet: trackingFleetReducer,
  vehicles: fleetReducer,
  leadTime: leadTimeReducer,
  cost: costReducer,
  registers: registersReducer,
  header: headerReducer,
  firstAccess: firstAccessReducer
});

export default rootReducer;
