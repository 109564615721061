import React from "react";
import { withAuthenticationRequired } from '@auth0/auth0-react';

import LoadingScreen from "../components/LoadingScreen";

const ProtectedRoute = ({ component, ...args }) => {

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => null,
  });

  return <Component />;
};

export default ProtectedRoute;
