const INITIAL_STATE = {
  showCostDialog: false,
  showCostTypeDialog: false,
  costsList: null
};


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@costs/NEW_COST_DIALOG':
      return {
        ...state,
        showCostDialog: action.payload
      };
    case '@costs/NEW_COST_TYPE_DIALOG':
      return {
        ...state,
        showCostTypeDialog: action.payload
      }; 
    case '@costs/COSTS':
      return {
        ...state,
        costsList: action.payload
      };
    default:
      return state;
  }
}
