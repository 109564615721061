const INITIAL_STATE = {
    newDocumentDialog: {state: false},
    newDocument: {},
    files: [],
    storedFiles: [],
    documentTypes: [],
    deleteDialog: {},
    xmlData: {},
    filesByDayAndOperation: null,
    integrationFile: null,
    lastIntegrationDate: null,
    selectedIntegration: null,
    integrationsFilters: null,
    integrationErrors: null
};


export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@briefcase/UPLOAD_DOCUMENT_DIALOG':
      return {
        ...state,
        newDocumentDialog: {
          ...action.payload,
          state: action.payload.state
        }
      };
    case '@briefcase/NEW_DOCUMENT':
      return {
        ...state,
        newDocument: {
          ...action.payload,
          type: action.payload.type
        }
      };
    case '@briefcase/DELETE_DIALOG':
      return {
        ...state,
        deleteDialog: action.payload
      };
    case '@briefcase/FILES':
      return {
        ...state,
        storedFiles: action.payload
      };
    case '@briefcase/DOCUMENTS':
      return {
        ...state,
        documentTypes: action.payload
      };
    case '@briefcase/XML_DATA':
      return {
        ...state,
        xmlData: action.payload
      };
    case '@briefcase/INTEGRATIONS':
      return {
        ...state,
        filesByDayAndOperation: action.payload
      };
    case '@briefcase/INTEGRATIONS_ERRORS':
      return {
        ...state,
        integrationErrors: action.payload
      };
    case '@briefcase/SELECTED_INTEGRATION':
      return {
        ...state,
        selectedIntegration: action.payload
      };
    case '@briefcase/INTEGRATION_FILTER':
      return {
        ...state,
        integrationsFilter: action.payload
      };


      
    case '@briefcase/LAST_INTEGRATION_DATE':
      return {
        ...state,
        lastIntegrationDate: action.payload
      };
    case '@briefcase/FILE_JSON':
      return {
        ...state,
        integrationFile: action.payload
      };
    default:
      return state;
  }
}
