
import * as services from './services';
import moment from 'moment';
import _ from 'lodash';

function isDate(value) {
  let check = false
  if (value && value.toString().length === 25 && moment(value).isValid()) {
    check = true
  }
  return check;
}



export function checkIfIsUnique(userLogged, owner, ownerParameter, uniqueParameter, uniqueParameterValue, operation) {
  return new Promise(async (resolve, reject) => {
    services
      .checkIfIsUnique(userLogged, owner, ownerParameter, uniqueParameter, uniqueParameterValue, operation)
      .then(resultCheck => {
        resolve(resultCheck)
      })
      .catch(error => {
        reject(error);
      });
  });
}




export function saveNewProcess(user, process) {
  return new Promise(async (resolve, reject) => {
    services
      .saveNewProcess(user, process)
      .then(resultProcess => {
        resolve(resultProcess)
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function saveNewProcessList(userLogged, listToSave) {
  return new Promise(async (resolve, reject) => {
    let count = 0;
    for(let i = 0; i < listToSave.length; i++){
      let processToSave = listToSave[i];
      services
        .saveNewProcess(userLogged, processToSave)
        .then(resultProcess => {
          count += 1;
          
          if(count === listToSave.length){
            resolve('Processos salvos com sucesso')
          }
        })
        .catch(error => {
          reject(error);
        });
    }
  });
}

export function deleteProcess(user, process) {
  return new Promise(async (resolve, reject) => {
    services
      .deleteProcess(user, process)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export function forceSendOrderEmail(user, order_id) {
  return new Promise(async (resolve, reject) => {
    services
      .forceSendOrderEmail(user, order_id)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
};


export function saveNewIntegration(user, integration, integrationName, cargoOwners) {
  let count = 0;
  return new Promise(async (resolve, reject) => {
  cargoOwners.forEach( owner => {
    let int = {
      fromTo: integration,
      owner: owner,
      name: integrationName
    }
      services
        .saveNewIntegration(user, int)
        .then(result => {
          count += 1;
          
          if(count === cargoOwners.length){
            resolve(result);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  })
}

export function getSpreadsheetIntegrations(user) {
  return new Promise(async (resolve, reject) => {
    services
    .getSpreadsheetIntegrations(user)
    .then(result => {
      resolve(result)
    })
    .catch(error => {
      reject(error);
    });
  });
}






export function getProcesses(user, parameters) {
  return new Promise(async (resolve, reject) => {
    services
      .getProcesses(user)
      .then(result => {
        
          resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}


export function getProcessesCount(user, operations) {
  return new Promise(async (resolve, reject) => {
    services
      .getProcessesCount(user, operations)
      .then(result => {
        
          resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}




export function getProcessByID(user, id, owner) {
  return new Promise(async (resolve, reject) => {
    services
      .getProcessByID(user, id, owner)
      .then(result => {
          resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function getProcessLogs(user, process, p, groupUpdate, mode){
    let logParam = [];
    if (
      p.value !== process[p.parameter] &&
      !p.parameter.includes('uploadedAt_') &&
      !p.parameter.includes('currentPhase') &&
      !p.parameter.includes('processEndedAt')
    ) {
      if (p.value) {
        logParam.push(
          {
            parameter: p.parameter,
            oldValue: process[p.parameter] ? (process[p.parameter].ID ? process[p.parameter].ID : process[p.parameter]) : 'firstRegister',
            newValue: p.value,
            type: isDate(p.value) ? 'date' : 'text'
          }
        )
      }
    }
  let logReturn = {
    user: mode === 'robot' ? 'FLOWLS' : user.Name,
    step: process.currentPhase,
    action: 'dataInsert',
    mode: (mode && mode) || 'manual',
    parameters: logParam,
    date: moment().format()
  }
  return logReturn
}

const isTrigger = (operation, process) => {
  let result = false;
  let currentStepKey = null;
  operation && operation.Phases && operation.Phases.forEach((phase, i) => {
    if (process.currentPhase === phase.Parameter) {
      currentStepKey = i;
    }
    if (currentStepKey >= 0 && checkConditionals(phase.TriggersIn, process)) {
      result = phase.Parameter;
    }
  })
  return result
}

function getParametersToChangePhase(user, process, operation){
  let parametersChanged = [];

  let trigger = isTrigger(operation, {...process});
  let cargoOwnerTrigger = isTrigger(process.mainOperation, {...process});
  
  if (trigger && process.currentPhase !== trigger) {

    parametersChanged.push(
      {
        parameter: 'currentPhase',
        value: trigger,
        type: 'text'
      }
    )
    parametersChanged.push(
      {
        parameter: `phaseIn_${trigger}`,
        value: moment().format(),
        type: 'date'
      }
    )
  } else if (cargoOwnerTrigger && process.currentPhase !== cargoOwnerTrigger) {
    parametersChanged.push(
      {
        parameter: 'currentPhase',
        value: cargoOwnerTrigger,
        type: 'text',
        role: 'cargoOwner'
      }
    )
    parametersChanged.push(
      {
        parameter: `phaseIn_${cargoOwnerTrigger}`,
        value: moment().format(),
        type: 'date',
        role: 'cargoOwner'
      }
    )
  }
  if(parametersChanged && parametersChanged.length > 0){
    return parametersChanged
  }else{
    return false
  }
}

const checkOperationEnd = (user, process, operation) => {
  let operationEndParameters = operation.operationEnd;
  let check = [];
  operationEndParameters && operationEndParameters.forEach(op => {
    if(process[op]){
      check.push(true)
    }else{
      check.push(false)
    }
  })
  if(operationEndParameters && check.filter(c => c).length === operationEndParameters.length){
    return true
  }else{
    return false
  }
}

export const checkConditionals = (tr, process) => {
  let resultCheck = false;
  let triggerCheck = [];
  const triggers = tr.Triggers;
  const behavior = tr.Behavior;
  if(triggers){
    
    triggers.forEach(trigger => {
      
      const triggerParameter = trigger.Parameter;
      const triggerReferenceGroupList = trigger.ReferenceGroupList;
      const triggerValue = trigger.Value;
      let checkTrigger = false;
      const triggerOperation = trigger.Operation; 
      const triggerConditionalsParameters = trigger.Conditionals && trigger.Conditionals.Parameters;
      const triggerConditionalBehavior = trigger.Conditionals && trigger.Conditionals.Behavior;
      let conditionalCheck = []
      triggerConditionalsParameters && triggerConditionalsParameters.forEach( condition => {
        let conditionParameter = condition.Parameter;
        let conditionValue = condition.Value;
        let conditionOperation = condition.Operation;
        if((!conditionOperation || conditionOperation === 'equal') && process[conditionParameter] === conditionValue){
          conditionalCheck.push(true)
        }else if(conditionOperation === 'diff' && process[conditionParameter] !== conditionValue){
          
          conditionalCheck.push(true)
        }else{
          conditionalCheck.push(false)
        }
      })
      
      if(!triggerConditionalsParameters ||
        (triggerConditionalBehavior === 'or' && conditionalCheck.filter( c => c).length > 0) ||
        (triggerConditionalBehavior === 'and' && conditionalCheck.filter( c => c).length === conditionalCheck.length)
      ){
        checkTrigger = true;
      }
      
      if(checkTrigger){
        if(triggerReferenceGroupList){
          if(process[triggerReferenceGroupList]){
            let processGroupList = process[triggerReferenceGroupList]
            let groupListCheck = [];
            processGroupList.forEach( listItem => {
              let processParameter = listItem.subForm && listItem.subForm[triggerParameter];
              if((!triggerOperation || triggerOperation === 'equal') && processParameter && processParameter === triggerValue){
                groupListCheck.push(true)
              }else if(triggerOperation === 'diff' && processParameter && processParameter !== triggerValue){
                groupListCheck.push(true)
              }else{
                groupListCheck.push(false)
              }
            })
            if(groupListCheck.filter(c => c).length === groupListCheck.length){
              
              triggerCheck.push(true)
            }else{
              triggerCheck.push(false)
            }
          }
        }else{
          let processParameter = process[triggerParameter];
          if((!triggerOperation || triggerOperation === 'equal') && processParameter === triggerValue){
            
            triggerCheck.push(true)
          }else if(triggerOperation === 'diff' && processParameter !== triggerValue){
            triggerCheck.push(true)
          }else{
            triggerCheck.push(false)
          }
        }
      }

    })
    
    if(
    ((behavior === 'or' && triggerCheck.filter( c => c).length > 0) ||
    (behavior === 'and' && triggerCheck.length > 0 && triggerCheck.filter( c => c).length === triggerCheck.length))
){
  resultCheck = true;
}
  }
  
  return resultCheck
}

export function updateProcess(user, process, parameters) {
  return new Promise(async (resolve, reject) => {
    services
      .updateProcess(user, process._id, parameters )
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export function updateProcesses(user, processes) {
  return new Promise(async (resolve, reject) => {
    services
      .updateProcesses(user, processes)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
};


export function getProcessTracking(id, token) {
  return new Promise(async (resolve, reject) => {
    services
      .getProcessTracking(id, token)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
};


export function getLisDis(user) {
  return new Promise(async (resolve, reject) => {
    services
      .getLisDis(user)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export function getAwbByNumber(user, awb) {
  return new Promise(async (resolve, reject) => {
    services
      .getAwbByNumber(user, awb)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export function getBlByNumber(user, bl) {
  return new Promise(async (resolve, reject) => {
    services
      .getBlByNumber(user, bl)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export function ignoreAutomation(user, process, automation) {
  return new Promise(async (resolve, reject) => {
    services
      .ignoreAutomation(user, process, automation)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export function getLeadTimes(user, ops, dateRange) {
  return new Promise(async (resolve, reject) => {
    services
      .getLeadTimes(user, ops, dateRange)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
};








