
import Axios from 'axios'
import qs from 'qs';

export const getUserData = ((userLogged, cpf) => {
  return new Promise((resolve, reject) => {

    
    Axios.post(`https://gateway.apiserpro.serpro.gov.br/token`, qs.stringify({grant_type: 'client_credentials'}), {
      headers: {"Content-Type": "application/x-www-form-urlencoded", Authorization: 'Basic WU9GR0VDTGNvUkp5UmdiaXk5Z2l3S1E5WFYwYTplRmhmbGE5eURPVUFXdk00ZVVjdVFhdl9Qd3dh'},
    })
      .then(token => {
        
        Axios.get(`https://gateway.apiserpro.serpro.gov.br/consulta-cpf-df/v1/cpf/${cpf}`, {
          headers: {Authorization: `Bearer ${token.data.access_token}`},
        })
          .then(response => {
            resolve(response.data);
          })
      })
      .catch(error => {
        console.log(error)
        reject(error);
      }); 
  });
});

export const getCompanyData = ((cnpj) => {
  return new Promise((resolve, reject) => {
    Axios.post(`https://gateway.apiserpro.serpro.gov.br/token`, qs.stringify({grant_type: 'client_credentials'}), {
      headers: {"Content-Type": "application/x-www-form-urlencoded", Authorization: 'Basic WU9GR0VDTGNvUkp5UmdiaXk5Z2l3S1E5WFYwYTplRmhmbGE5eURPVUFXdk00ZVVjdVFhdl9Qd3dh'},
    })
      .then(token => {
        Axios.get(`https://gateway.apiserpro.serpro.gov.br/consulta-cnpj-df/v2/basica/${cnpj}`, {
          headers: {Authorization: `Bearer ${token.data.access_token}`},
        })
          .then(response => {
            resolve(response.data);
          })
      })
      .catch(error => {
        console.log(error)
        reject(error);
      }); 
  });
});
