import { getCargoOwners } from '../../Utils/functions'
import Api from '../../App/api'

export const getProducts = ((user, isComplete) => {
  return new Promise((resolve, reject) => {
    const userActor = user?.Authorization?.ClientID
    if(userActor){
      const URL = `/products/list/${userActor}/${isComplete}`;
      Api.get(URL)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    }
  });
});

export const updateProduct = ((user, product) => {
  return new Promise((resolve, reject) => {
    const URL = `/products/update`;
    
    Api.put(URL, product)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
});

export const saveProduct = ((user, product) => {
  return new Promise((resolve, reject) => {
    product.cnpj = user.Authorization.ClientID;
    delete product._id;
    
    const URL = `/products/create`;

    Api.post(URL, product)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
});


export const deleteProduct = ((token, product) => {
  return new Promise((resolve, reject) => {
    const URL = `/products/delete/${product}`;

    Api.delete(URL)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
});

export const getByID = ((token) => {
  return new Promise((resolve, reject) => {
    resolve(true)
  });
});

export const getMailer = ((user) => {
  return new Promise((resolve, reject) => {
    const URL = `/mailer/list`;

    Api.get(URL)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
});