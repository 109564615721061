import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Menu, MenuItem, IconButton, Hidden } from '@material-ui/core';
import Flag from 'react-world-flags'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import * as actions from '../../actions/app_actions';
import * as layoutActions from '../../actions/layout_actions';

import { connect } from 'react-redux';

import headerLinksStyle from '../../../App/assets/jss/material-dashboard-pro-react/components/headerLinksStyle';
import ReactFlagsSelect from 'react-flags-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//import css module
import 'react-flags-select/css/react-flags-select.css';

const us_flag = require("../../../App/assets/img/flags/US.png").default;
const br_flag = require("../../../App/assets/img/flags/BR.png").default;
const ch_flag = require("../../../App/assets/img/flags/CN.png").default;
const es_flag = require("../../../App/assets/img/flags/ES.png").default;

class HeaderLinks extends React.Component {
  state = {
    open: false,
    anchorEl: null
  };

  languages = window.appConfig.languages;

  countFilter = () => {
    var count = 0;
    if (this.props.filterModals.length > 0) {
      count++;
    }
    if (this.props.filterPackageTypes.length > 0) {
      count++;
    }
    if (this.props.filterWithdrawPoints.length > 0) {
      count++;
    }
    if (this.props.filterDeliveryPoints.length > 0) {
      count++;
    }
    if (this.props.filterTemperatureRanges.length > 0) {
      count++;
    }
    if (this.props.filterClients.length > 0) {
      count++;
    }
    return count;
  };

  handleClose = () => {
    this.setState({ anchorEl: null })
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  };

  saveLanguage = (lang) => {
    this.props.setLanguage(lang)
    this.setState({ anchorEl: null })
  };

  flagMenu = () => {
    const { anchorEl } = this.state;
    const { language } = this.props;

    let currentSet = this.languages.filter(l => l.lang === language)[0];
    let currentCountry = 'BRA'

    if (currentSet) {
      currentCountry = currentSet.country;
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <img onClick={(event) => this.handleClick(event)} style={{ width: '25px' }} src={
          language === "pt-br" ? br_flag
            : language === "en-gb" ? us_flag
              : language === "ch" ? ch_flag
                : language === "es" ? es_flag
                  : br_flag} alt="" />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {this.languages.map((lang,j) =>
            <MenuItem key={j} onClick={() => this.saveLanguage(lang.lang)}>
              <Flag style={{ width: '30px', boxShadow: "2px 2px 5px #9E9E9E" }} code={lang.country} />
            </MenuItem>
          )}
        </Menu>
      </div>
    )
  }

  setOperationViewType = (view) => {
    const { setOperationView } = this.props;
    setOperationView(view)
  }

  render() {
    const { classes, language } = this.props; 

    return (
      <div>
        <Hidden smDown>  
          <IconButton style={{ height: '24px', width: '24px', padding: '0px', marginLeft: '5px', marginRight: '15px' }}>
            {this.flagMenu()}
          </IconButton>
        </Hidden>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  language: PropTypes.string,
  miniActive: PropTypes.bool,
  setLanguage: PropTypes.func
};

function mapStateToProps(state) {
  return {
  };
}

export default connect(
  mapStateToProps,
  {
    ...actions,
    setOperationView: layoutActions.setOperationView,
  },
)(withStyles(headerLinksStyle)(HeaderLinks));
