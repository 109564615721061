import * as business from './business';

import * as layoutActions from '../../App/actions/layout_actions';
import * as messageActions from '../../App/actions/message_actions';
import * as planningActions from '../../Planning/containers/actions';
import uuid from 'react-uuid'

import moment from 'moment';

export function setError(error) {
  return {
    type: 'ERROR',
    payload: error
  };
};

export function setSuccess(message) {
  return {
    type: 'SUCCESS',
    payload: message
  };
};

export const block = () => {
  return {
    type: 'BLOCK'
  }
};

export const unblock = () => {
  return {
    type: 'UNBLOCK'
  }
};

//-----------------------------------------------------------------------

export function setProcessDialog(state) {
  return {
    type: '@process/PROCESS_DIALOG',
    payload: state
  };
};

export function setBacklogDialog(state) {
  return {
    type: '@process/BACKLOG_DIALOG',
    payload: state
  };
};



export function setNewProcessDialog(state) {
  return {
    type: '@process/NEW_PROCESS_DIALOG',
    payload: state
  };
};

export function selectProcess(data) {
  return {
    type: '@process/PROCESS_SELECTED',
    payload: data
  };
};

export function selectBacklogProcess(data) {
  return {
    type: '@process/BACKLOG_PROCESS_SELECTED',
    payload: data
  };
};

export function setSpreadsheetIntegrationDialog(state) {
  return {
    type: '@process/SPREADSHEET_INTEGRATION_DIALOG',
    payload: state
  };
};



export function setOriginalForm(data) {
  
  return {
    type: '@process/ORIGINAL_FORM',
    payload: data
  };
};

export function setForm(data) {
  
  return {
    type: '@process/FORM',
    payload: data
  };
};

export function setNewOrderForm(data) {
  return {
    type: '@process/NEW_ORDER_FORM',
    payload: data
  };
};



export function setAwbData(data) {
  return {
    type: '@process/AWB',
    payload: data
  };
};

export function setBlData(data) {
  return {
    type: '@process/BL',
    payload: data
  };
};




export function setProcessSelected(data) {
  return {
    type: '@process/PROCESS_SELECTED',
    payload: data
  };
};

export function setHandlersForm(data) {
  return {
    type: '@process/HANDLERS_FORM',
    payload: data
  };
};

export function openProcessDialog(item) {
  return async dispatch => {
    dispatch(layoutActions.block())
    dispatch(setProcessDialog(true))
    dispatch({
      type: '@process/PROCESS_SELECTED',
      payload: item
    });
    //dispatch(layoutActions.unblock())

  }
}




export const getProcessDataAndSelect = (userLogged, id, operations, owner) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      dispatch(layoutActions.block());  
      await business
        .getProcessByID(userLogged, id, owner)
        .then(result => {
          result = putStatusInProcesses(userLogged, [result], operations)[0]
          dispatch({
            type: '@process/PROCESS_SELECTED',
            payload: result
          });
          dispatch(setProcessDialog(true))
          dispatch(layoutActions.unblock());
        })
        .catch(error => {
          dispatch(layoutActions.unblock());
        });
    }
  };
};

export const getLabel = (userLogged, language, row, param, refListFilter, refLists) => {
  let label;
  let checkActor;
  let parameter = param.Parameter;
  let parameterType = param.Type;
  
  

  const checkDate = (date) => {
    let result = moment(date, moment.ISO_8601, true).isValid()
   return result;
  }
  const isActor = (item) => {
    let checkActor;
    let actorCompany;
    //TODO
    if((typeof item === 'string') && item && !checkDate(item) && item.includes('OficialDocument') && item.includes('Country')){
      try{
        checkActor = item && JSON.parse(item);
      }catch(e){
        
      }
      if(checkActor && checkActor.OficialDocument){
        actorCompany = getCompany(userLogged, item)
      }
    }else if(item && item.OficialDocument && item.Country && item.ClientType){
      actorCompany = item;
    }
   
    return actorCompany;
  }
  //TODO
  checkActor = isActor(row[parameter]);
  if(checkActor && checkActor.BusinessName){
    label = checkActor.SocialName;
    //TODO URGENT
  }else if(row[parameter] && parameter !== '_id' && parameter !== 'operationLeadTime'){
    label = row[parameter]
  }else if(param.Source){
    let paramSourceRef = param.SourceRef;
    let paramSourceRefName = param.ReferenceName;
    let paramSourceRefLabel = param.ReferenceLabel;
    let listRef;
    if(param.Source.split('.').length > 1){
      let path = param.Source.split('.')
      let baseList = refLists[path[0]];
      //TODO
      //PREFILTER
      if(refListFilter && refListFilter[path[0]]){
        let filter = refListFilter[path[0]];
        baseList = baseList.filter(item => filter.value.includes(item[filter.parameter]))
      }
      let newListRef = [];
      baseList.forEach( (item, i )=> {
        if(item[path[1]]){
          item[path[1]] && item[path[1]].forEach( subItem => {
            if(path[2] && subItem[path[2]]){
              subItem[path[2]].forEach( subSubItem => {
                newListRef.push(subSubItem)
              })
            }else{
              newListRef.push(subItem)
            }
           
          })
        }
      })
      listRef = newListRef;
    }else{
      listRef = refLists[param.Source];
    }


    

    if(!Array.isArray(listRef)){
      let listRefArray = [];
      Object.values(listRef).forEach(v => {
        v.forEach(s => listRefArray.push(s))
      });
      listRef = listRefArray;
    }
    //TODO
    let rowItem = row[paramSourceRef] || row[`${paramSourceRef}_select`];

    let indexRef = listRef.findIndex(item => item[paramSourceRefName] === rowItem);
    
    if(indexRef && indexRef < 0){
      //TODO
      rowItem = row[paramSourceRef + '_select']
      //TODO
      indexRef = listRef.findIndex(item => item[paramSourceRefName] === (rowItem && rowItem.ActorID ? rowItem.ActorID : rowItem));
      if(indexRef && indexRef < 0){
        rowItem = row[paramSourceRef.replace('_select', '')]
        indexRef = listRef.findIndex(item => item[paramSourceRefName] === (rowItem && rowItem.ActorID ? rowItem.ActorID : rowItem));
      } 
    } 
    
    if(param.SubSource){
      let subRef = listRef[indexRef] && listRef[indexRef][param.SubSource.ParentRef];
      let subList = refLists[param.SubSource.List];
      if(!Array.isArray(subList)){
        let subListRefArray = [];
        Object.values(subList).forEach(v => {
          v.forEach(s => subListRefArray.push(s))
        });
        subList = subListRefArray;

      }
      let subItem = subList && subList[subList.findIndex(i => i[param.SubSource.Param] === subRef)];
      
      if(subItem){

        label = subItem[param.SubSource.Label];
      }


      
    }else if(listRef && listRef[indexRef] && paramSourceRefLabel === 'Label'){
      label =  listRef[indexRef].Label && listRef[indexRef].Label[language];
      
    }else if(listRef[indexRef]){

      const checkIfIsActor = isActor(listRef[indexRef][paramSourceRefLabel]);
      if(checkIfIsActor){
        label = checkIfIsActor.SocialName;
      }else{
        const item = listRef[indexRef][paramSourceRefLabel];
        label =  item;
      }
      if(parameter === 'skuNumber_linked'){
        label = listRef[indexRef].productInternCodesList.map(p => p.productInternCode).join(' | ')
      }
    }
  }
  if(parameterType === 'date'){
    if(label && label !== ""){
      label = moment(label).format(param.Format || 'DD/MM/YYYY')
    }
  }
  return label
}

export function updateProcessesList(user, data, processesList, parameters, operations, processSelected) {

  return async dispatch => {
  let updatedList = data;
  let order = putStatusInProcesses(user, [data.order], operations)[0]

  if(data.type === 'update'){
    if(processSelected._id === order._id){
      let orderOperation = operations[operations.findIndex(op => op.Operation === order.operation)]
      dispatch(setProcessSelected(order));
      const form = getFormData(order, orderOperation, parameters)
      dispatch(setOriginalForm(form));
    }
    updatedList = processesList.map( p => {
      if(p._id === order._id){
        return order
      }else{
        return p
      }
    }).filter( p => !p.isCanceled)

  }else if(data.type === 'delete'){
    updatedList = processesList.filter( p => p._id !== order._id )
  }else if(data.type === 'insert' && processesList && !processesList.some(o => (o.cargoOwnerReference && o.cargoOwnerReference === order.cargoOwnerReference) || (o.exporter && o.exporter === order.exporter))){
    updatedList = [...processesList, order]
  }
    dispatch({
      type: '@data/PROCESSES',
      payload: updatedList
    }); 
  };

};


const putFieldInForm = (form, parameter, processSelected) => {
  let newForm  = {...form};
  if(processSelected[parameter]){
    newForm = {...newForm, [parameter]: processSelected[parameter].ID ? processSelected[parameter].ID : processSelected[parameter]}
  }
  
  return newForm
}


export function formToFields(form, processSelected, parameters){

let newForm = {}
form && form.map( (formLine,i) => 
formLine.map( (formItem,k) => {
  const paramData = parameters[formItem.Parameter]
  let item = (paramData && paramData.fieldToSave) ? paramData.fieldToSave : formItem.Parameter;
    if(item && item.includes('group_')){
      const groupRows = parameters[item]?.Form;
      if(groupRows){
        groupRows.forEach((groupRow,k) => {
          groupRow.forEach((groupField,k) => {
            let groupItem = groupField.Parameter;
            if(groupItem && groupItem.includes('group_')){
              const subGroupRows = parameters[groupItem].Form;
              subGroupRows.forEach(subGroupRow => {
                subGroupRow.forEach((subGroupField,k) => {
                  let subGroupItem = subGroupField.Parameter;
                  newForm = putFieldInForm(newForm, subGroupItem, processSelected);
                })
              })
            }else{
              newForm = putFieldInForm(newForm, groupItem, processSelected);
            }
          })
        })
      }
    }else{
      newForm = putFieldInForm(newForm, item, processSelected);
    }
  }
))
return newForm;
}

export function getFormData(processSelected, operation, parameters){
  let p = [];
  if(operation){
    p = operation.Phases.map((phase,i) => {
      return(
        {
          ...phase,
          Order: i+1
        }
      )
    })
  }
  let newForm = {}
  p.forEach(panel => {
    if(!newForm.handlers){
      newForm.handlers = {};
    }
    let leadTimeParameter = `leadTime_${panel.Phase}`;
    let handlerParameter = `handler_${panel.Phase}`;
    let leadTimePredictedParameter = `leadTimePredicted_${panel.Phase}`;
    newForm={
      ...newForm, 
      [leadTimeParameter]: processSelected.leadTimePlanned && processSelected.leadTimePlanned.leadTimes[leadTimeParameter], 
      [leadTimePredictedParameter]: processSelected.leadTimePredicted && processSelected.leadTimePredicted.leadTimes[leadTimePredictedParameter],
    }
    if(processSelected.groupedOrders){
      newForm = putFieldInForm(newForm, "groupedOrders", processSelected);
    }
    newForm.handlers[handlerParameter] =  processSelected.handlers && processSelected.handlers[handlerParameter];
    if(panel?.Forms?.update){
      let fields = formToFields(panel.Forms.update, processSelected, parameters)
      newForm = {
        ...newForm,
        ...fields
      };
    }
    if(operation?.ContractForm && processSelected?.isContract){
      let fields = formToFields(operation.ContractForm, processSelected, parameters)
      newForm = {
        ...newForm,
        ...fields
      };
    }
  })
  return newForm
}

export const cleanPods = () => {
  return {
    type: '@process/SELECTED_PROCESS_PODS',
    payload: []
  };
};

export const cleanSelectedProcessFiles = () => {
  return {
    type: '@briefcase/FILES',
    payload: null
  };
};

export const getProcessByID = (userLogged, id, owner) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      dispatch(layoutActions.block());  
      await business
        .getProcessByID(userLogged, id, owner)
        .then(result => {
          dispatch({
            type: '@process/PROCESS_SELECTED',
            payload: result
          });
          dispatch(layoutActions.unblock());
        })
        .catch(error => {
          dispatch(layoutActions.unblock());
        });
    }
  };
};

export const saveNewProcessList = (userLogged, listToSave) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      dispatch(layoutActions.block());
      await business
        .saveNewProcessList(userLogged, listToSave)
        .then(result => {
          dispatch(layoutActions.unblock());
          dispatch(messageActions.setSuccess(result));
        })
        .catch(error => {
          dispatch(layoutActions.unblock());
          dispatch(messageActions.setError(error));
        });
    }
  };
}

export const forceSendOrderEmail = (userLogged, order_id) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      dispatch(layoutActions.block());
      await business
        .forceSendOrderEmail(userLogged, order_id)
        .then(result => {
          
          dispatch(layoutActions.unblock());
          dispatch(messageActions.setSuccess(result));
        })
        .catch(error => {
          
          dispatch(layoutActions.unblock());
          dispatch(messageActions.setError(error));
        });
    }
  };
}

export const saveNewProcess = (userLogged, process, parameters, uniqueParameter) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      dispatch(layoutActions.block());
      let ownerParameter = 'cargoOwner';
      if(process.exporter){
        ownerParameter = 'exporter'
      }
      let uniqueParameterValue = process[uniqueParameter]
      if(!uniqueParameterValue){
        process[uniqueParameter] = uuid();
        uniqueParameterValue = process[uniqueParameter]
      }
      let owner = process.cargoOwner ? process.cargoOwner : process.exporter;
      business
      .checkIfIsUnique(userLogged, owner, ownerParameter, uniqueParameter, uniqueParameterValue, process.operation)
      .then(result => {
        business
        .saveNewProcess(userLogged, process)
        .then(result => {
          //dispatch(getProcesses(userLogged, parameters))
          dispatch(layoutActions.unblock());
          dispatch(setNewOrderForm({ID: null}));
          dispatch(setNewProcessDialog(false));
          dispatch(messageActions.setSuccess(result.data && result.data.message ? result.data.message : result.data));
        })
        .catch(error => {
          dispatch(layoutActions.unblock());
          dispatch(messageActions.setError(error?.response?.data?.error || error));
        });
        })
      .catch(error => {
        dispatch(layoutActions.unblock());
        dispatch(messageActions.setError(error));
      });
      
    }
  };
};

export const deleteProcess = (userLogged, process, parameters, operations) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      dispatch(layoutActions.block());
      await business
        .deleteProcess(userLogged, process)
        .then(result => {
          dispatch(getProcesses(userLogged, parameters, operations))
          dispatch(layoutActions.unblock());
          dispatch(messageActions.setSuccess(result));
        })
        .catch(error => {
          dispatch(layoutActions.unblock());
          dispatch(messageActions.setError(error));
        });
    }
  };
};

export const saveNewIntegration = (userLogged, integration, integrationName, cargoOwners, errorMessage, successMessage) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      dispatch(layoutActions.block());
      await business
        .saveNewIntegration(userLogged, integration, integrationName, cargoOwners)
        .then(result => {
          dispatch(layoutActions.unblock());
          dispatch(messageActions.setSuccess(successMessage));
        })
        .catch(error => {
          dispatch(layoutActions.unblock());
          dispatch(messageActions.setError(errorMessage));
        });
    }
  };
};

export const getSpreadsheetIntegrations = (userLogged) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      //dispatch(layoutActions.block());
      await business
        .getSpreadsheetIntegrations(userLogged)
        .then(result => {
          
          dispatch({
            type: '@process/SPREADSHEET_INTEGRATIONS',
            payload: result
          });
          dispatch(layoutActions.unblock());
        })
        .catch(error => {
          dispatch(layoutActions.unblock());
          //dispatch(messageActions.setError(error));
        });
    }
  };
};

export const getCompany = (user, ID) => {
  let company = JSON.parse(ID);
  let result;
  user.Authorization.Profiles.forEach(profile => {
    profile.Roles.forEach(role => {
      role.Partners.forEach( partner => {
        if(partner.ClientType === company.ClientType && partner.Country === company.Country && partner.OficialDocument === company.OficialDocument){
          result = partner
        }
      })
    })
  })
  if(!result && company.ClientType === user.Authorization.ClientType && company.Country === user.Authorization.Country && company.OficialDocument === user.Authorization.OficialDocument){
    result = user.Clients.filter(client => client.ClientType === company.ClientType && client.Country === company.Country && client.OficialDocument === company.OficialDocument)[0]
  }
  if(result){
    result.ActorID = ID;
  }
  return result
}

export const putCompaniesInProcesses = (user, processes, parameters, operations) => {

  let result;
  result = processes.map(process => {
    Object.entries(process).map(processParam => {
      let param = parameters && parameters[processParam[0]];
      if(param && param.MenuType && param.MenuType.type === 'company'){
        if(process[processParam[0]]){
          if(!process[processParam[0]].BusinessName){
            process[processParam[0]] = getCompany(user, processParam[1]);
          }else{
            
            process[processParam[0]] = getCompany(user, processParam[1].ActorID);
          }
        }
      }else if(processParam[0] === 'handlers'){
        let handlers = [];
        processParam[1].forEach(handler => {
          if(handler.actor){
            
            handlers.push({
              ...handler,
              actor: handler.actor ? getCompany(user, handler.actor) : null
            })
          }
        })
        process[processParam[0]] = handlers;
      }
    })
    return process
  })
  
  result = putStatusInProcesses(user, result, operations)
  return result
}

export const putStatusInProcesses = (user, processes, operations) => {
  let result = processes ? [...processes] : [];
  result = result.map(process => {
    let operation = operations && operations[operations.findIndex(op => op.Operation === process.operation)]
    process.automations = undefined;

    let automations = operation && operation.Automations && operation.Automations.filter(automation => automation.automationRules && automation.automationRules.active && ["activateYellowLight", "activateRedLight", "activateGreenLight"].includes(automation.automationRules.action))

    let getField = (parameter, process) => {
      let date;
      if(parameter === 'importLicenseDocumentPreRegister' && process.importLicenseDocumentPreList){
        let param = 'importLicenseDocumentPreRegister';
        let paramGroup = 'importLicenseDocumentPreList';
        let lastEvent;
        process[paramGroup].forEach( li => {
          let value = li.subForm && li.subForm[param]
          if(value && (!lastEvent || moment(lastEvent).isAfter(value))){
            lastEvent = value
          }
        })
        date = lastEvent;
      }else if(parameter === 'importLicenseDocumentPreRelease' && process.importLicenseDocumentPreList){
        let param = 'importLicenseDocumentPreRelease';
        let paramGroup = 'importLicenseDocumentPreList';
        let lastEvent;
        process[paramGroup].forEach( li => {
          let value = li.subForm && li.subForm[param]
          if(value && (!lastEvent || moment(lastEvent).isAfter(value))){
            lastEvent = value;
          }
        })
        date = lastEvent;
      }else if(parameter === 'importLicenseDocumentPosRegister' && process.importLicenseDocumentPosList){
        let param = 'importLicenseDocumentPosRegister';
        let paramGroup = 'importLicenseDocumentPosList';
        let lastEvent;
        process[paramGroup].forEach( li => {
          let value = li.subForm && li.subForm[param]
          if(value && (!lastEvent || moment(lastEvent).isAfter(value))){
            lastEvent = value
          }
        })
        date = lastEvent;
      }else if(parameter === 'importLicenseDocumentPosRelease' && process.importLicenseDocumentPosList){
        let param = 'importLicenseDocumentPosRelease';
        let paramGroup = 'importLicenseDocumentPosList';
        let lastEvent;
        process[paramGroup].forEach( li => {
          let value = li.subForm && li.subForm[param]
          if(value && (!lastEvent || moment(lastEvent).isAfter(value))){
            lastEvent = value;
          }
        })
        date = lastEvent;
      }else if(parameter === 'importDocumentRegister' && process.importDocumentList){
        let param = 'importDocumentRegister';
        let paramGroup = 'importDocumentList';
        let lastEvent;
        process[paramGroup].forEach( li => {
          let value = li.subForm && li.subForm[param]
          if(value && (!lastEvent || moment(lastEvent).isAfter(value))){
            lastEvent = value;
          }
        })
        date = lastEvent;
      }else if(parameter === 'importDocumentRelease' && process.importDocumentList){
        let param = 'importDocumentRelease';
        let paramGroup = 'importDocumentList';
        let lastEvent;
        process[paramGroup].forEach( li => {
          let value = li.subForm && li.subForm[param]
          if(value && (!lastEvent || moment(lastEvent).isAfter(value))){
            lastEvent = value;
          }
        })
        date = lastEvent;
      }else{
        date  = process[parameter]
      }
      return date;
    }

    if(automations){
      for(let automation of automations){
        if(automation.automationType === 'whenHasPassedPeriodWithoutConfirmation'){
          let fromDateParameter = automation.automationRules.fromDate;
          let fromDate = getField(fromDateParameter, process);
          let toDateParameter = automation.automationRules.toDate;
          let toDate = getField(toDateParameter, process);
          let action = automation.automationRules.action;
          let now = moment();
          if(fromDate && !toDate){
            let period = parseFloat(automation.automationRules.periodTime) * parseFloat(automation.automationRules.periodUnit);
            let diff = moment(now).diff( moment(fromDate), 'minutes');
            if(diff && diff > period){
              if(!process.automations){
                process.automations = []
              }
              let aut = {
                triggered: true,
                ignore: false,
                automation: automation
              }
              process.automations.push(aut)
            }
          }
        }else if(automation.automationType === 'whenHasPassedPeriodWithoutReachPhase'){
          let fromDateParameter = automation.automationRules.fromDate;
          let fromDate = getField(fromDateParameter, process);
          let toPhase = automation.automationRules.phaseToReach;
          let action = automation.automationRules.action;
          let now = moment();
          let isBeforeReachPhase = false;
          let currentPhaseIndex = 0;
          let phaseToReachIndex = 0;
          operation.Phases.forEach( (phase, i) => {

            if(phase.Phase === toPhase){
              phaseToReachIndex = i
            }
            if(phase.Phase === process.currentPhase.Phase){
              currentPhaseIndex = i
            }
          })

          if(fromDate && (currentPhaseIndex < phaseToReachIndex)){
            let period = parseFloat(automation.automationRules.periodTime) * parseFloat(automation.automationRules.periodUnit);
            let diff = moment(now).diff( moment(fromDate), 'minutes');
            if(diff && diff > period){
              if(!process.automations){
                process.automations = []
              }
              let aut = {
                triggered: true,
                ignore: false,
                automation: automation
              }
              process.automations.push(aut)
            }
          }
          

        }
      }
    }

    if(process.leadTimePlanned){
      process.processStatus = planningActions.getOperationCheckpoints(operations, process);
      if(process.processStatus && process.processStatus.warehouseInboundDatePredicted){
        process.warehouseInboundDateRealized = process.processStatus.warehouseInboundDateRealized;
        process.warehouseInboundDatePredicted = process.processStatus.warehouseInboundDatePredicted;
        process.warehouseInboundDatePlanned = process.processStatus.warehouseInboundDatePlanned;
      };
    }else{
      //TODO
      process.processStatus = {
        status: 'ontime',
        checkpoints: [],
        alerts: planningActions.getAutomationAlerts(process)
      };
    };
    return process;
  })
  return result
}

export const getProcesses = (userLogged, parameters, operations) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      dispatch(layoutActions.block());  
      await business
        .getProcesses(userLogged, parameters)
        .then(result => {
          
          result = putStatusInProcesses(userLogged, result, operations)
          
          dispatch({
            type: '@data/PROCESSES',
            payload: result
          });
          dispatch(layoutActions.unblock());
        })
        .catch(error => {
          dispatch(layoutActions.unblock());
        });
    }
  };
};

export const checkCheckpointConditionals = (checkpoint, process) => {
  let resultCheck = false;
  let triggerCheck = [];
  const triggers = checkpoint.Triggers;
  const behavior = checkpoint.Behavior;
  if(triggers){
    const validTriggers = triggers.map(trigger => {
      const triggerParameter = trigger.Parameter;
      const triggerReferenceGroupList = trigger.ReferenceGroupList;
      const triggerValue = trigger.Value;
      let checkTrigger = false;
      const triggerOperation = trigger.Operation; 
      const triggerConditionalsParameters = trigger.Conditionals && trigger.Conditionals.Parameters;
      const triggerConditionalBehavior = trigger.Conditionals && trigger.Conditionals.Behavior;
      let conditionalCheck = []



      triggerConditionalsParameters && triggerConditionalsParameters.forEach( condition => {
        
        let conditionParameter = condition.Parameter;
        let conditionValue = condition.Value;
        let conditionOperation = condition.Operation;
        
        if((!conditionOperation || conditionOperation === 'equal') && process[conditionParameter] === conditionValue){
          
          conditionalCheck.push(true)
        }else if(conditionOperation === 'diff' && process[conditionParameter] !== conditionValue){
          
          conditionalCheck.push(true)
        }else{
          conditionalCheck.push(false)
        }
      })
      
      if(!triggerConditionalsParameters ||
        (triggerConditionalBehavior === 'or' && conditionalCheck.filter( c => c).length > 0) ||
        (triggerConditionalBehavior === 'and' && conditionalCheck.filter( c => c).length === conditionalCheck.length)
      ){
        checkTrigger = true;
      }
      
      if(checkTrigger){
        if(triggerReferenceGroupList){
          
          if(process[triggerReferenceGroupList]){
            
            let processGroupList = process[triggerReferenceGroupList]
            let groupListCheck = [];
            processGroupList.forEach( listItem => {
              let processParameter = listItem.subForm && listItem.subForm[triggerParameter];
              if((!triggerOperation || triggerOperation === 'equal') && processParameter && processParameter === triggerValue){
                
                groupListCheck.push(true)
              }else if(triggerOperation === 'diff' && processParameter && processParameter !== triggerValue){
                
                groupListCheck.push(true)
              }else{
                groupListCheck.push(false)
              }
            })
            
            if(groupListCheck.filter(c => c).length === groupListCheck.length){
              
              triggerCheck.push(true)
            }else{
              triggerCheck.push(false)
            }
          }
        }else{
          let processParameter = process[triggerParameter];
          if((!triggerOperation || triggerOperation === 'equal') && processParameter === triggerValue){
            
            triggerCheck.push(true)
          }else if(triggerOperation === 'diff' && processParameter !== triggerValue){
            
            triggerCheck.push(true)
          }else{

            triggerCheck.push(false)
          }
        }
        
        if(
          (behavior === 'or' && triggerCheck.filter( c => c).length > 0) ||
          (behavior === 'and' && triggerCheck.length > 0 && triggerCheck.filter( c => c).length === triggerCheck.length)
        ){
          resultCheck = true;
        }
      }
    })
  }
  
  return resultCheck
}

export const updateProcess = (userLogged, process, params ) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      dispatch(layoutActions.block(null));
        await business
        .updateProcess(userLogged, process, params)
        .then( result => {
          dispatch(messageActions.setSuccess(result));
          dispatch(layoutActions.unblock());
        })
        .catch(error => {
          dispatch(layoutActions.unblock());
          dispatch(messageActions.setError(error));
        });
    }
  }
}

export const updateProcesses = (userLogged, processes) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      dispatch(layoutActions.block(null));
        await business
        .updateProcesses(userLogged, processes)
        .then( result => {
          dispatch(messageActions.setSuccess(result));
          dispatch(layoutActions.unblock());
        })
        .catch(error => {
          dispatch(layoutActions.unblock());
          dispatch(messageActions.setError(error));
        });
    }
  }
}

export const getAwbByNumber = (userLogged, awb) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      //dispatch(layoutActions.block());
      await business
        .getAwbByNumber(userLogged, awb)
        .then(result => {
          dispatch({
            type: '@process/AWB',
            payload: result
          });
          dispatch(layoutActions.unblock());
        })
        .catch(error => {
          dispatch(layoutActions.unblock());
          //dispatch(messageActions.setError(error));
        });
    }
  };
};

export const getBlByNumber = (userLogged, bl) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      //dispatch(layoutActions.block());
      await business
        .getBlByNumber(userLogged, bl)
        .then(result => {
          
          dispatch({
            type: '@process/BL',
            payload: result
          });
          dispatch(layoutActions.unblock());
        })
        .catch(error => {
          dispatch(layoutActions.unblock());
          //dispatch(messageActions.setError(error));
        });
    }
  };
};

export const getLisDis = (userLogged) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      //dispatch(layoutActions.block());
      await business
        .getLisDis(userLogged)
        .then(result => {
          
          if(result.lis){
            dispatch({
              type: '@process/LI_LIST',
              payload: result.lis
            });
          }
          if(result.dis){
            dispatch({
              type: '@process/DI_LIST',
              payload: result.dis
            });
          }
          dispatch(layoutActions.unblock());
        })
        .catch(error => {
          dispatch(layoutActions.unblock());
          //dispatch(messageActions.setError(error));
        });
    }
  };
};

export const ignoreAutomation = (userLogged, process, automation ) => {
  return async dispatch => {
    if (userLogged != null && userLogged.Token != null) {
      let processId = process._id
      let automationId = automation._id
      let ignoreParameter = `automationIgnore_${automationId}`
      let params = [
        {
          parameter: ignoreParameter,
          value: !process[ignoreParameter]
        }
      ]
      dispatch(layoutActions.block(null));
        await business
        .updateProcess(userLogged, process, params)
        .then( result => {

          dispatch(layoutActions.unblock());
        })
        .catch(error => {
          dispatch(layoutActions.unblock());
          dispatch(messageActions.setError(error));
        });
    }
  }
}

export const forceGetProcessStatus = (userLogged, processes, operations) => {
  return async dispatch => {
    let updatedProcesses = putStatusInProcesses(userLogged, processes, operations)
    dispatch({
      type: '@data/PROCESSES',
      payload: updatedProcesses
    });
  };
};

export const getProcessTracking = (id, token) => {
  return async dispatch => {
    dispatch(layoutActions.block(null));
    await business
    .getProcessTracking(id, token)
    .then( result => {
      dispatch({
        type: '@process/ROADTRANSPORT_TRACKING_DATA',
        payload: result
      });
      dispatch({
        type: '@operations/PARAMETERS',
        payload: result.parameters
      });
      dispatch(layoutActions.unblock());
    })
    .catch(error => {
      dispatch(layoutActions.unblock());
      dispatch(messageActions.setError(error));
    });
  }
}

export const getLeadTimes = (id, ops, dateRange) => {
  return async dispatch => {
    dispatch(layoutActions.block(null));
    await business
    .getLeadTimes(id, ops, dateRange)
    .then( result => {
      // dispatch({
      //   type: '@operations/PARAMETERS',
      //   payload: result.parameters
      // });
      dispatch(layoutActions.unblock());
    })
    .catch(error => {
      dispatch(layoutActions.unblock());
      dispatch(messageActions.setError(error));
    });
  }
}

