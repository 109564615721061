//TODO

let foreignOperators = [
  {
    "seq": 1,
    "cpfCnpjRaiz": "30546072000108",
    "codigo": "001",
    "versao": "1",
    "nome": "THE ABSOLUT COMPANY AB",
    "nickname": "ABSOLUT",
    "code": "ABSOLUT",
    "situacao": "ATIVADO",
    "logradouro": "Köpmannagatan 29, 296 31 Åhus, Sweden",
    "geolocation": {
      "lat": 55.925717,
      "lng": 14.297717
    },
    "nomeCidade": "Ahus",
    "codigoSubdivisaoPais": "",
    "codigoPais": "SE",
    "cep": "",
    "codigoInterno": "0001",
    "dataReferencia": "",
    "metadata": {
      data: [{
        'name': 'AN8',
        'value' : '00000000'
      }]
    }
  },
  {
    "seq": 1,
    "cpfCnpjRaiz": "30546072000108",
    "codigo": "002",
    "versao": "1",
    "nome": "CHIVAS BROTHERS",
    "nickname": "CHIVAS",
    "code": "CBL",
    "situacao": "ATIVADO",
    "logradouro": "Kilmalid, Stirling Road, Dumbarton G82 2SS, United Kingdom",
    "geolocation": {
      "lat": 55.973851,
      "lng": -4.565796
    },
    "nomeCidade": "Dumbarton",
    "codigoSubdivisaoPais": "",
    "codigoPais": "UK",
    "cep": "",
    "codigoInterno": "0002",
    "dataReferencia": ""
  }
]

const INITIAL_STATE = {
  productList: null,
  selectedProduct: null,
  productForm: null,
  foreignOperatorList: foreignOperators,
  productMeasureUnitList: [
    {
       "Unidade":"AMPOLA","Descricao":"AMPOLA"  },
    {
       "Unidade":"BALDE","Descricao":"BALDE"  },
    {
       "Unidade":"BANDEJ","Descricao":"BANDEJA"  },
    {
       "Unidade":"BARRA","Descricao":"BARRA"  },
    {
       "Unidade":"BISNAG","Descricao":"BISNAGA"  },
    {
       "Unidade":"BLOCO","Descricao":"BLOCO"  },
    {
       "Unidade":"BOBINA","Descricao":"BOBINA"  },
    {
       "Unidade":"BOMB","Descricao":"BOMBONA"  },
    {
       "Unidade":"CAPS","Descricao":"CAPSULA"  },
    {
       "Unidade":"CART","Descricao":"CARTELA"  },
    {
       "Unidade":"CENTO","Descricao":"CENTO"  },
    {
       "Unidade":"CJ","Descricao":"CONJUNTO"  },
      {
       "Unidade":"CNTR","Descricao":"CONTAINER"  },
    {
       "Unidade":"CM","Descricao":"CENTIMETRO"  },
    {
       "Unidade":"CM2","Descricao":"CENTIMETRO QUADRADO"  },
    {
       "Unidade":"CX","Descricao":"CAIXA"  },
    {
       "Unidade":"DISP","Descricao":"DISPLAY"  },
    {
       "Unidade":"DUZIA","Descricao":"DUZIA"  },
    {
       "Unidade":"EMBAL","Descricao":"EMBALAGEM"  },
    {
       "Unidade":"FARDO","Descricao":"FARDO"  },
    {
       "Unidade":"FOLHA","Descricao":"FOLHA"  },
    {
       "Unidade":"FRASCO","Descricao":"FRASCO"  },
    {
       "Unidade":"GALAO","Descricao":"GALÃO"  },
    {
       "Unidade":"GF","Descricao":"GARRAFA"  },
    {
       "Unidade":"GRAMAS","Descricao":"GRAMAS"  },
   {
         "Unidade":"ISOTANK","Descricao":"ISOTANK"  },
    {
       "Unidade":"JOGO","Descricao":"JOGO"  },
    {
       "Unidade":"KG","Descricao":"QUILOGRAMA"  },
    {
       "Unidade":"KIT","Descricao":"KIT"  },
    {
       "Unidade":"LATA","Descricao":"LATA"  },
    {
       "Unidade":"LITRO","Descricao":"LITRO"  },
    {
       "Unidade":"M","Descricao":"METRO"  },
    {
       "Unidade":"M2","Descricao":"METRO QUADRADO"  },
    {
       "Unidade":"M3","Descricao":"METRO CÚBICO"  },
    {
       "Unidade":"MILHEI","Descricao":"MILHEIRO"  },
    {
       "Unidade":"ML","Descricao":"MILILITRO"  },
    {
       "Unidade":"MWH","Descricao":"MEGAWATT HORA"  },
    {
       "Unidade":"PACOTE","Descricao":"PACOTE"  },
    {
       "Unidade":"PALETE","Descricao":"PALETE"  },
    {
       "Unidade":"PARES","Descricao":"PARES"  },
    {
       "Unidade":"PC","Descricao":"PEÇA"  },
    {
       "Unidade":"POTE","Descricao":"POTE"  },
    {
       "Unidade":"K","Descricao":"QUILATE"  },
    {
       "Unidade":"RESMA","Descricao":"RESMA"  },
    {
       "Unidade":"ROLO","Descricao":"ROLO"  },
    {
       "Unidade":"SACO","Descricao":"SACO"  },
    {
       "Unidade":"SACOLA","Descricao":"SACOLA"  },
    {
       "Unidade":"TAMBOR","Descricao":"TAMBOR"  },
    {
       "Unidade":"TANQUE","Descricao":"TANQUE"  },
    {
       "Unidade":"TON","Descricao":"TONELADA"  },
    {
       "Unidade":"TUBO","Descricao":"TUBO"  },
    {
       "Unidade":"UNID","Descricao":"UNIDADE"  },
    {
       "Unidade":"VASIL","Descricao":"VASILHAME"  },
    {
       "Unidade":"VIDRO","Descricao":"VIDRO"  }
  ],
  mailerList: null
};


export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@products/PRODUCT_LIST':
      return {
        ...state,
        productList: action.payload
      }; 
    case '@products/SELECTED_PRODUCT':
      
      return {
        ...state,
        selectedProduct: action.payload
      }; 
    case '@products/PRODUCT_FORM':
      
      return {
        ...state,
        productForm: action.payload
      };
    case '@products/FOREIGN_OPERATORS_LIST':
      return {
        ...state,
        foreignOperatorList: action.payload
      }; 

   case '@mailer/MAILER_LIST':
      return {
         ...state,
         mailerList: action.payload
      }; 
      
      
      default:
      return state;
  }
}
