
import * as services from './services';


export function getOperations(user) {
  return new Promise(async (resolve, reject) => {
    services
      .getOperations(user)
      .then(result => {
        
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export async function getOperationsPermissions(user, operations) {
  let result = {};
  let promises = [];
  for(let operation of operations){
    promises.push(services.getOperationsPermissions(user, operation.Operation))
  }
  await Promise.all(promises).then(permission => {
    permission.forEach(perm => {
      result = {
        ...result,
        ...perm
      }
    })
  });
  return result
}

export function getParameters(user) {
  return new Promise(async (resolve, reject) => {
    services
      .getParameters(user)
      .then(result => {
        
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getPhases(user) {
  return new Promise(async (resolve, reject) => {
    services
      .getPhases(user)
      .then(result => {
        
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}



export function saveNewAutomationInOperation(user, data) {
  return new Promise(async (resolve, reject) => {
    services
      .saveNewAutomationInOperation(user, data)
      .then(result => {
        
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}


export function getDashboardData(user, operations) {
  return new Promise(async (resolve, reject) => {
    services
      .getDashboardData(user, operations)
      .then(result => {
        
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}




export function toogleAutomation(user, data) {
  return new Promise(async (resolve, reject) => {
    services
      .toogleAutomation(user, data)
      .then(result => {
        
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function deleteAutomation(user, id) {
  return new Promise(async (resolve, reject) => {
    services
      .deleteAutomation(user, id)
      .then(result => {
        
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}





export function getDashboardComponents(user, operations, date) {
  return new Promise(async (resolve, reject) => {

    services
      .getDashboardComponent(user,operations, date)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}



