
import * as services from './services';
import {getCargoOwners} from '../../Utils/functions';

export function getReports(user, operations) {
  return new Promise(async (resolve, reject) => {
    services
      .getReports(user, operations)
      .then(result => {
        
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getProfileReports(user) {
  return new Promise(async (resolve, reject) => {
    services
      .getProfileReports(user)
      .then(result => {
        
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getCompletedProcesses(user) {
  return new Promise(async (resolve, reject) => {
    let token = user.token;
    let cargoOwners = [];
    if (user) {
      cargoOwners = getCargoOwners(user)
    }
    services
      .getCompletedProcesses(user, cargoOwners)
      .then(result => {
          resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}
export function saveReport(userLogged, reportId, reportData) {
  return new Promise(async (resolve, reject) => {
    services
      .saveReport(userLogged, reportId, reportData)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}
