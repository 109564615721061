import React, { useEffect } from 'react'
import {socket} from './services/socket'
import {getCargoOwners} from '../Utils/functions'

export default function Data(props) {

  const {
    userLogged,
    callUpdateProcessList
  } = props;

  useEffect(() => {
    let cargoOwners = [];
    if( userLogged ){
      cargoOwners = getCargoOwners(userLogged)
    }
    
    if(cargoOwners && cargoOwners.length > 0){
      for(let cargoOwner of cargoOwners){
        let obj = JSON.parse(cargoOwner);
        const socketAddress = `companies/${obj.ClientType}/${obj.Country}/${obj.OficialDocument}`;
        console.log('Socket susbcribed')
        socket.on(socketAddress, data => {
          console.log('Socket received...',)
          callUpdateProcessList(userLogged, data)
        });
      }
    }
  }, [userLogged, socket]);

  return null
}




