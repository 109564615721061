import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Checkbox,
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
  OutlinedInput
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import Auth0Api from '../../App/api/Auth0Api';
import InputMask from '../../App/components/InputMask';
import { primaryColor, secondaryColor } from '../../App/assets/jss/material-dashboard-pro-react';
import { firstAccessSchema } from './firstAccessSchema';
import { mapUserData } from '../../App/utils/handleUserAccess';
import { purgeSpecialChars } from '../../App/utils/purgeSpecialChars';
import * as messageActions from '../../App/actions/message_actions';
import { getUserData } from '../../FirstAccess/containers/business';
import { isValidCnpj } from '../../App/utils/isValidCnpj';
import { isValidCpf } from '../../App/utils/isValidCpf';
import {getData} from 'country-list';
export const DOCUMENT_MASK = {
  CNPJ: '99.999.999/9999-99',
  CPF: '999.999.999-99',
  TIN: '',
};

export default function TasksPres(props) {
  const userLogged = useSelector(state => state.auth.userLogged);
  const currentUser = useSelector(state => state.auth.currentUser);
  const { language: userDefaultLanguage } = props;

  const countriesList = getData();

  let dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid },
    setValue,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(firstAccessSchema),
    defaultValues: {
      country: userLogged.Country,
    },
  });

  const label = {
    selectCountry: {
      'pt-br': 'Selecione seu país',
      'en-gb': 'Select your country',
    },
    welcomeToFlowls: {
      'pt-br': 'Criar sua conta',
      'en-gb': 'Create your account',
    },
    welcome: {
      'pt-br': 'Bem-vindo',
      'en-gb': 'Welcome',
    },
    agree: {
      'pt-br': 'Estou de acordo com os Termos de Uso e Política de Privacidade',
      'en-gb': 'I agree to the Understand Terms and Conditions and Privacy Policy',
    },
    insertCpf: {
      'pt-br': 'Insira o CPF',
      'en-gb': 'Insert your ID number',
    },
    download: {
      'pt-br': 'Baixar termos de uso',
      'en-gb': 'Download Terms of Use',
    },
    country: {
      'pt-br': 'País',
      'en-gb': 'Country',
    },
    insertName: {
      'pt-br': 'Insira seu nome',
      'en-gb': 'Insert your name',
    },
    buttonNextLabel: {
      'pt-br': 'FINALIZAR CADASTRO',
      'en-gb': 'REGISTER',
    },
    registerSuccess: {
      'pt-br': 'Cadastro finalizado com sucesso',
      'en-gb': 'Register completed succesfully',
    },
  };

  const onGetUserData = officialDoc => {
    const cpf = purgeSpecialChars(officialDoc);
    getUserData({}, cpf)
      .then(data => {
        if (data) {
          setValue('name', data.nome);
          setValue('birthday', data.nascimento);
        }
      })
      .catch(error => {
        dispatch(messageActions.setError(error));
      });
  };

  const isBrazil = watch('country') === 'BR';
  const hasCountry = Boolean(watch('country'));
  const hasName = Boolean(watch('name'));

  let formLanguage = 'en-gb';

  if (isBrazil) {
    formLanguage = 'pt-br';
  }

  const handleCountrySelect = (value) => {
    setValue('name', null)
    setValue('officialDocument', null)
    setValue('country', value)
  }

  let form = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
          paddingTop: '50px',
        }}
      >
        <img
          style={{ width: '120px', marginRight: '20px' }}
          src={require('../../App/assets/logo/short_dark.png').default}
        />

        <label style={{ fontSize: 42, color: secondaryColor, marginTop: '30px' }}>
          {label.welcomeToFlowls[formLanguage]}
        </label>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          paddingTop={10}
          flex={1}
          height={200}
        >
          <FormControl variant="outlined" style={{ marginBottom: '20px' }}>
            <InputLabel >{label.selectCountry[formLanguage]}</InputLabel>
            <Select
              {...register('country')}
              onChange={event => handleCountrySelect(event.target.value)}
              style={{ width: '450px' }}
              input={<OutlinedInput label={label.selectCountry[formLanguage]} />}
            >
              {countriesList &&
                ['', ...countriesList]
                  .sort(country => country.name)
                  .map(country => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" style={{ marginBottom: '20px' }}>
            {isBrazil && hasCountry ? (
              <InputMask
                {...register('officialDocument')}
                label={label.insertCpf[formLanguage]}
                onChange={value => setValue('officialDocument', value)}
                childrenProps={{
                  fullWidth: true,
                  required: true,
                }}
                style={{ width: '450px' }}
                variant="outlined"
                mask={DOCUMENT_MASK['CPF']}
                onBlur={event => onGetUserData(event.target.value)}
              />
            ) : hasCountry ? (
              <TextField
                {...register('officialDocument')}
                label={label.insertCpf[formLanguage]}
                onChange={event => setValue('officialDocument', event.target.value)}
                fullWidth
                required
                variant="outlined"
                style={{ width: '450px' }}
              />
            ) : null}
          </FormControl>
          {hasCountry && !isBrazil ? (
            <FormControl variant="outlined" style={{ marginBottom: '20px' }}>
              <TextField
                color="primary"
                variant="outlined"
                label={label.insertName[formLanguage]}
                {...register('name')}
                style={{ width: '450px' }}
                disabled={isBrazil && hasName}
                onChange={event => setValue('name', event.target.value)}
              />
            </FormControl>
          ) : null}
        </Box>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ height: '50px' }}>
            {isBrazil && watch('name') && (
              <label style={{ fontSize: 16, color: 'gray' }}>{`${label.welcome[formLanguage]}, ${watch(
                'name',
              )}`}</label>
            )}
          </div>
          <Button
            cursor="pointer"
            style={{ color: primaryColor, fontSize: 18 }}
            onClick={() =>
              window.open(
                'https://flowls-public.s3.sa-east-1.amazonaws.com/Termos+de+Uso+do+Software+Flowls.pdf',
                '_blank',
              )
            }
          >
            {label.download[formLanguage]}
          </Button>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Checkbox
              color="primary"
              checked={watch('checkbox')}
              onChange={() => setValue('checkbox', !watch('checkbox'))}
            />
            <label style={{ fontSize: 16, color: '#404040' }}>{label.agree[formLanguage]}</label>
          </div>
        </div>
      </div>
    );
  };

  const onSubmit = values => {
    const data = {
      user_metadata: {
        name: values.name,
        country_code: values.country,
        document_number: purgeSpecialChars(values.officialDocument),
        birthday: values?.birthday ?? '',
      },
    };

    Auth0Api.patch(`/${currentUser.sub}`, data)
      .then(() => {

        dispatch({ type: 'USER_LOGGED', payload: mapUserData({...userLogged, 'https://flowls.app/user_metadata': data.user_metadata}) });

        dispatch(messageActions.setSuccess(label.registerSuccess[userDefaultLanguage]));

      })
      .catch(error => {
        dispatch(messageActions.setError(error));
      });
  };

  const isChecked = watch('checkbox');
  const isButtonDisabled = !(
    watch('officialDocument') &&
    watch('name') &&
    isChecked &&
    ((isValidCnpj(watch('officialDocument')) || isValidCpf(watch('officialDocument'))) || !isBrazil)
  );

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown fullWidth maxWidth="lg" open>
      <DialogContent>
        <div style={{ height: '70vh' }}>{form(userDefaultLanguage)}</div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          style={{ width: '100%' }}
          disabled={isButtonDisabled}
          onClick={handleSubmit(onSubmit)}
        >
          {label.buttonNextLabel[userDefaultLanguage]}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
