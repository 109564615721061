

import Axios from 'axios';

import Api from '../../App/api';

export const getFiles = (user, process) => {
  let CARGOSNAP_TOKEN = 'YzVBS2pGOHp3YUZWVDhEbVcyenpBR0lhWEJ2VnJ4U2NfNTE2'
  let CARGOSNAP_API = `https://platform.cargosnap.com/api/v2/files?format=json&token=${CARGOSNAP_TOKEN}&reference=${process}&include[]=uploads&include[]=forms&include[]=fields`
  return new Promise((resolve, reject) => {

    Axios.get(`${CARGOSNAP_API}`)
      .then(result => {
        resolve(result.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const refreshProfile = (user) => {
  let profile = user && user.Authorization?.Profile;

  return new Promise((resolve, reject) => {
    Api.get(`/flowlsapi/getworkspaceprofile/${[profile]}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export const getLabels = ((userLogged) => {
  return new Promise((resolve, reject) => {
    Axios.get(`${window.appConfig.endpoints.bff_node}/labels/getByName`, {
      headers: {Authorization: userLogged.Token},
      params: {
        name: 'container.status'
      }
    })
      .then(response => {
        resolve(response.data.map);
      })
      .catch(error => {
        reject(error);
      }); 
  });
});