import * as actorTypes from '../App/domainmodel/enum/actorType';

export function getCargoOwners(userLogged) {
  let cargoOwners = [];
  if (userLogged && userLogged.Authorization && userLogged.Authorization.ClientID) {
    if (!cargoOwners.some(cargoOwner => userLogged.Authorization.ClientID)) {
      cargoOwners.push(userLogged.Authorization.ClientID);
    }
  }

  userLogged?.Authorization?.Profiles &&
    userLogged.Authorization.Profiles.forEach(profile => {
      profile.Roles.forEach(role => {
        role.Partners.forEach(partner => {
          if (partner.ClientRoleTypes.includes(1)) {
            if (!cargoOwners.some(cargoOwner => cargoOwner === partner.PartnerID)) {
              cargoOwners.push(partner.PartnerID);
            }
          }
        });
      });
    });

  //subsidiaries
  if (userLogged.Authorization?.CurrentRole?.Subsidiaries?.length) {
    const subsidiaries = userLogged.Authorization?.CurrentRole?.Subsidiaries.map(subsidiary => {
      return `{"ClientType":${subsidiary.ClientType}, "Country":"${subsidiary.Country}", "OficialDocument":"${subsidiary.OficialDocument}"}`
    });
    cargoOwners = [...cargoOwners, ...subsidiaries];
  }

  return cargoOwners;
}

export function getCargoHandlers(userLogged) {
  let cargoOwners = [];
  if (userLogged && userLogged.Authorization && userLogged.Authorization.ClientID) {
    if (!cargoOwners.some(cargoOwner => userLogged.Authorization.ClientID)) {
      cargoOwners.push(userLogged.Authorization.ClientID);
    }
  }
  userLogged?.Authorization?.Profiles &&
    userLogged.Authorization.Profiles.forEach(profile => {
      profile.Roles.forEach(role => {
        role.Partners.forEach(partner => {
          if (
            partner.ClientRoleTypes.includes(actorTypes['CargoOwner']) ||
            partner.ClientRoleTypes.includes(actorTypes['FreightForwarder'])
          ) {
            if (!cargoOwners.some(cargoOwner => cargoOwner === partner.PartnerID)) {
              cargoOwners.push(partner.PartnerID);
            }
          }
        });
      });
    });
  //subsidiaries
  if (userLogged.Authorization?.CurrentRole?.Subsidiaries?.length) {
    const subsidiaries = userLogged.Authorization?.CurrentRole?.Subsidiaries.map(p => p.PartnerID);
    cargoOwners = [...cargoOwners, ...subsidiaries];
  }
  return cargoOwners;
}

export const getParametersFromOperation = (operation, parameters) => {
  let allParameters = [];
  let phases = operation.Phases;
  const getParametersFromForm = form => {
    let parametersFromForm = [];
    for (let row of form) {
      for (let field of row) {
        let paramData = parameters[field.Parameter];
        if (paramData && paramData.Parameter.includes('group_')) {
          const groupParameters = getGroupParameters(paramData.Parameter);
          parametersFromForm = [...parametersFromForm, ...groupParameters];
        } else if(field){
          parametersFromForm.push(field.Parameter);
        }
      }
    }
    return parametersFromForm;
  };
  const getGroupParameters = group => {
    const groupForm = parameters[group].Form;
    let groupParameters = getParametersFromForm(groupForm);
    return groupParameters;
  };
  for (let phase of phases) {
    let form = phase.Forms.update;
    let phaseParameters = getParametersFromForm(form);
    allParameters = [...allParameters, ...phaseParameters];
  }
  return allParameters;
};

export function TestaCPF(strCPF) {
  var Soma;
  var Resto;
  Soma = 0;
  if (strCPF == '00000000000') return false;

  for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

export function getPermissionPhasesMap(userLogged, operation, operationsPermissions) {
  const myCompanyId = userLogged?.Authorization?.OficialDocument;
  const phasesPermission = operation && operationsPermissions[operation.Operation]?.PHASES;
  const phasesPermissionMap = {};
  if (phasesPermission) {
    let lastPermittedPhase;
    operation.OriginalPhases.forEach(phase => {
      const phasePermission = phasesPermission[phase.Phase];
      if (!phasePermission || !phasePermission[myCompanyId] || phasePermission[myCompanyId] !== 'BLOCKED') {
        lastPermittedPhase = phase.Phase;
      }
      if (phase.IsPOGroupedPhase) {
        phasesPermissionMap[phase.Phase] = phase.Phase;
      } else if (!operation.OriginalPhases.find(p => p.Phase === lastPermittedPhase)?.IsPOGroupedPhase) {
        phasesPermissionMap[phase.Phase] = lastPermittedPhase;
      } else {
        phasesPermissionMap[phase.Phase] = null;
      }
    });
    operation.OriginalPhases.forEach(phase => {
      if (!phasesPermissionMap[phase.Phase]) {
        let permissions = Object.entries(phasesPermissionMap).filter(
          a => a && a[1] && !operation.OriginalPhases.find(p => p.Phase === a[0])?.IsPOGroupedPhase,
        )[0]
        phasesPermissionMap[phase.Phase] = permissions && permissions[1];
      }
    });
  }
  return phasesPermissionMap;
}
