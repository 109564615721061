import 'perfect-scrollbar/css/perfect-scrollbar.css';
import './assets/block-ui.css';
import 'react-table-v6/react-table.css';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ScaleLoader } from 'react-spinners';
import { library } from '@fortawesome/fontawesome-svg-core';
import PerfectScrollbar from 'perfect-scrollbar';
import AddAlert from '@material-ui/icons/AddAlert';
import PropTypes from 'prop-types';
import React from 'react';
import ReduxBlockUi from 'react-block-ui/redux';
import cx from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import { withAuth0 } from '@auth0/auth0-react';

import Socket from './socket';
// core components
import * as messageActions from './actions/message_actions';
import * as authActions from './actions/auth_actions';
import * as layoutActions from './actions/layout_actions';
import * as processActions from '../Process/containers/actions';

import * as reducers from './actions/types';

import Card from './components/Card/Card';
import CardBody from './components/Card/CardBody';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import Snackbar from './components/Snackbar/Snackbar';

import appStyle from './assets/jss/material-dashboard-pro-react/layouts/appStyle';

import image from './assets/img/sidebar.jpg';
import logo from './assets/img/flowls_white_alt2.png';
import logoMini from './assets/img/flowls_white_mini_text.png';
import routes from './routes/routes';
import Setup from '../Setup';
import FirstAccess from '../FirstAccess/FirstAccess';
import { getCargoOwners } from '../Utils/functions';
import LoadingScreen from './components/LoadingScreen';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment/locale/en-gb';

//--------ICONS--------
import {
  faAnalytics,
  faCogs,
  faTasksAlt,
  faCabinetFiling,
  faShoppingCart,
  faStoreAlt,
  faIndustryAlt,
  faBriefcase,
  faMapMarkerAlt,
  faCameraAlt,
  faRobot,
  faMapMarkedAlt,
  faTruckRamp,
  faFileSpreadsheet,
  faArrowAltDown,
  faArrowAltUp,
  faPencil,
  faFileInvoiceDollar,
  faStamp,
  faExpandWide,
  faUserHeadset,
  faCalendarDay,
  faMoneyBillWave,
  faDolly,
  faCalculatorAlt,
  faInventory,
  faHistory,
  faFilePdf,
  faStream,
  faExclamationCircle,
  faCodeBranch,
  faWarehouse,
  faNewspaper,
  faRecycle,
  faWineBottle,
  faFileImage
} from '@fortawesome/pro-solid-svg-icons';
import {
  faHome,
  faColumns,
  faRoute,
  faCalendarWeek,
  faTable,
  faPlus,
  faCode,
  faKeyboard,
  faFileExcel,
  faChevronRight,
  faShip,
  faTruck,
  faClipboardList,
  faGlobe,
  faExpand,
  faCompress,
  faDollarSign,
  faBoxes,
  faPlane,
  faBox,
  faPallet,
  faMinus,
  faIndustry,
  faPlaneDeparture,
  faQuestion,
  faCircle,
  faExclamationTriangle,
  faSatelliteDish,
  faSatellite,
  faUpload,
  faCaretLeft,
  faCaretRight,
  faCaretDown,
  faChartBar,
  faTasks,
  faClipboardCheck,
  faArrowRight,
  faPaperPlane,
  faCalculator,
  faCompass,
  faTrain,
  faChartArea,
} from '@fortawesome/free-solid-svg-icons';
import {
  faBuilding,
  faUsers,
  faBoxOpen,
  faTrashAlt,
  faEdit,
  faUser,
  faFileDownload,
  faFolderOpen,
  faInboxIn,
  faArrowAltRight,
  faArrowAltLeft,
  faMapMarked,
  faFlag,
  faSearch,
  faCrosshairs,
  faClipboard,
  faTimes,
  faTruckContainer,
  faSnowflake,
  faSearchPlus,
  faSearchMinus,
  faClock,
  faCheckCircle,
  faCheck,
  faChartLine,
  faStopwatch,
  faAlarmExclamation,
  faBoxCheck,
  faShippingTimed,
  faWarehouseAlt,
  faPlusCircle,
  faBoxFull,
  faFileCheck,
  faFileUpload,
  faCloudUploadAlt,
  faSensorAlert,
  faImages,
  faPrint,
  faSteeringWheel,
  faContainerStorage,
  faAlarmClock,
  faSirenOn,
  faFileSignature,
  faTruckLoading,
  faFilter,
  faCalendar,
  faSyncAlt,
  faLocation,
  faTrailer,
  faCalendarAlt,
  faInfoCircle,
  faExternalLink,
  faEnvelope,
  faFileContract,
  faEllipsisV,
  faCopy,
  faObjectGroup,
  faProjectDiagram,
  faWrench,
  faDownload,
  faCalendarCheck,
  faFileInvoice,
  faEye,
  faUndo,
  faKey,
  faLock,
  faLink,
  faEmptySet,
  faCog,
  faExchange,
  faExchangeAlt,
} from '@fortawesome/pro-regular-svg-icons';

require('dotenv').config();

library.add(
  faHome,
  faAnalytics,
  faCogs,
  faCog,
  faRoute,
  faColumns,
  faTasksAlt,
  faCalendarWeek,
  faTable,
  faCabinetFiling,
  faBuilding,
  faUsers,
  faBoxOpen,
  faPlus,
  faCode,
  faKeyboard,
  faFileExcel,
  faChevronRight,
  faTrashAlt,
  faEdit,
  faUser,
  faShoppingCart,
  faStoreAlt,
  faShip,
  faTruck,
  faLock,
  faIndustryAlt,
  faFlag,
  faBriefcase,
  faGlobe,
  faClipboardList,
  faExpand,
  faCompress,
  faDollarSign,
  faMapMarkerAlt,
  faCameraAlt,
  faFileDownload,
  faFolderOpen,
  faBoxes,
  faInboxIn,
  faUpload,
  faArrowAltRight,
  faArrowAltLeft,
  faRobot,
  faLink,
  faMapMarkedAlt,
  faPlane,
  faBox,
  faMapMarked,
  faSearch,
  faCrosshairs,
  faClipboard,
  faTruckRamp,
  faTruckLoading,
  faPallet,
  faTimes,
  faTruckContainer,
  faSnowflake,
  faMinus,
  faFileSpreadsheet,
  faIndustry,
  faSearchPlus,
  faSearchMinus,
  faClock,
  faCheckCircle,
  faCheck,
  faChartLine,
  faStopwatch,
  faAlarmExclamation,
  faBoxCheck,
  faShippingTimed,
  faWarehouseAlt,
  faPlaneDeparture,
  faQuestion,
  faArrowAltUp,
  faArrowAltDown,
  faPlusCircle,
  faCircle,
  faBoxFull,
  faPencil,
  faFileCheck,
  faStamp,
  faFileInvoiceDollar,
  faFileUpload,
  faExclamationTriangle,
  faSatelliteDish,
  faSatellite,
  faCloudUploadAlt,
  faCaretLeft,
  faCaretRight,
  faCaretDown,
  faExpandWide,
  faChartBar,
  faSensorAlert,
  faFileSignature,
  faUserHeadset,
  faCalendarDay,
  faTasks,
  faClipboardCheck,
  faMoneyBillWave,
  faArrowRight,
  faImages,
  faPrint,
  faSteeringWheel,
  faContainerStorage,
  faAlarmClock,
  faSirenOn,
  faDolly,
  faPaperPlane,
  faFilter,
  faCalendar,
  faSyncAlt,
  faLocation,
  faTrailer,
  faCalendarAlt,
  faCalculatorAlt,
  faCalculator,
  faInfoCircle,
  faExternalLink,
  faHistory,
  faInventory,
  faCompass,
  faEnvelope,
  faFilePdf,
  faFileContract,
  faEllipsisV,
  faCopy,
  faObjectGroup,
  faProjectDiagram,
  faWrench,
  faStream,
  faDownload,
  faCalendarCheck,
  faExclamationCircle,
  faCodeBranch,
  faFileInvoice,
  faTrain,
  faWarehouse,
  faNewspaper,
  faRecycle,
  faWineBottle,
  faChartArea,
  faEye,
  faFileImage,
  faUndo,
  faKey,
  faEmptySet,
  faExchange,
  faExchangeAlt
);

let ps;

class PrivateLayout extends React.Component {
  constructor(props) {
    super(props);
    this.toggleSidebarDrawerOpen = this.toggleSidebarDrawerOpen.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
    this.setError = this.setError.bind(this);
    this.setInfo = this.setInfo.bind(this);
    this.setSuccess = this.setSuccess.bind(this);
  }

  callUpdateProcessList = (userLogged, data) => {
    const { parameters, updateProcessesList, operations, processSelected, processesList } = this.props;
    updateProcessesList(userLogged, data, processesList, parameters, operations, processSelected);
  };

  componentDidMount = () => {
    const { language, userLogged } = this.props;
    let cargoOwners = [];
    if (userLogged) {
      cargoOwners = getCargoOwners(userLogged);
    }
    if (cargoOwners) {
      return;
    }

    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', this.updateDimensions);
    moment.locale(language);
  };

  componentWillUnmount = () => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
    window.removeEventListener('resize', this.updateDimensions);
  };

  componentDidUpdate = e => {
    const { setSidebarDrawerOpen, sidebarDrawerOpen } = this.props;
    if (e.history.location.pathname !== e.location.pathname) {
      this.mainPanel.scrollTop = 0;
      if (sidebarDrawerOpen) {
        setSidebarDrawerOpen(false);
      }
    }
  };

  toggleSidebarDrawerOpen = () => {
    const { setSidebarDrawerOpen, sidebarDrawerOpen } = this.props;
    setSidebarDrawerOpen(!sidebarDrawerOpen);
  };

  handleChangeClient = value => {
    const { userLogged, changeUserAuthorization } = this.props;
    const client = eval('(' + value + ')');

    changeUserAuthorization(userLogged, client.ClientType, client.Country, client.OficialDocument);
  };

  setLanguage = () => {
    const { setLanguage } = this.props;
    setLanguage();
  };

  setError = () => {
    const { setError } = this.props;
    setError('');
  };

  setInfo = () => {
    const { setInfo } = this.props;
    setInfo('');
  };

  setSuccess = () => {
    const { setSuccess } = this.props;
    setSuccess('');
  };

  renderBody = () => {
    const {
      classes,
      userLogged,
      miniActive,
      sidebarDrawerOpen,
      language,
      userData,
      fullScreen,
      operationView,
      children,
      auth0,
      ...rest
    } = this.props;
    const { logout } = auth0;

    const mainPanel = `${classes.mainPanel} ${cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
    })}`;

    return (
      <div className={classes.wrapper}>
        {!userLogged || !userLogged.Authorization ? (
          <LoadingScreen />
        ) : (
          <Sidebar
            userLogged={userLogged}
            signOutUser={logout}
            userData={userData}
            changeUserDate={this.changeUserDate}
            routes={routes}
            logo={logo}
            logoMini={logoMini}
            image={image}
            toggleSidebarDrawerOpen={this.toggleSidebarDrawerOpen}
            open={sidebarDrawerOpen}
            color="blue"
            bgColor="black"
            miniActive={miniActive}
            language={language}
            {...rest}
          />
        )}
        <div
          className={mainPanel}
          ref={c => {
            this.mainPanel = c;
          }}
        >
          {!userLogged || !userLogged.Authorization ? null : (
            <Header
              operationView={operationView}
              setLanguage={this.setLanguage}
              routes={routes}
              toggleSidebarDrawerOpen={this.toggleSidebarDrawerOpen}
              language={language}
              {...rest}
              handleChangeClient={this.handleChangeClient}
              userLogged={userLogged}
            />
          )}

          <div className={classes.content} style={{ backgroundColor: '#EEEEEE' }}>
            <div className={classes.container}>{children}</div>
          </div>
        </div>
      </div>
    );
  };

  renderLoader = () => {
    const { blockMessage } = this.props;
    if (blockMessage == null || blockMessage === '')
      return (
        <div style={{ width: '60px' }}>
          <ScaleLoader size={60} loading color="#1c385c" style={{ margin: '5px' }} />
        </div>
      );
    return (
      <Card style={{ width: 400, height: 200, left: -200, zIndex: 2010 }}>
        <CardBody>
          <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
              }}
            >
              <ScaleLoader loading color="#1c385c" size={20} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                height: '100%',
                alignItems: 'center',
                flex: 2,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%',
                  alignItems: 'center',
                  marginLeft: '10px',
                }}
              >
                <label style={{ fontSize: 16, fontWeight: 'bold', color: 'gray' }}>{blockMessage}</label>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  render = () => {
    const { userLogged, error, info, success } = this.props;
    const isUserFirstAccess = userLogged.Token && !userLogged?.OficialDocument;
    return (
      <div style={{ backgroundColor: '#EEEEEE' }}>
        <Setup />

        <Socket userLogged={userLogged} callUpdateProcessList={this.callUpdateProcessList} />

        {isUserFirstAccess && <FirstAccess />}

        <ReduxBlockUi tag="div" block={reducers.BLOCK} unblock={[reducers.UNBLOCK, /fail/i]} loader={this.renderLoader}>
          <Snackbar
            verticalPlace="bottom"
            horizontalPlace="right"
            color="danger"
            icon={AddAlert}
            message={error}
            open={error !== ''}
            closeNotification={this.setError}
            close
          />
          <Snackbar
            verticalPlace="bottom"
            horizontalPlace="right"
            color="info"
            icon={AddAlert}
            message={info}
            open={info !== ''}
            closeNotification={this.setInfo}
            close
          />
          <Snackbar
            verticalPlace="bottom"
            horizontalPlace="right"
            color="success"
            icon={AddAlert}
            message={success}
            open={success !== ''}
            closeNotification={this.setSuccess}
            close
          />

          {this.renderBody()}
        </ReduxBlockUi>
      </div>
    );
  };
}

PrivateLayout.propTypes = {
  // style
  classes: PropTypes.instanceOf(Object).isRequired,
  auth0: PropTypes.PropTypes.instanceOf(Object).isRequired,

  // redux
  error: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  success: PropTypes.string.isRequired,
  blockMessage: PropTypes.string.isRequired,
  miniActive: PropTypes.bool.isRequired,
  userLogged: PropTypes.instanceOf(Object).isRequired,
  sidebarDrawerOpen: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,

  // actions
  setError: PropTypes.func.isRequired,
  setInfo: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  setBlokers: PropTypes.func.isRequired,
  setSidebarDrawerOpen: PropTypes.func.isRequired,
  setMine: PropTypes.func.isRequired,
  setLanguage: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    userLogged: state.auth.userLogged,
    userData: state.user.userData,

    error: state.notification.error,
    info: state.notification.info,
    success: state.notification.success,
    blockMessage: state.notification.blockMessage,
    sidebarDrawerOpen: state.layout.sidebarDrawerOpen,
    miniActive: state.layout.miniActive,
    language: state.layout.language,
    parameters: state.operations.parameters,
    operations: state.operations.operations,
    operationView: state.operations.operationView,
    processesList: state.process.processesList,
    processSelected: state.process.processSelected,
  };
}

export default connect(mapStateToProps, {
  setBlokers: layoutActions.setBlokers,
  setSidebarDrawerOpen: layoutActions.setSidebarDrawerOpen,
  setMine: layoutActions.setMine,
  setLanguage: layoutActions.setLanguage,
  setError: messageActions.setError,
  setInfo: messageActions.setInfo,
  setSuccess: messageActions.setSuccess,
  changeUserAuthorization: authActions.changeUserAuthorization,
  updateProcessesList: processActions.updateProcessesList,
})(withAuth0(withRouter(withStyles(appStyle)(PrivateLayout))));
