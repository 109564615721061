

import Api from '../../App/api';

export const getOperations = ((user) => {
  return new Promise((resolve, reject) => {
    let data = [];
    user.Authorization.Profiles.forEach(profile => {
      profile.Roles.forEach(role => {
        role.Operations.forEach( op => {
          if(!data.some(a => a === op.OperationTypeCode)){
            data.push(op.OperationTypeCode)
          }
        })
      })
    })
    
    Api.post(`/operations/list`, 
      data,
    )
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      }); 
  });
});

export const getOperationsPermissions = ((user, operation) => {
  return new Promise((resolve, reject) => {
    Api.get(`/operations/getOperationPermissions/${operation}`)
      .then(response => {
        let permissionsData = {}
        response.data.forEach( operationPermission => {
          permissionsData[operationPermission.operation] = operationPermission.permissions;
        })
        resolve(permissionsData);
      })
      .catch(error => {
        reject(error);
      }); 
  });
});

export const getParameters = ((user) => {
  return new Promise((resolve, reject) => {
    Api.get(`/parameters/list`)
      .then(response => {
        let arrayResponse = response.data;
        let objectResponse = {};
        arrayResponse.forEach( r => {
          objectResponse[r.Parameter] = r
        })       
        resolve(objectResponse);
      })
      .catch(error => {
        reject(error);
      }); 
  });
});

export const getPhases = ((user) => {
  return new Promise((resolve, reject) => {
    Api.get(`/phases/list`)
      .then(response => {
        let arrayResponse = response.data;
        let objectResponse = {};
        arrayResponse.forEach( r => {
          objectResponse[r.Parameter] = r
        })
        resolve(objectResponse);
      })
      .catch(error => {
        reject(error);
      }); 
  });
});


export const saveNewAutomationInOperation = ((userLogged, data) => {
  return new Promise((resolve, reject) => {
    Api.post(`/operations/saveautomation`, data)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      }); 
  });
});


export const getDashboardData = ((userLogged, operations) => {
  return new Promise((resolve, reject) => {
    Api.get(`/operations/getDashboardData/${operations}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      }); 
  });
});



export const toogleAutomation = ((userLogged, automation) => {
  
  return new Promise((resolve, reject) => {
    Api.post(`/operations/toogleautomation`, automation)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      }); 
  });
});


export const deleteAutomation = ((userLogged, id) => {
  
  return new Promise((resolve, reject) => {
    Api.post(`/operations/deleteautomation`, {_id: id})
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      }); 
  });
});

export const getDashboardComponent = ((userLogged, ops, date) => {
  let operations = ops.map(op => op.Operation).join(';');
  return new Promise((resolve, reject) => {
    Api.get(`/flowlsapi/dashboard/${operations}/${date}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      }); 
  });
});

