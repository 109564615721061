import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';

import * as actions from '../../actions/message_actions';

export default function (ComposedComponent, permission) {
  const checkViewPermission = (user, pathname) => {
    let allowed = false;
    user.Authorization?.Profiles?.forEach(profile => {
      profile.Roles.forEach(role => {
        role.Views.forEach(view => {
          if (view.ViewType === permission) {
            allowed = true;
          }
        });
      });
    });
    if(pathname === "/"){
      allowed = true;
    }
    return allowed;
  };

  class Authentication extends Component {
    // componentDidMount = () => {
    //   const { userLogged, history } = this.props;
    //   if (history.location.pathname === '/processtracking') {
    //     history.push('/processtracking');
    //   } else {
    //     const allowed = checkViewPermission(userLogged, history.location.pathname);
    //     if (!allowed) {
    //       if (['/operations', '/'].includes(history.location.pathname)) {
    //         history.push('/reports');
    //       } else if (history.location.pathname === '/reports') {
    //         history.push('/trackingmonitor');
    //       } else {
    //         history.push('/permissiondenied');
    //       }
    //     }
    //   }
    // };

    // componentWillUpdate = nextProps => {
    //   const { history } = this.props;
    //   const user = nextProps.userLogged;
    //   const allowed = checkViewPermission(user);
    //   if (!allowed) history.push('/permissiondenied');
    // };

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  Authentication.propTypes = {
    userLogged: PropTypes.instanceOf(Object).isRequired,
    history: PropTypes.instanceOf(Object).isRequired,
  };

  function mapStateToProps(state) {
    return {
      userLogged: state.auth.userLogged,
    };
  }

  return connect(mapStateToProps, { setError: actions.setError })(withRouter(Authentication));
}
