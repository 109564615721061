
import * as services from './services';

export function getFiles(user, process) {
  return new Promise(async (resolve, reject) => {
    services
      .getFiles(user, process)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}


export function refreshProfile(user) {
  return new Promise(async (resolve, reject) => {
    services
      .refreshProfile(user)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}


export function getLabels(user) {
  return new Promise(async (resolve, reject) => {
    services
      .getLabels(user)
      .then(result => {
        resolve(result);
      })
      .catch(error => {
        reject(error);
      });
  });
}