import * as reducers from './types';

export const setSidebarDrawerOpen = value => {
  return {
    type: reducers.MOBILE_OPEN,
    payload: value
  };
};

export const setMine = value => {
  return {
    type: reducers.MINE_ACTIVE,
    payload: value
  };
};

export const setLanguage = language => {
  window.zE('webWidget', 'setLocale', language);
  return dispatch => {
    dispatch({
      type: reducers.LANGUAGE,
      payload: language
    });
  };
};

export const setWindowWidth = () => {
  return dispatch => {
    dispatch({
      type: reducers.WINDOWWIDTH,
      payload: window.innerWidth
    });
  };
};

export const setBlokers = blockers => {
  return dispatch => {
    dispatch({
      type: reducers.BLOCKERS,
      payload: blockers
    });
  };
};

export const block = (message,origin) => {
  
  return dispatch => {
    dispatch({
      type: reducers.BLOCK_MESSAGE,
      payload: message == null ? '' : message
    });

    dispatch({
      type: reducers.BLOCK
    });
  };
};

export const unblock = (origin) => {
  
  return dispatch => {
    dispatch({
      type: reducers.BLOCK_MESSAGE,
      payload: ''
    });
    dispatch({
      type: reducers.UNBLOCK
    });
  };
};

export const setOperationView = view => {
  return dispatch => {
    dispatch({
      type: reducers.OPERATION_VIEW,
      payload: view
    });
  };
};