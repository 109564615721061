import * as reducers from '../actions/types';

const INITIAL_STATE = {
  userList: [],
  userSave: {
    ID: null,
    Name: null,
    Email: null,
    Gender: null,
    Birthday: null,
    MotherName: null,
    Image: null,
    HomePhone: null,
    CellPhone: null,
    CPF: {
      ID: null,
      Code: null
    },
    CompanyProfiles: []
  },
  userDelete: {
    ID: null
  },
  userFilter: {
    searchValue: ''
  },
  userFilteredList: null,
  userData: {
    userLogged: null,
    dialog: false,
    currentPassword: '',
    newPassword: '',
    newPasswordCheck: '',
    email: '',
    fone: '',
    passwordValidate: {
      message: '',
      color: ''
    }
  },
  resetEmail: {
    show: false
  }

};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case reducers.USER_LIST:
      return {
        ...state,
        userList: action.payload
      };
    case reducers.USER_SAVE:
      return {
        ...state,
        userSave: action.payload
      };
    case reducers.USER_DELETE:
      return {
        ...state,
        userDelete: action.payload
      };
    case reducers.USER_FILTER:
      return {
        ...state,
        userFilter: {
          ...action.payload,
          searchValue: action.payload.searchValue
        }
      };
    case reducers.USER_FILTERED_LIST:
      return {
        ...state,
        userFilteredList: action.payload
      };
    case reducers.USER_DATA:
      return {
        ...state,
        userData: {
          ...action.payload,
          dialog: action.payload.dialog
        }
      };
    case reducers.RESET_PASSWORD_EMAIL:
      return {
        ...state,
        resetEmail: action.payload
      };
    default:
      return state;
  }
}
