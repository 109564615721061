const INITIAL_STATE = {
  processesList: [],
  newProcessDialog: false,
  spreadsheetIntegrationDialog: false,
  spreadsheetIntegrations: [],
  processDialog: false,
  processSelected: {},
  backlogProcessSelected: {},

  vehicleSelected: null,
  mainOperation: null,
  liList: [],
  diList: [],
  originalForm: null,
  awbData: null,
  blData: null,
  processForm: null,
  handlersForm: null,
  newOrderForm: {},
  selectedProcessPods: [],
  trackingData: null,
  processesCount: null,
  gotFirstProcessList: false,
  completedProcesses: null,
  dbLabels: {}
};


export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@data/PROCESSES':
      return {
        ...state,
        processesList: action.payload
      };
    case '@data/GOT_FIRST_PROCESSES_LIST':
      return {
        ...state,
        gotFirstProcessList: action.payload
      };
    case '@data/PROCESSES_COUNT':
      return {
        ...state,
        processesCount: action.payload
      };
    case '@process/NEW_PROCESS_DIALOG':
      
      return {
        ...state,
        newProcessDialog: action.payload
      }; 
    case '@process/PROCESS_DIALOG':
      return {
        ...state,
        processDialog: action.payload
      };
    case '@process/BACKLOG_DIALOG':
      return {
        ...state,
        backlogDialog: action.payload
      }; 
    case '@process/PROCESS_SELECTED':
      return {
        ...state,
        processSelected: action.payload
      };
    case '@process/BACKLOG_PROCESS_SELECTED':
      return {
        ...state,
        backlogProcessSelected: action.payload
      };  
    case '@process/SPREADSHEET_INTEGRATION_DIALOG':
      return {
        ...state,
        spreadsheetIntegrationDialog: action.payload
      }; 
    case '@process/SPREADSHEET_INTEGRATIONS':
      
      return {
        ...state,
        spreadsheetIntegrations: action.payload
      }; 
    
    case '@process/SELECTED_VEHICLE':
      return {
        ...state,
        vehicleSelected: action.payload
      }; 
    case '@process/MAIN_OPERATION':
      return {
        ...state,
        mainOperation: action.payload
      };  
    case '@process/LI_LIST':
      return {
        ...state,
        liList: action.payload
      };
    case '@process/DI_LIST':
      return {
        ...state,
        diList: action.payload
      };  
    case '@process/AWB':
      return {
        ...state,
        awbData: action.payload
      }; 
    case '@process/BL':
      return {
        ...state,
        blData: action.payload
      };   
    case '@process/ORIGINAL_FORM':
      return {
        ...state,
        originalForm: action.payload
      }; 
    case '@process/FORM':
      return {
        ...state,
        processForm: action.payload
      };   
    case '@process/HANDLERS_FORM':
      return {
        ...state,
        handlersForm: action.payload
      }; 
    case '@process/NEW_ORDER_FORM':
      return {
        ...state,
        newOrderForm: action.payload
      };  
    case '@process/SELECTED_PROCESS_PODS':
      return {
        ...state,
        selectedProcessPods: action.payload
      };  
    case '@process/ROADTRANSPORT_TRACKING_DATA':
      return {
        ...state,
        trackingData: action.payload
      }; 
    case '@completed/PROCESSES':
      return {
        ...state,
        completedProcesses: action.payload
      }; 
    case '@data/LABELS':
      return {
        ...state,
        dbLabels: action.payload
      };  
    default:
      return state;
  }
}