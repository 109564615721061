import {
  primaryColor,
  successColor,
  dangerColor,
  warningColor,
  secondaryColor,
  roseColor,
  infoColor,
  grayColor,
  belizeHoleColor,
  midnightBlueColor,
  alizarionColor,
  carrotColor,
  thirdColor
} from '../../assets/jss/material-dashboard-pro-react';

export const CargoOwner = 1;
export const Trading = 2;
export const RoadCarrier = 3;
export const Warehouse = 4;
export const FreightForwarder = 5;
export const Supplier = 6;
export const Customer = 7;
export const ContainerDepot = 8;
export const BondedTerminal = 9;
export const Agent = 10;

export const ActorTypes = {
  1: {
    Parameter: "CargoOwner",
    Label: {
      "pt-br": "Embarcador",
      "en-gb": "Cargo Owner"
    },
    Color: primaryColor
  },
  2: {
    Parameter: "Trading",
    Label: {
      "pt-br": "Trading",
      "en-gb": "Trading"
    },
    Color: secondaryColor
  },
  3: {
    Parameter: "RoadCarrier",
    Label: {
      "pt-br": "Transportadora",
      "en-gb": "Road Carrier"
    },
    Color: roseColor
  },
  4: {
    Parameter: "Warehouse",
    Label: {
      "pt-br": "Armazém",
      "en-gb": "Warehouse"
    },
    Color: infoColor
  },
  5: {
    Parameter: "FreightForwarder",
    Label: {
      "pt-br": "Despachante",
      "en-gb": "Freight Forwarder"
    },
    Color: grayColor
  },
  6: {
    Parameter: "Supplier",
    Label: {
      "pt-br": "Fornecedor",
      "en-gb": "Supplier"
    },
    Color: belizeHoleColor
  },
  7: {
    Parameter: "Customer",
    Label: {
      "pt-br": "Cliente",
      "en-gb": "Customer"
    },
    Color: midnightBlueColor
  },
  8: {
    Parameter: "ContainerDepot",
    Label: {
      "pt-br": "Container Depot",
      "en-gb": "Container Depot"
    },
    Color: alizarionColor
  },
  9: {
    Parameter: "BondedTerminal",
    Label: {
      "pt-br": "Terminal Alfandegado",
      "en-gb": "Bonded Terminal"
    },
    Color: carrotColor
  },
  10: {
    Parameter: "Agent",
    Label: {
      "pt-br": "Agente",
      "en-gb": "Agent"
    },
    Color: thirdColor
  },
}
