import * as reducers from '../actions/types/index';

const INITIAL_STATE = {
  sidebarDrawerOpen: false,
  miniActive: true,
  language: 'pt-br',
  windowHeight: window.innerHeight,
  windowWidth: window.innerWidth,
  blockers: 0,
  operationView: 0
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case reducers.MOBILE_OPEN:
      return {
        ...state,
        sidebarDrawerOpen: action.payload
      };
    case reducers.MINE_ACTIVE:
      return {
        ...state,
        miniActive: action.payload
      };
    case reducers.LANGUAGE:
      return {
        ...state,
        language: action.payload
      };
    case reducers.WINDOWHEIGHT:
      return {
        ...state,
        windowHeight: action.payload
      };
    case reducers.WINDOWWIDTH:
      return {
        ...state,
        windowWidth: action.payload
      };
    case reducers.BLOCKERS:
      return {
        ...state,
        blockers: action.payload
      };
    case reducers.OPERATION_VIEW:
      return {
        ...state,
        operationView: action.payload
      };
    default:
      return state;
  }
}
